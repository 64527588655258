import React, { useRef } from 'react'
import BigPicture from 'bigpicture'

const Video = ({ src, caption, children }) => {
  const link = useRef()

  const onZoom = () => {
    BigPicture({ el: link.current, imgSrc: src })
  }

  return (
    <div className="bigpicture link video" onClick={onZoom} ref={link} caption={caption}>
      {children}
    </div>
  )
}

export default Video
