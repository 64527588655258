import i18n from 'lib/i18n'

export const mapStatus = status => ({
  CREATED: i18n`CREATED`,
  REVOKED: i18n`REVOKED`,
  CANCELED: i18n`CANCELED`,
  CHECKED_IN: i18n`CHECKED IN`,
  CHECKED_OUT: i18n`CHECKED OUT`,
  EXPIRED: i18n`EXPIRED`
})[status] || ''

export const mapType = type => ({
  recurrent: i18n`Recurrent`,
  temporal: i18n`Temporal`,
  permanent: i18n`Permanent`
})[type] || ''

export const mapWeekDays = day => ({
  '1': i18n`Mon`,
  '2': i18n`Tue`,
  '3': i18n`Wed`,
  '4': i18n`Thu`,
  '5': i18n`Fri`,
  '6': i18n`Sat`,
  '7': i18n`Sun`
})[day] || ''