import React, { Fragment, useReducer } from 'react'
import * as cons from 'data/auth/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import Validator from './validator'
import Logo from './svgs/logo-icon'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Authenticate = ({ renderConfirm, renderConfirmed, renderExpired, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    errors: {},
    isValid: false,
    data: { email: '', name: '' }
  })

  const renderForm = () => {
    return (
      <Validator
        {...props}
        data={state.data}
        onValidate={(data, isValid, errors) => setState({ data, isValid, errors })}
        render={validate => props.renderForm({ validate, ...state })}
      />
    )
  }

  const renderContent = () => {
    return (renderMap[stores.auth.status] || renderForm)(stores.accounts)
  }

  const renderMap = {
    [cons.NOT_VERIFIED]: renderForm,
    [cons.VERIFYING]: () => renderConfirm(state),
    [cons.VERIFIED]: () => renderConfirmed(state),
    [cons.VERIFICATION_EXPIRED]: () => renderExpired(state)
  }

  return (
    <Fragment>
      <Logo width="80" style={{ marginBottom: 25 }} />
      {renderContent()}
    </Fragment>
  )
}

Authenticate.defaultProps = {
  rules: [],
  data: {},
  renderConfirm: () => null,
  renderConfirmed: () => null,
  renderExpired: () => null,
  renderForm: () => null
}

export default view(Authenticate)
