import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import i18n from 'lib/i18n'
import { withTheme } from 'hocs'

const TableHeader = ({
	data,
	render,
	classes,
	onSelectAll,
	numSelected,
	noCheckbox,
	noActions,
	rowCount,
	...props
}) => (
	<TableHead>
		<TableRow>
			{!noCheckbox && (
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={numSelected === rowCount}
						onChange={(e, checked) => onSelectAll(checked)}
					/>
				</TableCell>
			)}
			{data.map((item) => render(item, props))}
			{!noActions && <TableCell className={classes.lastHeaderCell}>{i18n`Actions`}</TableCell>}
		</TableRow>
	</TableHead>
)

export default withTheme('table')(TableHeader)
