import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.gates',
        content: (
          <div>
            <h2>{i18n`Gate List`}</h2>
            <span>{i18n`This is the gate list section where all gates that belong to this community will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create, import, export gates.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for gates.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .create-action',
        content: (
          <div>
            <h2>{i18n`Create Gate`}</h2>
            <span>{i18n`Create a new gate.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .upload-action',
        content: (
          <div>
            <h2>{i18n`Import Gates`}</h2>
            <span>{i18n`Import gates using a CSV file.`}</span><br />
            <span>{i18n`Download the CSV import template from the help section under the main menu.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .download-action',
        content: (
          <div>
            <h2>{i18n`Download Gates`}</h2>
            <span>{i18n`Export all gates as a CSV file.`}</span>
          </div>
        )
      },
      {
        selector: '.gates tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Gate Record`}</h2>
            <span>{i18n`Row with basic information about the gate. You can click on the row to get more details of the gate.`}</span>
          </div>
        )
      },
      {
        selector: '.gates tbody tr:first-child td:last-child span',
        content: (
          <div>
            <h2>{i18n`Gate Activation`}</h2>
            <span>{i18n`You can activate or inactivate a gate by clicking this switch button.`}</span>
          </div>
        )
      },
      {
        selector: '.gates .guide-action',
        content: (
          <div>
            <h2>{i18n`Gate Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
