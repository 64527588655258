import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'incidents')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  return list.replace(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const comment = async (domain, data) => {
  const item = list.get(data.id)
  const comment = await api.comment(domain, data)
  return list.set(item.id, { comments: [...item.comments, comment] })
}

const ack = async (domain, data, userId) => {
  const item = list.get(data.id)
  if (userId && item.viewers.includes(userId)) return
  const res = await api.ack(domain, data)
  return list.set(data.id, res)
}

const sortLogs = (logs = []) => {
  return logs.sort((a, b) => b.ts - a.ts)
}

const setLogs = (id, logs = []) => {
  list.set(id, { logs: sortLogs(logs) })
}

const fetchLogs = async (domain, data) => {
  const logs = await api.fetchLogs(domain, data)
  return setLogs(data.id, logs)
}

const setComment = (id, data) => {
  const incident = list.get(id)
  if (!incident) return
  const index = incident.comments.findIndex(c => c.id === data.id)
  return index !== -1
    ? Object.assign(incident.comments[index], data)
    : list.set(id, { comments: [...incident.comments, data] })
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.INCIDENT_CREATED:
    case cons.INCIDENT_UPDATED:
      return list.add(payload)
    case cons.INCIDENT_COMMENTED:
      return setComment(payload.incidentId, payload)
    case cons.INCIDENT_DELETED:
      return list.remove(payload)
    default:
      break
  }
}

const incidentCount = id => {
  return state.list.reduce((count, item) => {
    item.viewers = item.viewers || []
    if (!item.viewers.includes(id)) count++
    return count
  }, 0)
}

export default merge({
  get,
  ack,
  fetch,
  create,
  update,
  comment,
  destroy,
  event,
  fetchLogs,
  setLogs,
  incidentCount
})
