import timesAgo from 'date-fns/formatDistanceStrict'
import formatTs from 'date-fns/format'
import capitalize from 'lodash.capitalize'
import { es } from 'date-fns/locale'
import i18n from 'lib/i18n'

const locales = { es }

export const getDateOptions = () => {
  const locale = locales[i18n.state.locale]
  return locale ? { locale } : {}
}

export const bytesToSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1000
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const isEmptyObject = (object = {}) => {
  return !Object.keys(object).length
}

export const isEmptyArray = (arr = []) => {
  return !arr.length
}

export const toTime = time => {
  const [H, m] = time.split(':')
  const h = H % 12 || 12
  const a = H < 12 ? 'AM' : 'PM'

  return `${h}:${m} ${a}`
}

export const omit = (obj, keys) => {
  return Object.keys(obj)
    .filter(key => keys.indexOf(key) === -1)
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})
}

export const pick = (obj, keys) => {
  return Object.assign(
    {},
    ...keys.map(key => {
      return typeof obj[key] !== 'undefined' ? { [key]: obj[key] } : {}
    })
  )
}

export const timeAgo = ts => {
  if (!ts) return ''

  const options = getDateOptions()
  return i18n`${timesAgo(new Date(), ts, options)} ago`
}

export const tsDateRangeFormat = (minTs, maxTs) => {
  const options = getDateOptions()

  let min = formatTs(new Date(minTs), 'yyyy-MM-dd')
  let max = formatTs(new Date(maxTs), 'yyyy-MM-dd')

  if (min === max) {
    const date = formatTs(new Date(minTs), 'MMM D, yyyy', options)
    return capitalize(date)
  }

  min = formatTs(new Date(minTs), 'MMM D - ', options)
  max = formatTs(new Date(maxTs), 'MMM D, yyyy', options)
  return `${capitalize(min)} ${capitalize(max)}`
}

export const parseDuration = duration => {
  duration = duration || 1
  duration = typeof duration === 'string' ? duration.replace(/hours|hour| /g, '').trim() : duration
  return parseInt(duration, 10)
}

export const formatDuration = duration => {
  return parseDuration(duration) + ' hours'
}

export const isFunction = fn => {
  return typeof fn === 'function'
}

export const extractNumberFromString = string => {
  const match = string.match(/\d+/g)
  return Array.isArray(match) && match.length ? match.map(Number)[0] : undefined
}

export const truncate = (text = '', max) => {
  return text.substr(0, max - 1) + (text.length > max ? '...' : '')
}

export const merge = (a, b) => {
  return Object.assign(a, b)
}

export const createMerge = a => b => {
  return merge(a, b)
}

export const isSlug = text => {
  if (!text) return null
  return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(text)
}
