import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useForm from 'react-hook-form'
import { withTheme } from 'hocs'
import * as cons from 'data/community/constants'
import { Form, Select } from 'components'
import i18n from 'lib/i18n'

const Plan = ({ data, buttonRef, classes, onSubmit }) => {
  const { setValue, register, handleSubmit, errors } = useForm({ mode: 'onBlur' })
  const [plan, setPlan] = useState(data.plan || '')

  const plans = [
    { id: cons.MICRO, label: i18n`Plan Micro, 30 units, $0/unit by month` },
    { id: cons.PLUS, label: i18n`Plan Plus, 400 units, $0.95/unit by month` },
    { id: cons.PREMIUM, label: i18n`Plan Premium, 600 units, $0.75/unit by month` }
  ]

  useEffect(() => {
    register({ name: 'plan' }, { required: i18n`Field required` })
    setValue('plan', plan)
  }, [register])

  const onPlanChange = ({ plan }) => {
    setPlan(plan)
    setValue('plan', plan)
  }

  return (
    <Form noValidate buttonRef={buttonRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container style={{ padding: `0px 60px` }}>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <Typography variant="h6">{i18n`Select Service Plan`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            required
            name="plan"
            label={i18n`Billing Plan`}
            data={plans}
            value={data.plan || ''}
            onChange={onPlanChange}
            className={classes.textField}
            helperText={errors.plan ? errors.plan.message : ''}
            error={Boolean(errors.plan)}
          />
        </Grid>
      </Grid>
    </Form>
  )
}

Plan.defaultProps = {
  data: {},
  isValid: true,
  onChange: () => {},
  onSignUp: () => {}
}

export default withTheme('form')(Plan)
