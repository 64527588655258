import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'polls')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  return list.replace(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const start = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.start(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const end = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.end(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const event = async ({ type, payload }) => {
  try {
    switch (type) {
      case cons.POLL_CREATED:
      case cons.POLL_UPDATED:
      case cons.POLL_STARTED:
      case cons.POLL_ENDED:
        return list.add(payload)
      case cons.POLL_DELETED:
        return list.remove(payload)
      case cons.COMMUNITY_CHANGED:
        if (payload && payload.domain) {
          await fetch(payload.domain, true)
        }
        return
      default:
        break
    }
  } catch (error) {
    console.log(error)
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  destroy,
  start,
  end,
  event
})
