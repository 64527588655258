import React, { Fragment } from 'react'
import Comment from 'components/comment'
import { timeAgo } from 'lib/utils'

const sort = (a, b) => {
  if (a.time < b.time) return -1
  if (a.time > b.time) return 1
  return 0
}

const Comments = ({ comments, ...props }) => (
  <Fragment>
    {console.log('COMMENTS', comments)}
    {comments.sort(sort).map(comment => (
      <Comment
        {...props}
        key={comment.id}
        text={comment.text}
        title={comment.userName}
        subtitle={timeAgo(comment.time)}
      />
    ))}
    <Comment create {...props} />
  </Fragment>
)

export default Comments
