import React from 'react'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Validator } from 'components'
import { withTheme } from 'hocs'
import { isRequired, isEmail, isMobile } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const AdminForm = ({ data, errors, isValid, isUpdate, classes, onChange }) => {
  const noop = () => {}

  const rules = [
    runner('name', i18n`Name`, isRequired),
    runner('phone', i18n`Mobile number`, isMobile),
    runner('email', i18n`Email`, isRequired, isEmail)
  ]

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                name="name"
                id="name-field"
                label={i18n`Full name`}
                value={data.name || ''}
                className={classes.textField}
                onChange={value(validate, 'name')}
                helperText={!isUpdate && i18n`E.g. Juan Castillo Smith`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="email"
                name="email"
                id="email-field"
                label={i18n`Email`}
                value={data.email || ''}
                className={classes.textField}
                inputProps={{ readOnly: isUpdate }}
                onChange={isUpdate ? noop : value(validate, 'email')}
                helperText={!isUpdate && i18n`E.g. juan@example.com`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                name="phone"
                id="phone-field"
                label={i18n`Mobile number`}
                value={data.phone || ''}
                className={classes.textField}
                onChange={value(validate, 'phone')}
                helperText={!isUpdate && i18n`E.g. 88108888`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={i18n`Active`}
                control={
                  <Switch
                    name="active"
                    id="active-field"
                    checked={Boolean(data.active)}
                    className={classes.textField}
                    onChange={e => validate({ active: e.target.checked })}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(AdminForm)
