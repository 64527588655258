import { createMerge } from './utils'

export default (store, key = 'list', idKey = 'id') => {
  const merge = createMerge(store)

  const update = (list, data) => {
    const index = list.findIndex(item => item[idKey] === data[idKey])

    return index === -1
      ? [...list, data]
      : [...list.slice(0, index), { ...list[index], ...data }, ...list.slice(index + 1)]
  }

  const modify = (data = {}) => {
    return Array.isArray(data) ? data.reduce(update, store[key]) : update(store[key], data)
  }

  const get = id => {
    return store[key].find(item => item[idKey] === id)
  }

  const set = (id, data) => {
    return add({ ...data, [idKey]: id })
  }

  const add = item => {
    replace(modify(item))
    return item
  }

  const remove = data => {
    if (Array.isArray(data)) return data.map(remove)
    const list = store[key].filter(item => item[idKey] !== data[idKey])
    replace(list)
  }

  const replace = list => {
    merge({ [key]: list })
  }

  return { get, set, add, remove, replace, modify }
}
