import React, { Fragment, useReducer, useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import getTime from 'date-fns/getTime'
import { CANCELLED, APPROVED } from 'data/reservation/constants'
import toast from 'lib/toast'
import { Section } from 'components'
import { DeleteDialog } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const ReservationDetail = ({ classes, match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    cancelDialog: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const { isValid, deleteDialog, cancelDialog } = state
  const reservation = stores.reservations.get(id)

  const isSameData =
    reservation.owner.name === state.data.owner &&
    reservation.owner.phone === state.data.phone &&
    reservation.active === state.data.active

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({
      errors: {},
      isValid: false,
      data: { code: 'none' }
    })
  }

  const fetch = async () => {
    try {
      await stores.reservations.fetch(domain, { id })
    } catch (err) {
      toast.error(i18n`Unable to fetch reservation information`)
      back()
    }
  }

  const approve = async data => {
    try {
      await stores.reservations.approve(domain, data)
      toast.success(i18n`Approved`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to approve reservation`)
    }
    reset()
  }

  const cancel = async data => {
    try {
      await stores.reservations.cancel(domain, data)
      toast.success(i18n`Cancelled`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to cancel reservation`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.reservations.destroy(domain, data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete reservation`)
      }
    }
  }

  const back = () => {
    history.push(`/reservations/${domain}`)
    return null
  }

  const onApprove = () => {
    approve({ id })
  }

  const onCancelAction = ({ ok }) => {
    if (ok) cancel({ id })
    closeCancelDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id })
    closeDeleteDialog()
  }

  const openCancelDialog = () => {
    setState({ cancelDialog: true })
  }

  const closeCancelDialog = () => {
    setState({ cancelDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const renderToolbarActions = data => {
    return (
      <Fragment>
        {data.status !== CANCELLED && (
          <Fragment>
            {data.status !== APPROVED && (
              <Tooltip title={i18n`Approve`}>
                <IconButton
                  aria-label={i18n`Approve`}
                  className={classes.approveButton}
                  onClick={() => onApprove()}
                >
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={i18n`Cancel`}>
              <IconButton
                aria-label={i18n`Cancel`}
                className={classes.cancelButton}
                onClick={() => openCancelDialog()}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
        <Tooltip title={i18n`Delete`}>
          <IconButton aria-label={i18n`Delete`} onClick={() => openDeleteDialog()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Fragment>
    )
  }

  if (!reservation) return back()

  const data = { ...reservation, ...state.data }
  const isCompleted = data.endTime < getTime(new Date())

  return (
    <Fragment>
      <Section
        noAssign
        noActions
        data={data}
        onBack={back}
        noDelete={isCompleted}
        title={i18n`Reservation Detail`}
        backButtonLabel={i18n`Reservations`}
        onDelete={openDeleteDialog}
        disableSubmitButton={!isValid || isSameData}
        toolbarActions={() => renderToolbarActions(data)}
      >
        <Form isUpdate data={data} />
      </Section>
      <DeleteDialog open={deleteDialog} title={i18n`Delete reservation`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this reservation?`}</p>
      </DeleteDialog>
      <DeleteDialog
        open={cancelDialog}
        title={i18n`Cancel reservation`}
        okLabel={i18n`Cancel reservation`}
        onAction={onCancelAction}
      >
        <p>
          {i18n`Are you sure you want to cancel this reservation? Once a reservation is canceled it can't be approved again.`}
        </p>
      </DeleteDialog>
    </Fragment>
  )
}

// class ReservationDetail extends Component {
//   state = {
//     data: {},
//     errors: {},
//     isValid: false,
//     cancelDialog: false,
//     deleteDialog: false
//   }

//   get id() {
//     return this.props.match.params.id
//   }

//   get domain() {
//     return this.props.match.params.domain
//   }

//   get reservation() {
//     return stores.reservations.get(this.id)
//   }

//   get isSameData() {
//     return (
//       this.reservation.name === this.state.data.name &&
//       this.reservation.phone === this.state.data.phone &&
//       this.reservation.active === this.state.data.active
//     )
//   }

//   componentDidMount() {
//     this.fetch()
//   }

//   componentDidUpdate(prevProps) {
//     if (this.domain !== prevProps.match.params.domain) {
//       this.fetch()
//     }
//   }

//   reset = () => {
//     this.setState({
//       errors: {},
//       isValid: false,
//       data: { code: 'none' }
//     })
//   }

//   fetch = async () => {
//     try {
//       await stores.reservations.fetch(this.domain, { id: this.id })
//     } catch (err) {
//       toast.error(i18n`Unable to fetch reservation information`)
//       this.back()
//     }
//   }

//   approve = async data => {
//     try {
//       await stores.reservations.approve(this.domain, data)
//       toast.success(i18n`Approved`)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to approve reservation`)
//     }
//     this.reset()
//   }

//   cancel = async data => {
//     try {
//       await stores.reservations.cancel(this.domain, data)
//       toast.success(i18n`Cancelled`)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to cancel reservation`)
//     }
//     this.reset()
//   }

//   destroy = async data => {
//     try {
//       await stores.reservations.destroy(this.domain, data)
//       toast.success(i18n`Deleted`)
//       this.back()
//     } catch (error) {
//       console.log(error)
//       if (error.message === 'Offline') {
//         toast.warn(i18n`Request queued`)
//         this.back()
//       } else {
//         toast.error(i18n`Unable to delete reservation`)
//       }
//     }
//   }

//   back = () => {
//     this.props.history.push(`/reservations/${this.domain}`)
//     return null
//   }

//   onUpdate = () => {
//     const { data } = this.state
//     this.update(data)
//   }

//   onApprove = () => {
//     this.approve({ id: this.id })
//   }

//   onCancelAction = ({ ok }) => {
//     if (ok) this.cancel({ id: this.id })
//     this.closeCancelDialog()
//   }

//   onDeleteAction = ({ ok }) => {
//     if (ok) this.destroy({ id: this.id })
//     this.closeDeleteDialog()
//   }

//   openCancelDialog = () => {
//     this.setState({ cancelDialog: true })
//   }

//   closeCancelDialog = () => {
//     this.setState({ cancelDialog: false })
//   }

//   openDeleteDialog = () => {
//     this.setState({ deleteDialog: true })
//   }

//   closeDeleteDialog = () => {
//     this.setState({ deleteDialog: false })
//   }

//   renderToolbarActions = data => {
//     const { classes } = this.props
//     return (
//       <Fragment>
//         {data.status !== CANCELLED && (
//           <Fragment>
//             {data.status !== APPROVED && (
//               <Tooltip title={i18n`Approve`}>
//                 <IconButton
//                   aria-label={i18n`Approve`}
//                   className={classes.approveButton}
//                   onClick={() => this.onApprove()}
//                 >
//                   <CheckCircleIcon />
//                 </IconButton>
//               </Tooltip>
//             )}
//             <Tooltip title={i18n`Cancel`}>
//               <IconButton
//                 aria-label={i18n`Cancel`}
//                 className={classes.cancelButton}
//                 onClick={() => this.openCancelDialog()}
//               >
//                 <CancelIcon />
//               </IconButton>
//             </Tooltip>
//           </Fragment>
//         )}
//         <Tooltip title={i18n`Delete`}>
//           <IconButton aria-label={i18n`Delete`} onClick={() => this.openDeleteDialog()}>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       </Fragment>
//     )
//   }

//   render() {
//     if (!this.reservation) return this.back()
//     const { isValid, deleteDialog, cancelDialog } = this.state

//     const data = {
//       ...this.reservation,
//       ...this.state.data
//     }

//     const isCompleted = data.endTime < getTime(new Date())

//     return (
//       <Fragment>
//         <Section
//           {...this.props}
//           noAssign
//           noActions
//           data={data}
//           onBack={this.back}
//           noDelete={isCompleted}
//           title={i18n`Reservation Detail`}
//           backButtonLabel={i18n`Reservations`}
//           onDelete={this.openDeleteDialog}
//           disableSubmitButton={!isValid || this.isSameData}
//           toolbarActions={() => this.renderToolbarActions(data)}
//         >
//           <Form isUpdate data={data} />
//         </Section>
//         <DeleteDialog
//           open={deleteDialog}
//           title={i18n`Delete reservation`}
//           onAction={this.onDeleteAction}
//         >
//           <p>{i18n`Are you sure you want to delete this reservation?`}</p>
//         </DeleteDialog>
//         <DeleteDialog
//           open={cancelDialog}
//           title={i18n`Cancel reservation`}
//           okLabel={i18n`Cancel reservation`}
//           onAction={this.onCancelAction}
//         >
//           <p>
//             {i18n`Are you sure you want to cancel this reservation? Once a reservation is canceled it can't be approved again.`}
//           </p>
//         </DeleteDialog>
//       </Fragment>
//     )
//   }
// }

export default compose(
  withTheme('reservations'),
  view
)(ReservationDetail)
