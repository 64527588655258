import React from 'react'
import { Switch } from "react-router-dom"
import { Layout, PrivateRoute } from 'components'
import CommunityList from './list'
import CommunityDetail from './detail'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/communities" component={CommunityList} />
      <PrivateRoute exact path="/communities/:id" component={CommunityDetail} />
    </Switch>
  </Layout>
)