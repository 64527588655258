export default theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  controls: {
    margin: 'auto',
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing()}px 0`
  },
  control: {
    textAlign: 'center',
    transition: 'all 0.2s ease-out',
    width: '20%',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRightWidth: 0,
    flexGrow: 1,
    padding: 0,
    margin: 0,
    fontSize: 12
  },
  firstControl: {
    borderRadius: '25px 0 0 25px'
  },
  lastControl: {
    borderRadius: '0 25px 25px 0',
    borderRightWidth: 1
  },
  input: {
    display: 'none'
  },
  label: {
    marginTop: theme.spacing(),
    marginBottom: 3,
    display: 'block',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12
  },
  content: {
    display: 'block',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: theme.spacing()
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: '#FFF'
  }
})
