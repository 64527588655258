import React, { Component } from 'react'
import clsx from 'clsx'
import { withTheme } from 'hocs'

class Content extends Component {
  render() {
    const { children, classes, open, ...props } = this.props

    return (
      <div className={clsx(classes.content, open && classes.contentShift)} {...props}>
        {children}
      </div>
    )
  }
}

export default withTheme('layout')(Content)
