import getBrowserLocale from 'browser-locale'
import * as storage from './storage'

export const getLocale = () => {
  const locale = storage.get('settings', {}).locale || getBrowserLocale()
  if (!locale) return
  return locale.replace(/[-_].*/g, '')
}

export const setLocale = locale => {
  storage.set('locale', locale)
}
