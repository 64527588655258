import React from 'react'
import { Switch } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import DocumentList from './list'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/documents/:domain" component={DocumentList} />
    </Switch>
  </Layout>
)
