import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.reservations',
        content: (
          <div>
            <h2>{i18n`Reservation List`}</h2>
            <span>{i18n`This is the reservation list section where all recent reservations will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.reservations .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create or export reservations.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for reservations.`}</span>
          </div>
        )
      },
      {
        selector: '.create-action',
        content: (
          <div>
            <h2>{i18n`Create Reservation`}</h2>
            <span>{i18n`Create a new reservation.`}</span>
          </div>
        )
      },
      {
        selector: '.download-action',
        content: (
          <div>
            <h2>{i18n`Download`}</h2>
            <span>{i18n`Download reservations based on a time range.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Reservation Record`}</h2>
            <span>{i18n`This is a reservation. You can click on any individual reservation row to get additional details.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child .cell-status',
        content: (
          <div>
            <h2>{i18n`Reservation Status`}</h2>
            <span>{i18n`Every reservation has a status field, which indicates the current status of the reservation in real-time.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Reservation Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
