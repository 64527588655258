import { get, post, put } from 'lib/api'

const toId = (id) => (id ? `/${id}` : '')

export const fetch = (domain, { id, data } = {}) => get(`/${domain}/passes${toId(id)}`, data)
export const fetchLogs = (domain, { id, ...data }) => get(`/${domain}/passes/${id}/logs`, data)
export const create = (domain, data) => post(`/${domain}/passes`, data)
export const download = (domain, data) => get(`/${domain}/passes/export`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/passes/${id}`, data)
export const revoke = (domain, { id, ...data }) => put(`/${domain}/passes/${id}/revoke`, data)
