export default (theme) => ({
  title: {
    //backgroundColor: '#fafafa'
    borderBottom: '1px solid #fafafa'
  },
  content: {
    padding: '8px 32px'
  },
  paper: {
    //width: '80%',
    maxHeight: '70%'
  },
  noPadding: {
    padding: 0
  }
})
