import { get, post, put, del } from 'lib/api'

const toId = id => (id ? `/${id}` : '')

export const fetch = (domain, { id, ...data } = {}) => get(`/${domain}/units${toId(id)}`, data)
export const create = (domain, data) => post(`/${domain}/units`, data)

export const upload = (domain, { file }) => {
  const data = new FormData()
  data.append('file', file)
  return post(`/${domain}/units/import`, data, { isFile: true })
}

export const assign = (domain, data) => post(`/${domain}/units/assign`, data)
export const download = (domain, data) => get(`/${domain}/units/export`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/units/${id}`, data)
export const destroy = (domain, data) => del(`/${domain}/units`, data)
export const unassign = (domain, data) => del(`/${domain}/units/unassign`, data)
