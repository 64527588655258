import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.passes',
        content: (
          <div>
            <h2>{i18n`Pass List`}</h2>
            <span>{i18n`This is the pass list section where all recent passes will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.passes .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create or export passes.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for passes.`}</span>
          </div>
        )
      },
      {
        selector: '.create-action',
        content: (
          <div>
            <h2>{i18n`Create Pass`}</h2>
            <span>{i18n`Create a new pass.`}</span>
          </div>
        )
      },
      {
        selector: '.download-action',
        content: (
          <div>
            <h2>{i18n`Download`}</h2>
            <span>{i18n`Download passes based on a time range.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Pass Record`}</h2>
            <span>{i18n`This is a pass. You can click on any individual pass row to get additional details.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child .cell-status',
        content: (
          <div>
            <h2>{i18n`Pass Status`}</h2>
            <span>{i18n`Every pass has a status field, which indicates the current status of the pass in real-time.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Pass Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
