import React, { useReducer } from 'react'
import toast from 'lib/toast'
import Section from 'components/section'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const GateCreate = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false
  })

  const { domain } = match.params

  const create = async data => {
    try {
      data.active = 'active' in data ? Boolean(data.active) : true

      if ('coords' in data) {
        data.coords = data.coords ? data.coords.split(',') : []
      }

      await stores.gates.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create gate`)
      }
    }
  }

  const back = () => {
    history.push(`/resources/${domain}/gates`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({ data, isValid, errors })
  }

  const onCreate = () => {
    create(state.data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Gate`}
      backButtonLabel={i18n`Gates`}
      disableSubmitButton={!state.isValid}
    >
      <Form {...state} onChange={onFormChange} />
    </Section>
  )
}

export default view(GateCreate)
