import React from 'react'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Validator } from 'components'
import { withTheme } from 'hocs'
import { isRequired, isMobile } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const OfficerForm = ({ data, errors, classes, isValid, isUpdate, onChange }) => {
  const rules = [
    runner('name', i18n`Name`, isRequired),
    runner('username', i18n`Username`, isRequired),
    runner('phone', i18n`Mobile number`, isMobile)
  ]

  if (!isUpdate) {
    rules.push(runner('passcode', i18n`Passcode`, isRequired))
  }

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                name="name"
                id="name-field"
                label={i18n`Full name`}
                value={data.name || ''}
                className={classes.textField}
                onChange={value(validate, 'name')}
                helperText={!isUpdate && i18n`E.g. Juan Castillo Smith`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                name="username"
                id="username-field"
                label={i18n`Username`}
                value={data.username || ''}
                className={classes.textField}
                onChange={value(validate, 'username')}
                helperText={!isUpdate && i18n`E.g. jcsmith`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="password"
                name="passcode"
                id="passcode-field"
                label={i18n`Passcode`}
                value={data.passcode || (isUpdate ? '********' : '')}
                className={classes.textField}
                onChange={value(validate, 'passcode')}
                helperText={!isUpdate && i18n`Alphanumeric`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="text"
                name="phone"
                id="phone-field"
                label={i18n`Mobile number`}
                value={data.phone || ''}
                className={classes.textField}
                onChange={value(validate, 'phone')}
                helperText={!isUpdate && i18n`E.g. 88108888`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={i18n`Active`}
                control={
                  <Switch
                    name="active"
                    id="active-field"
                    checked={Boolean(data.active)}
                    className={classes.textField}
                    onChange={e => validate({ active: e.target.checked })}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(OfficerForm)
