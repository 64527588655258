import { createStore } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as api from './api'

const state = {
  monthSegment: [],
  segment: [],
  month: [],
  week: [],
  day: [],
  counts: {
    today: {
      total: 0,
      person: 0,
      service: 0
    },
    week: {
      total: 0,
      person: 0,
      service: 0
    },
    month: {
      total: 0,
      person: 0,
      service: 0
    },
    year: {
      total: 0,
      person: 0,
      service: 0
    }
  }
}

const store = createStore(state, 'stats')
const merge = createMerge(store)

const set = data => {
  return merge(data)
}

const fetch = async (domain, data) => {
  const res = await api.fetch(domain, data)
  return set(res)
}

export default merge({ fetch })
