import React, { useReducer } from 'react'
import SendIcon from '@material-ui/icons/Send'
import toast from 'lib/toast'
import { Section } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const NotificationCreate = ({ classes, match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false
  })

  const { domain } = match.params
  const { isValid } = state
  const create = async data => {
    try {
      data.status = 'PUBLISHED'
      await stores.notifications.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create notification`)
      }
    }
  }

  const back = () => {
    history.push(`/notifications/${domain}`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({ data, isValid, errors })
  }

  const onCreate = () => {
    create(state.data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Notification`}
      backButtonLabel={i18n`Notifications`}
      submitButtonLabel={i18n`Send`}
      submitButtonIcon={<SendIcon className={classes.buttonIcon} />}
      disableSubmitButton={!isValid}
    >
      <Form {...state} onChange={onFormChange} />
    </Section>
  )
}

export default compose(
  withTheme('section'),
  view
)(NotificationCreate)
