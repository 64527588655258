import { createStore } from 'lib/store'
import { createMerge } from 'lib/utils'
import i18n from 'lib/i18n'
import * as cons from './constants'
import * as api from './api'

const state = {}
const store = createStore(state, 'settings')
const merge = createMerge(store)

const set = data => {
  return merge({ ...store.setings, ...data })
}

const fetch = async force => {
  const res = (await api.fetch()) || []
  return set(res)
}

const update = async (data = {}) => {
  if (data.locale) {
    await i18n.set(data.locale, true)
  }
  const res = await api.update(data)
  return set(res)
}

const event = async ({ type, payload }) => {
  try {
    switch (type) {
      case cons.SETTINGS_UPDATED:
        return set(payload)
      case cons.ACCOUNT_SIGNED_IN:
        return await fetch(true)
      default:
        break
    }
  } catch (error) {
    console.log(error)
  }
}

export default merge({
  fetch,
  update,
  event
})
