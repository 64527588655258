import { useState } from 'react'
import useBus from './use-bus'
import useSync from './use-sync'

const useOnline = () => {
  const [online, setOnline] = useState(
    typeof navigator.onLine === 'boolean' ? navigator.onLine : true
  )

  useBus('online::change', isOnline => {
    setOnline(isOnline)
  })

  const syncing = useSync()

  return { online, syncing }
}

export default useOnline
