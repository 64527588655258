import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import bus from 'lib/bus'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'members')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, data, force) => {
  const res = await api.fetch(domain, data)
  return Array.isArray(res) ? list.replace(res) : list.add(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, { phone: '', ...item })
    throw error
  }
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const invite = (domain, data) => {
  return api.invite(domain, data)
}

const assign = async (domain, data) => {
  const res = await api.assign(domain, data)
  list.add(res)
  bus.emit('member::assigned', res, domain)
  return res
}

const unassign = async (domain, data) => {
  const res = await api.unassign(domain, data)
  list.add(res)
  bus.emit('member::unassigned', res, domain)
  return res
}

const upload = async (domain, data) => {
  const res = await api.upload(domain, data)
  return list.add(res)
}

const download = async (domain, data = []) => {
  const ids = data.map(item => item.id).join(',')
  return api.download(domain, { ids })
}

const event = ({ type, payload }, domain) => {
  console.log('ASSIGNED ======>', domain, type, payload)
  switch (type) {
    case cons.MEMBER_CREATED:
    case cons.MEMBER_UPDATED:
    case cons.MEMBER_ASSIGNED:
    case cons.MEMBER_UNASSIGNED:
      return list.add(payload)
    case cons.MEMBER_DELETED:
      return list.remove(payload)
    case cons.UNIT_ASSIGNED:
    case cons.UNIT_UNASSIGNED:
      return fetch(domain, true)
    default:
      break
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  destroy,
  assign,
  unassign,
  upload,
  download,
  invite,
  event
})
