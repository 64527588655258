import React, { Fragment, useEffect, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled'
import LockIcon from '@material-ui/icons/Lock'
import { PlaceHolder, Button, Table, DeleteDialog } from 'components'
import { ReactComponent as HeroImage } from 'svgs/segment_analysis.svg'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { mapStatus } from 'data/poll/maps'
import { ENDED, STARTED } from 'data/poll/constants'
import { view } from 'lib/store'
import { SEARCH_KEYS } from 'data/poll/constants'
import createSearch from 'lib/search'
import { timeAgo, truncate } from 'lib/utils'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const PollList = ({ classes, match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    deleteDialog: false,
    endDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { keyword, endDialog, deleteDialog } = state
  const { domain } = match.params
  const { list } = stores.polls
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'title', label: i18n`Title` },
    { id: 'respondents', label: i18n`Responses` },
    { id: 'updated', label: i18n`Updated` },
    { id: 'status', label: i18n`Status` }
  ]

  const selected = state.selected.map(id => ({ id }))

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      await stores.polls.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching polls`)
    }
  }

  const start = async data => {
    try {
      await stores.polls.start(domain, data)
      toast.success(i18n`Started`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to start poll`)
    }
    reset()
  }

  const end = async data => {
    try {
      await stores.polls.end(domain, data)
      toast.success(i18n`Ended`)
    } catch (error) {
      console.log(error, data)
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to end poll`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.polls.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.areas.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete poll(s)`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onStart = data => {
    start(data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onEndAction = ({ ok }) => {
    if (ok) end(state.data)
    else reset()
    closeEndDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openEndDialog = data => {
    setState({ endDialog: true, data })
  }

  const closeEndDialog = () => {
    setState({ endDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <HeroImage width="350" height="250" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE A POLL`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">{i18n`No polls to show at this moment`}</Typography>
      </PlaceHolder>
    )
  }

  const renderRow = (node, key, data) => {
    if (key === 'status') {
      node = (
        <Typography className={classes[`STATUS_${data.status}`]}>
          {mapStatus(data.status)}
        </Typography>
      )
    }

    if (key === 'updated') {
      node = <div className={classes.noWrap}>{timeAgo(data.updated)}</div>
    } else if (key === 'created') {
      node = <div className={classes.noWrap}>{timeAgo(data.created)}</div>
    } else if (key === 'respondents') {
      node = data.respondents.length
    } else if (key === 'title') {
      node = truncate(data.title, 50)
    } else if (key === 'custom-action') {
      return (
        data.status !== ENDED && (
          <Fragment>
            {data.status !== STARTED && (
              <Tooltip title={i18n`Start`}>
                <IconButton
                  aria-label={i18n`Start`}
                  className={classes.startButton}
                  onClick={() => onStart(data)}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={i18n`End`}>
              <IconButton
                aria-label={i18n`End`}
                className={classes.endButton}
                onClick={() => openEndDialog(data)}
              >
                <LockIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )
      )
    }

    return node
  }

  console.log(data)

  return (
    <Fragment>
      <Table
        {...props}
        noInvite
        noAssign
        noSwitch
        noUpload
        noDownload
        noCheckbox
        noItemSwitch
        noItemDelete
        noSelectedDownload
        data={data}
        selected={state.selected}
        columns={columns}
        className="polls"
        title={i18n`Polls`}
        onRow={page}
        onDetail={page}
        onCreate={() => page('create')}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
        row={renderRow}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete polls`} onAction={onDeleteAction}>
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected polls?`
            : i18n`Are you sure you want to delete this poll?`}
        </p>
      </DeleteDialog>
      <DeleteDialog
        open={endDialog}
        title={i18n`End poll`}
        okLabel={i18n`End poll`}
        onAction={onEndAction}
      >
        <p>
          {i18n`Are you sure you want to end this poll? Once a poll is ended it can't be started again.`}
        </p>
      </DeleteDialog>
      <Guide open={state.guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default compose(
  withTheme('polls'),
  view
)(PollList)
