import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { withTheme } from 'hocs'

const MultiSegment = ({ value, classes, children, name, label, ...props }) => {
  const [values, setValues] = useState(value || [])

  useEffect(() => {
    if (values.length) {
      props.onChange(values)
    }
  }, [values])

  const onChange = event => {
    event.stopPropagation()

    const { value, checked } = event.currentTarget
    let newValues = [...values]

    if (checked) {
      newValues.push(value)
    } else {
      newValues = values.filter(val => val !== value)
    }

    setValues(newValues)
    props.onChange(newValues)
  }

  const isSelected = value => {
    return Boolean(values.find(val => val === value))
  }

  const first = 0
  const last = children.length - 1
  const controls = children.map((child, index) => {
    const selected = isSelected(child.props.value || index)
    const id = name + index

    return (
      <div
        key={id}
        className={clsx(classes.control, {
          [classes.selectedControl]: selected,
          [classes.selected]: selected,
          [classes.firstControl]: index === first,
          [classes.lastControl]: index === last
        })}
      >
        <input
          id={id}
          name={id}
          type="checkbox"
          checked={selected}
          onChange={onChange}
          className={clsx(classes.input)}
          value={child.props.value || index}
        />
        <label className={classes.content} htmlFor={id}>
          {child}
        </label>
      </div>
    )
  })

  return (
    <div className={classes.root} {...props}>
      <label htmlFor="multi-segment-controls" className={classes.label}>
        {label}
      </label>
      <div id="multi-segment-controls" className={classes.controls}>
        {controls}
      </div>
    </div>
  )
}

export default withTheme('multiSegment')(MultiSegment)
