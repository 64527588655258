import React, { useReducer } from 'react'
import toast from 'lib/toast'
import { Section, AvatarDialog } from 'components'
import i18n from 'lib/i18n'
import { stores } from 'data'
import { view } from 'lib/store'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Profile = ({ classes, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    avatarDialog: false
  })

  const { user } = stores.accounts
  const isSameData = user && user.name === state.data.name && user.phone === state.data.phone

  const reset = () => {
    setState({ data: {}, errors: {}, isValid: false })
  }

  const update = async data => {
    try {
      await stores.accounts.update(data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update profile`)
    }
    reset()
  }

  const onFormChange = (data, isValid, errors) => {
    setState({ data, isValid, errors })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onChangeAvatar = () => {
    openAvatarDialog()
  }

  const onAvatarChangeAction = ({ ok, avatar }) => {
    if (ok) update({ avatar })
    closeAvatarDialog()
  }

  const openAvatarDialog = () => {
    setState({ avatarDialog: true })
  }

  const closeAvatarDialog = () => {
    setState({ avatarDialog: false })
  }

  const data = { ...user, ...state.data }

  return (
    <Section
      {...props}
      noAssign
      noDelete
      noBackButton
      noCancelButton
      title={i18n`Profile`}
      onSubmit={onUpdate}
      submitButtonLabel={i18n`Update`}
      disableSubmitButton={!state.isValid || isSameData}
    >
      <Form {...state} data={data} onChange={onFormChange} onChangeAvatar={onChangeAvatar} />
      <AvatarDialog
        open={state.avatarDialog}
        title="Avatar Editor"
        onAction={onAvatarChangeAction}
      />
    </Section>
  )
}

export default view(Profile)
