import React, { useRef } from 'react'
import SendIcon from '@material-ui/icons/Send'
import getTime from 'date-fns/getTime'
import toast from 'lib/toast'
import { Section } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import i18n from 'lib/i18n'
import Form from './form'

const PollCreate = ({ classes, match, history }) => {
  const form = useRef()
  const button = useRef()
  const { domain } = match.params

  const create = async data => {
    try {
      await stores.polls.create(domain, normalize(data))
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create poll`)
      }
    }
  }

  const normalize = data => {
    if (data.startDate) {
      data.startDate = getTime(data.startDate)
    }

    if (data.endDate) {
      data.endDate = getTime(data.endDate)
    }

    return data
  }

  const back = () => {
    history.push(`/polls/${domain}`)
  }

  const onSubmit = () => {
    button.current.click()
  }

  const onCreate = data => {
    create(data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onSubmit}
      title={i18n`Create Poll`}
      backButtonLabel={i18n`Polls`}
      submitButtonLabel={i18n`Send`}
      submitButtonIcon={<SendIcon className={classes.buttonIcon} />}
    >
      <Form ref={form} onSubmit={onCreate} buttonRef={button} />
    </Section>
  )
}

export default compose(
  withTheme('section'),
  view
)(PollCreate)
