import React, { Fragment, useRef, useReducer } from 'react'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import MuiToolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import CssBaseline from '@material-ui/core/CssBaseline'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { CommunitySelector, LocaleSelector, AccountSpot, Online } from 'components'
import { withTheme } from 'hocs'
import compose from 'lib/compose'
import i18n from 'lib/i18n'
import { stores } from 'data'
import { view } from 'lib/store'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Toolbar = ({ classes, open, onMenu }) => {
  const [state, setState] = useReducer(reducer, {
    locale: 'en',
    userMenu: false,
    localeMenu: false,
    communitySelector: false
  })

  const menu = useRef()

  const openCommunitySelector = () => {
    setState({ communitySelector: true })
  }

  const closeCommunitySelector = () => {
    setState({ communitySelector: false })
  }

  const openLocaleMenu = e => {
    setState({ localeMenu: true, localeMenuEl: e.currentTarget })
  }

  const closeLocaleMenu = e => {
    setState({ localeMenu: false, localeMenuEl: null })
  }

  const openUserMenu = e => {
    setState({ userMenu: true, userMenuEl: e.currentTarget })
  }

  const closeUserMenu = e => {
    setState({ userMenu: false, userMenuEl: null })
  }

  const onLocaleChange = async locale => {
    try {
      await stores.settings.update({ locale })
      setState({ locale })
    } catch (err) {
      console.log(err)
    }
    closeLocaleMenu()
  }

  const { user } = stores.accounts
  const { communitySelector, userMenu, userMenuEl, localeMenu, localeMenuEl } = state
  const community = stores.communities.getCommunity()
  const { locale } = stores.settings

  console.log('OPEN TOOLBAR', open)

  return (
    <Online>
      {({ online, syncing }) => (
        <Fragment>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.toolbar, {
              [classes.toolbarShift]: open,
              [classes.toolbarOffline]: !online || syncing
            })}
          >
            <MuiToolbar>
              <div className={classes.toolbarLeft}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onMenu}
                  className={clsx('sidebar-toggler', classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <div id="community-selector" className={classes.communitySelector}>
                  <div
                    className={clsx({ [classes.disabled]: !online })}
                    onClick={openCommunitySelector}
                    ref={menu}
                  >
                    <Typography variant="h6" color="inherit" noWrap>
                      {community.title || i18n`Create community`}
                    </Typography>
                  </div>
                  <IconButton color="inherit" disabled={!online} onClick={openCommunitySelector}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                  <CommunitySelector
                    el={menu.current}
                    open={communitySelector}
                    onClose={closeCommunitySelector}
                    onChange={closeCommunitySelector}
                  />
                </div>
              </div>
              <div className={classes.toolbarMiddle}>
                {!online && (
                  <Typography variant="h6" color="inherit" noWrap>
                    {i18n`Offline`}
                  </Typography>
                )}
                {syncing && (
                  <Typography variant="h6" color="inherit" noWrap>
                    {i18n`Syncing data`}
                  </Typography>
                )}
              </div>
              <div className={classes.toolbarRight}>
                <LocaleSelector
                  locale={locale}
                  classes={classes}
                  el={localeMenuEl}
                  open={localeMenu}
                  onMenu={openLocaleMenu}
                  onChange={onLocaleChange}
                  onClose={closeLocaleMenu}
                />
                <AccountSpot
                  user={user}
                  el={userMenuEl}
                  open={userMenu}
                  onMenu={openUserMenu}
                  onClose={closeUserMenu}
                  classes={classes}
                />
              </div>
            </MuiToolbar>
          </AppBar>
        </Fragment>
      )}
    </Online>
  )
}

export default compose(
  withTheme('layout'),
  view
)(Toolbar)
