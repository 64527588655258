import { useEffect } from 'react'
import { run } from 'lib/validate/runner'
import { isEmptyObject } from 'lib/utils'

const Validator = ({ rules, render, onValidate, data: propData }) => {
  useEffect(() => validate, [])

  const validate = (data = {}) => {
    data = { ...propData, ...data }
    const errors = run(data, rules)
    const isValid = Boolean(isEmptyObject(errors))
    onValidate(data, isValid, errors)

    console.log(data, isValid, errors)
  }

  return render(validate)
}

Validator.defaultProps = {
  onValidate: () => {},
  render: () => null,
  rules: [],
  data: {}
}

export default Validator
