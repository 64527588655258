import React, { useRef } from 'react'
import { Converter } from 'showdown'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import i18n from 'lib/i18n'

const Preview = ({ classes, value, label, showdownOptions }) => {
  const converter = useRef(new Converter(showdownOptions)).current
  const html = converter.makeHtml(value || '') || '<p>&nbsp</p>'

  return (
    <FormControl className={classes.formControl} margin="normal">
      <InputLabel shrink>{label || i18n`Preview`}</InputLabel>
      <div className={classes.preview} dangerouslySetInnerHTML={{ __html: html }} />
    </FormControl>
  )
}

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  preview: {
    ...theme.typography.body1,
    marginTop: theme.spacing(2),
    paddingTop: 6,
    paddingBottom: 7,
    fontFamily: theme.typography.fontFamilySlab,
    '& p': {
      marginTop: 0,
      marginBottom: theme.spacing(2)
    }
  }
})

export default withStyles(styles)(Preview)
