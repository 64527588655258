import React, { PureComponent, useState, useEffect } from 'react'
import formatTime from 'date-fns/format'
import getTime from 'date-fns/getTime'
import toDate from 'date-fns/toDate'
import toast from 'lib/toast'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import Form from './form'

const ReservationCreate = ({ match, history }) => {
  const [data, setData] = useState({})
  const { domain } = match.params
  const { areaId } = data
  const area = stores.areas.get(areaId) || { slots: [] }
  const community = stores.communities.getCommunity()

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const fetch = async () => {
    try {
      await stores.areas.fetch(domain)
    } catch (error) {
      console.log(error)
      toast.error(i18n`Unable to fetch common areas at this moment`)
    }
  }

  const fetchSlots = async ({ areaId, date }) => {
    try {
      await stores.areas.fetchSlots(domain, {
        id: areaId,
        date: getTime(new Date(date))
      })
    } catch (error) {
      console.log(error)
      toast.error(i18n`Unable to fetch time slots`)
    }
  }

  const formatSlots = (slots = []) => {
    console.log('SLOT', slots)

    return slots.map(({ startTime, endTime }) => {
      return {
        range: `${startTime},${endTime}`,
        label:
          formatTime(toDate(new Date(startTime)), 'h:mm a') +
          ' - ' +
          formatTime(toDate(new Date(endTime)), 'h:mm a')
      }
    })
  }

  const normalize = ({ areaId, range }) => {
    const [start, end] = range.split(',')

    return {
      areaId,
      terms: area.terms || '',
      startTime: getTime(new Date(start)),
      endTime: getTime(new Date(end)),
      placeId: community.id
    }
  }

  const create = async data => {
    try {
      console.log(normalize(data))
      await stores.reservations.create(domain, normalize(data))
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create reservation`)
      }
    }
  }

  const back = () => {
    history.push(`/reservations/${domain}`)
  }

  const onFormChange = async ({ date, ...props }) => {
    if (areaId && date && date !== data.date) {
      await fetchSlots({ date, areaId: areaId })
    }

    date = date || data.date

    console.log({ ...data, ...props, date })

    setData({ ...data, ...props, date })
  }

  const onCreate = () => {
    create(data)
  }

  const slots = formatSlots(area.slots)
  const formData = { ...data, terms: area.terms }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Reservation`}
      backButtonLabel={i18n`Reservations`}
      disableSubmitButton={!formData.range}
    >
      <Form data={formData} onChange={onFormChange} areas={stores.areas.list} slots={slots} />
    </Section>
  )
}

export default view(ReservationCreate)
