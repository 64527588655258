import React from 'react'
import { Route as BaseRoute, Redirect } from 'react-router'
import { stores } from 'data'

const Route = ({ isPrivate, ...props }) => {
  return isPrivate ? <PrivateRoute {...props} /> : <PublicRoute {...props} />
}

export const PrivateRoute = ({ component: Comp, ...props }) => {
  return stores.auth.isSignedIn() ? (
    <BaseRoute
      {...props}
      render={routeProps => <Comp {...props} {...routeProps} {...stores.settings} />}
    />
  ) : (
    <Redirect to="/signin" />
  )
}

export const PublicRoute = ({ component: Comp, ...props }) => {
  return stores.auth.isSignedIn() ? (
    <Redirect to="/" />
  ) : (
    <BaseRoute {...props} render={routeProps => <Comp {...props} {...routeProps} />} />
  )
}

export default Route
