import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { Dialog } from 'components'
import value from 'lib/value'
import i18n from 'lib/i18n'

const CommentDialog = ({ onAction, ...props }) => {
  const [text, setText] = useState('')
  const onChange = ({ text }) => setText(text)

  return (
    <Dialog
      noActions
      noPadding
      maxWidth="md"
      title={i18n`Reason`}
      onAction={({ ok }) => onAction({ ok, text })}
      {...props}
    >
      <TextField
        fullWidth
        multiline
        rowsMax="4"
        InputProps={{
          disableUnderline: true
        }}
        placeholder={i18n`Write your comment here`}
        name="text"
        value={text}
        id="text-field"
        onChange={value(onChange, 'text')}
      />
    </Dialog>
  )
}

CommentDialog.defaultProps = {
  onAction: () => {}
}

export default CommentDialog
