export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(4),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(2),
    marginBottom: 0
  },
  subtitle: {
    padding: theme.spacing(),
    paddingLeft: 0
  },
  text: {
    padding: theme.spacing(2),
    paddingLeft: 0
  },
  button: {
    margin: theme.spacing(2),
    marginBottom: 0
  },
  buttonIcon: {
    margin: theme.spacing()
  }
})
