import React from 'react'
import { Switch } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import PollList from './list'
import PollCreate from './create'
import PollDetail from './detail'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/polls/:domain" component={PollList} />
      <PrivateRoute exact path="/polls/:domain/create" component={PollCreate} />
      <PrivateRoute exact path="/polls/:domain/:id" component={PollDetail} />
    </Switch>
  </Layout>
)
