import React, { Component, Fragment } from 'react'
import Section from 'components/section'
import DeleteDialog from 'components/delete-dialog'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

class GateDetail extends Component {
  state = {
    data: {},
    errors: {},
    isValid: false,
    deleteDialog: false
  }

  get id() {
    return this.props.match.params.id
  }

  get domain() {
    return this.props.match.params.domain
  }

  get gate() {
    return stores.gates.get(this.id)
  }

  get isSameData() {
    return (
      this.gate.title === this.state.data.title &&
      (this.gate.coords || []).join(',') === this.state.data.coords &&
      this.gate.active === this.state.data.active
    )
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.domain !== prevProps.match.params.domain) {
      this.fetch()
    }
  }

  reset = () => {
    this.setState({
      data: {},
      errors: {},
      isValid: false
    })
  }

  fetch = async () => {
    try {
      await stores.gates.fetch(this.domain, { id: this.id })
    } catch (err) {
      toast.error(i18n`Unable to fetch gate information`)
      this.back()
    }
  }

  update = async data => {
    try {
      if ('coords' in data) {
        data.coords = data.coords ? data.coords.split(',') : []
      }
      await stores.gates.update(this.domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update gate`)
    }
    this.reset()
  }

  destroy = async data => {
    try {
      await stores.gates.destroy(this.domain, data)
      this.back()
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        this.back()
      } else {
        toast.error(i18n`Unable to delete gate`)
      }
    }
  }

  back = () => {
    this.props.history.push(`/resources/${this.domain}/gates`)
    return null
  }

  onFormChange = (data, isValid, errors) => {
    data = pick(data, ['id', 'title', 'coords', 'active'])
    this.setState({ data, isValid, errors })
  }

  onUpdate = () => {
    this.update(this.state.data)
  }

  onDeleteAction = ({ ok }) => {
    if (ok) this.destroy({ id: this.id })
    this.closeDeleteDialog()
  }

  openDeleteDialog = () => {
    this.setState({ deleteDialog: true })
  }

  closeDeleteDialog = () => {
    this.setState({ deleteDialog: false })
  }

  render() {
    if (!this.gate) return this.back()
    const { isValid, deleteDialog } = this.state

    const data = {
      ...this.gate,
      coords: (this.gate.coords || []).join(','),
      ...this.state.data
    }

    return (
      <Fragment>
        <Section
          {...this.props}
          noAssign
          data={data}
          title={i18n`Gate Detail`}
          backButtonLabel={i18n`Gates`}
          onBack={this.back}
          onCancel={this.back}
          onSubmit={this.onUpdate}
          onDelete={this.openDeleteDialog}
          disableSubmitButton={!isValid || this.isSameData}
        >
          <Form isUpdate data={data} onChange={this.onFormChange} />
        </Section>
        <DeleteDialog open={deleteDialog} title={i18n`Delete gate`} onAction={this.onDeleteAction}>
          <p>{i18n`Are you sure you want to delete this gate?`}</p>
        </DeleteDialog>
      </Fragment>
    )
  }
}

export default view(GateDetail)
