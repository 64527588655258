import notifications from 'data/notification/store'
import reservations from 'data/reservation/store'
import communities from 'data/community/store'
import documents from 'data/document/store'
import members from 'data/member/store'
import incidents from 'data/incident/store'
import settings from 'data/settings/store'
import accounts from 'data/account/store'
import officers from 'data/officer/store'
import sessions from 'data/session/store'
import admins from 'data/admin/store'
import stats from 'data/stats/store'
import passes from 'data/pass/store'
import units from 'data/unit/store'
import areas from 'data/area/store'
import gates from 'data/gate/store'
import polls from 'data/poll/store'
import auth from 'data/auth/store'
import bus from 'lib/bus'

export const stores = {
  notifications,
  reservations,
  communities,
  documents,
  members,
  incidents,
  officers,
  accounts,
  sessions,
  settings,
  admins,
  passes,
  units,
  areas,
  gates,
  stats,
  polls,
  auth
}

bus.on('*', (type, payload, ...rest) => {
  Object.keys(stores).forEach(key => {
    const store = stores[key]
    if (store.event) store.event({ type, payload }, ...rest)
  })
})
