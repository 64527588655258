import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.polls',
        content: (
          <div>
            <h2>{i18n`Poll List`}</h2>
            <span>{i18n`This is the poll list section where all recent polls will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.polls .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search and create polls.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for polls.`}</span>
          </div>
        )
      },
      {
        selector: '.create-action',
        content: (
          <div>
            <h2>{i18n`Create Poll`}</h2>
            <span>{i18n`Create a new poll.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Poll Record`}</h2>
            <span>{i18n`This is a poll. You can click on any individual poll row to get additional details.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Poll Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
