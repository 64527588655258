import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import useForm from 'react-hook-form'
import isEmail from 'is-email'
import { withTheme } from 'hocs'
import { Form } from 'components'
import { stores } from 'data'
import { isMobile } from 'lib/phone'
import i18n from 'lib/i18n'

const Account = ({ data, buttonRef, classes, onSubmit }) => {
  const form = useForm({ mode: 'onSubmit' })
  const { register, handleSubmit, errors } = form

  const code = 'CR'

  return (
    <Form noValidate buttonRef={buttonRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container style={{ padding: `0px 60px` }}>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <Typography variant="h6">{i18n`User Information`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="name"
            name="name"
            type="text"
            label={i18n`Full name`}
            defaultValue={data.name || ''}
            className={classes.textField}
            inputRef={register({
              required: i18n`Field required`
            })}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : i18n`Eg. ${'John Doe'}`}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            type="email"
            label={i18n`Email`}
            defaultValue={data.email || ''}
            className={classes.textField}
            inputRef={register({
              required: i18n`Field required`,
              validate: async value => {
                if (isEmail(value)) {
                  const { ok } = await stores.accounts.exists(value)
                  return !ok || i18n`Email already exist`
                }
                return i18n`Invalid email`
              }
            })}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : i18n`Eg. ${'john.doe@acme.com'}`}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="phone"
            name="phone"
            type="phone"
            label={i18n`Phone`}
            defaultValue={data.phone || ''}
            className={classes.textField}
            inputRef={register({
              required: i18n`Field required`,
              validate: value => isMobile(value, code) || i18n`Invalid mobile number`
            })}
            error={Boolean(errors.phone)}
            helperText={errors.phone ? errors.phone.message : i18n`Eg. ${'88887777'}`}
          />
        </Grid>
      </Grid>
    </Form>
  )
}

Account.defaultProps = {
  data: {},
  isValid: true,
  onChange: () => {},
  onSignUp: () => {}
}

export default withTheme('form')(Account)
