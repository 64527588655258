import React from 'react'
import AreaDetail from './detail'
import AreaCreate from './create'
import AreaList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <AreaCreate {...props} />
  else if (id && id !== 'create') return <AreaDetail {...props} />
  else return <AreaList {...props} />
}

