import isValidEmail from 'is-email'
import isValidURL from 'is-url'
import { toPhone } from 'lib/phone'
import * as errors from './errors'

export const isRequired = text => {
  return text ? null : errors.required
}

export const isEmail = text => {
  return !text || isValidEmail(text) ? null : errors.email
}

export const isUrl = text => {
  return !text || isValidURL(text) ? null : errors.url
}

export const isMobile = text => {
  return !text || toPhone(text) ? null : errors.mobile
}

export const isEmailOrMobile = text => {
  if (!text) return null
  return isValidEmail(text) || toPhone(text) ? null : errors.emailOrMobile
}

export const isSlug = text => {
  if (!text) return null
  return /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(text) ? null : errors.slug
}

export const isCoordinate = text => {
  if (!text) return null
  const position = text.split(',')
  if (position.length !== 2) return errors.coordinate
  const [lat, lng] = position
  const re = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/
  return re.test(lat.trim()) && re.test(lng.trim()) ? null : errors.coordinate
}
