import React, { Fragment } from 'react'
import clsx from 'clsx'
import formatTime from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import addDays from 'date-fns/addDays'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { mapStatus } from 'data/reservation/maps'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { Select, Editor } from 'components'
import { withTheme } from 'hocs'
import createStyled from 'lib/styled'
import { parseDuration } from 'lib/utils'
import i18n from 'lib/i18n'

const Styled = createStyled('reservations')
const PickerRightIcon = <KeyboardArrowRightIcon />
const PickerLeftIcon = <KeyboardArrowLeftIcon />

const ReservationForm = ({ data, areas = [], slots = [], classes, isUpdate, onChange }) => {
  areas = areas.filter(a => a.reservable)
  const today = new Date()
  const minDate = startOfDay(today)
  const maxDate = endOfDay(addDays(today, 60))

  const timeRange = isUpdate
    ? formatTime(data.startTime, 'h:mm a') + ' - ' + formatTime(data.endTime, 'h:mm a')
    : null

  const parsedDuration = parseDuration(data.duration)

  const duration = isUpdate
    ? parsedDuration === 1
      ? parsedDuration + ' ' + i18n`hour`
      : parsedDuration + ' ' + i18n`hours`
    : null

  return (
    <Styled>
      {({ classes: reservationClasses }) => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            {!isUpdate && (
              <Fragment>
                <Grid item xs={12} md={6}>
                  {areas.length === 0 ? (
                    <Typography variant="subtitle1" className={classes.errorText}>
                      {i18n`No reservable areas available`}
                    </Typography>
                  ) : (
                    <Select
                      required
                      name="areaId"
                      itemLabel="title"
                      itemValue="id"
                      id="area-field"
                      label={i18n`Common Area`}
                      data={areas}
                      value={data.areaId || ''}
                      onChange={onChange}
                      className={classes.textField}
                    />
                  )}
                </Grid>
                {Boolean(data.areaId) && (
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      required
                      format="dd/MM/yyyy"
                      label={i18n`Date`}
                      minDate={minDate}
                      maxDate={maxDate}
                      value={data.date || today}
                      className={classes.textField}
                      rightArrowIcon={PickerRightIcon}
                      leftArrowIcon={PickerLeftIcon}
                      onChange={date => onChange({ date })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  {Boolean(slots.length) && (
                    <FormControl margin="normal">
                      <InputLabel shrink>{i18n`Available time slots`}</InputLabel>
                      <div className={classes.slots}>
                        {slots.map(slot => (
                          <Button
                            className={clsx(classes.slotButton, {
                              [classes.activeSlotButton]: data.range === slot.range
                            })}
                            key={slot.range}
                            color={data.range === slot.range ? 'primary' : 'default'}
                            variant={data.range === slot.range ? 'contained' : 'text'}
                            onClick={() => onChange({ range: slot.range })}
                          >
                            {slot.label}
                          </Button>
                        ))}
                      </div>
                    </FormControl>
                  )}
                  {Boolean(data.areaId) && data.date && !slots.length && (
                    <Typography variant="subtitle1" className={classes.errorText}>
                      {i18n`No time slot available for this date`}
                    </Typography>
                  )}
                </Grid>
              </Fragment>
            )}

            {isUpdate && (
              <Fragment>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={data.owner.name}
                    label={i18n`User`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={data.area.title}
                    label={i18n`Area`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={formatTime(data.created, 'd/MM/yyyy h:mm a')}
                    label={i18n`Created`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={formatTime(data.updated, 'd/MM/yyyy h:mm a')}
                    label={i18n`Updated`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={formatTime(data.startTime, 'd/MM/yyyy')}
                    label={i18n`Date`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={timeRange}
                    label={i18n`Time`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={duration}
                    label={i18n`Duration`}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={mapStatus(data.status)}
                    label={i18n`Status`}
                    className={classes.textField}
                    inputProps={{
                      readOnly: true,
                      className: reservationClasses[`STATUS_${data.status}`]
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {(isUpdate || Boolean(slots.length)) && (
              <Grid item xs={12}>
                <Editor label={i18n`Terms of use`} preview value={data.terms} />
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Styled>
  )
}

export default withTheme('form')(ReservationForm)
