export const runner = (field, name, ...validations) => {
  return state => {
    for (const validation of validations) {
      const fn = validation(state[field], state)

      if (typeof fn === 'function') {
        return { [field]: fn(name) }
      }
    }

    return null
  }
}

export const run = (state, rules) => {
  if (typeof rules === 'function') {
    rules = rules(state)
  }

  return rules.reduce((memo, rule) => {
    return { ...memo, ...rule(state) }
  }, {})
}
