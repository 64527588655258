import { API_URL } from 'config'
import * as storage from 'lib/storage'
import sync from 'lib/sync'
import qs from 'lib/qs'

const signout = () => {
  storage.clear()
  storage.set('signout', Date.now())
}

export const request = async ({ path, method = 'GET', body, query, headers: hdrs = {} }) => {
  const token = storage.get('token')
  const { isFile } = hdrs

  const headers = !isFile
    ? {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...hdrs
      }
    : {}

  if (token && !headers['Authorization']) {
    headers['Authorization'] = `Bearer ${token}`
  }

  try {
    body = body && !isFile ? JSON.stringify(body) : body
  } catch (error) {
    console.error(error)
  }

  let url = `${API_URL}${path}`

  if (query && Object.keys(query).length) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + qs(query)
  }

  try {
    const response = await sync(url, { body, method, headers })
    const type = response.headers.get('content-type')

    if (response.status >= 200 && response.status < 300) {
      return type.indexOf('text') === -1 ? response.json() : response.text()
    }

    if (response.status === 401) signout()

    const error = (await response.json()) || {}
    error.response = response

    throw new Error(error.message || response.statusText || 'Unknown Error')
  } catch (error) {
    if (error.message === 'Unauthorized') signout()
    else throw error
  }
}

export const get = (path, query, headers) => {
  return request({ path, query, method: 'GET', headers })
}

export const post = (path, body, headers) => {
  return request({ path, method: 'POST', body, headers })
}

export const put = (path, body, headers) => {
  return request({ path, method: 'PUT', body, headers })
}

export const patch = (path, body, headers) => {
  return request({ path, method: 'PATCH', body, headers })
}

export const del = (path, body = {}, headers) => {
  return request({ path, method: 'DELETE', body, headers })
}

export const cvs = (path, query) => {
  return get(path, query, {
    'Content-Type': 'application/text'
  })
}
