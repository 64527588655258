import React from 'react'
import OfficerDetail from './detail'
import OfficerCreate from './create'
import OfficerList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <OfficerCreate {...props} />
  else if (id && id !== 'create') return <OfficerDetail {...props} />
  else return <OfficerList {...props} />
}
