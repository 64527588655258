import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Button } from 'components'
import i18n from 'lib/i18n'

export default ({ email, emailDomain, code, onCancel }) => (
  <div>
    <Typography variant="h6">{i18n`Authenticating`}</Typography>
    <br />
    <Typography>
      {i18n`We sent an email to`} <strong>{email}</strong>.
    </Typography>
    <br />
    <Typography>
      {i18n`Please verify that the provided security code we sent matches the name of the`}
      <strong> {i18n`costarrican tree`}</strong> {i18n`bellow`}:
    </Typography>
    <br />
    <Typography variant="h4">{code}</Typography>
    <br />
    <Typography>{i18n`Waiting for confirmation...`}</Typography>
    <br />
    <Button variant="text" color="secondary" onClick={onCancel}>{i18n`Cancel`}</Button>
    <Button
      target="_blank"
      href={`http://${emailDomain}`}
      variant="contained"
      color="primary"
    >{i18n`go to ${emailDomain}`}</Button>
  </div>
)
