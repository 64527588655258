export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  INCIDENT_CREATED: {
    backgroundColor: '#4CAF50'
  },
  INCIDENT_UPDATED: {
    backgroundColor: '#2196F3'
  },
  INCIDENT_COMMENTED: {
    backgroundColor: '#8E24AA'
  },
  INCIDENT_STATUS_CHANGED: {
    backgroundColor: '#E65100'
  },

  STATUS_OPEN: {
    color: '#2196F3'
  },
  STATUS_ON_HOLD: {
    color: '#E65100'
  },
  STATUS_IN_PROGRESS: {
    color: '#8E24AA'
  },
  STATUS_RESOLVED: {
    color: '#43A047'
  },
  STATUS_DUPLICATE: {
    color: '#546E7A'
  },
  STATUS_CLOSED: {
    color: '#43A047'
  },
  STATUS_INVALID: {
    color: '#E91E63'
  }
})
