import React, { useState } from 'react'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinearProgress from '@material-ui/core/LinearProgress'
import clsx from 'clsx'
import Dialog from 'components/dialog'
import FileDrop from 'components/file-drop'
import FileInput from 'components/file-input'
import Button from 'components/button'
import { withTheme } from 'hocs'
import bytesToSize from 'lib/bytes-to-size'
import i18n from 'lib/i18n'

const UploadDialog = React.memo(
  ({ maxSize, accept, open, classes, progress, className, title, text, ...props }) => {
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    const onDrop = files => {
      reset()
      if (files.length) onFile(files[0])
    }

    const onFile = file => {
      if (!isFileTypeValid(file)) {
        return setError(i18n`File must be a CSV document`)
      }

      if (!isFileSizeValid(file)) {
        return setError(i18n`File size must be under ${bytesToSize(maxSize)}`)
      }

      setFile(file)
    }

    const onRemove = () => {
      setFile(null)
    }

    const onAction = data => {
      props.onAction(data)
      onRemove()
      reset()
    }

    const reset = () => {
      setFile(null)
      setError(null)
    }

    const isFileTypeValid = ({ type }) => {
      if (!accept) return /text\/csv/i.test(type)
      const types = accept.split(',')
      return types.includes(type)
    }

    const isFileSizeValid = ({ size }) => {
      return size < maxSize
    }

    const size = file && file.size ? bytesToSize(file.size) : ''

    return (
      <Dialog
        open={open}
        title={title || i18n`Import data`}
        maxWidth="xs"
        okLabel={i18n`Import`}
        okDisabled={!file}
        className={clsx('upload-dialog', className)}
        onAction={({ ok }) => onAction({ ok, data: { file, error, maxSize } })}
      >
        {error && <p className={classes.error}>{error}</p>}

        <FileDrop onDrop={onDrop}>
          {file ? (
            <div>
              <InsertDriveFileIcon className={classes.icon} />
              <p>
                <span>
                  {file.name} ({size})
                </span>
              </p>
              <Button variant="text" onClick={onRemove}>
                {i18n`Remove`}
              </Button>
            </div>
          ) : (
            <div>
              <CloudUploadIcon className={classes.icon} />
              <p>{text || i18n`Drag and drop your CSV file here or`}</p>
              <FileInput
                variant="text"
                name="file"
                id="file-input"
                accept={accept || 'text/csv'}
                onChange={onFile}
              >
                {i18n`Select file`}
              </FileInput>
            </div>
          )}
        </FileDrop>
        {Boolean(progress) && <LinearProgress variant="determinate" value={progress} />}
      </Dialog>
    )
  }
)

UploadDialog.defaultProps = {
  accept: '',
  maxSize: 3000000
}

export default withTheme('uploadDialog')(UploadDialog)
