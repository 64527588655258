import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import CloseIcon from '@material-ui/icons/Close'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EditIcon from '@material-ui/icons/Edit'
import CommentIcon from '@material-ui/icons/Comment'
import capitalize from 'lodash.capitalize'
import formatTs from 'date-fns/format'
import { Table, Section } from 'components'
import * as cons from 'data/incident/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import { mapStatus, mapTypes } from 'data/incident/maps'
import { withTheme } from 'hocs'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'

const getData = ({ type, ...log }, incident, { classes }) => {
  const title = capitalize(type.replace(/_/g, ' '))
  const time = formatTs(log.ts, 'd/m/yyyy h:mm a')

  log.user = log.user || {}

  switch (type) {
    case cons.INCIDENT_CREATED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <SmartphoneIcon />
          </Avatar>
        ),
        text: (
          <span>
            "{mapTypes(incident.type)}" reported by {log.user.name}
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
    case cons.INCIDENT_STATUS_CHANGED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <BookmarkIcon />
          </Avatar>
        ),
        text: (
          <span>
            {log.user.name} changed status to "{mapStatus(log.incidentStatus)}"
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
    case cons.INCIDENT_UPDATED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <EditIcon />
          </Avatar>
        ),
        text: <span>Incident updated by {log.user.name}</span>,
        loggedBy: log.user.name,
        event: title
      }
    case cons.INCIDENT_COMMENTED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <CommentIcon />
          </Avatar>
        ),
        text: <span>Incident commented by {log.user.name}</span>,
        loggedBy: log.user.name,
        event: title
      }
  }

  return {}
}

class PassLogs extends Component {
  state = {
    keyword: ''
  }

  columns = [
    { id: 'avatar', label: i18n`Indicator` },
    { id: 'event', label: i18n`Event` },
    { id: 'text', label: i18n`Description` },
    { id: 'time', label: i18n`TIme`, last: true }
  ]

  get id() {
    return this.props.match.params.id
  }

  get incident() {
    return stores.incidents.get(this.id) || { logs: [] }
  }

  get domain() {
    return this.props.match.params.domain
  }

  get data() {
    return (this.incident.logs || []).map(log => getData(log, this.incident, this.props))
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { id, domain } = prevProps.match.params
    if (this.domain !== domain || this.id !== id) {
      this.fetch()
    }
  }

  fetch = async () => {
    try {
      await stores.incidents.fetch(this.domain, { id: this.id })
      await stores.incidents.fetchLogs(this.domain, { id: this.id })
    } catch (err) {
      toast.error(i18n`Unable to fetch logs`)
    }
  }

  back = () => {
    this.props.history.push(`/incidents/${this.domain}/${this.id}`)
  }

  render() {
    return (
      <Section
        {...this.props}
        noAssign
        noActions
        noDelete
        title={i18n`Incident Logs`}
        backButtonLabel={i18n`Incident`}
        onBack={this.back}
      >
        <Table
          noFooter
          noToolbar
          noActions
          noDownload
          noCheckbox
          noSmartphonebox
          noItemSwitch
          noItemDelete
          noWrapperMargins
          data={this.data}
          columns={this.columns}
          style={{ margin: 0 }}
        />
      </Section>
    )
  }
}

export default compose(
  withTheme('incidents'),
  view
)(PassLogs)
