export default (fn, delay = 1500, onStart) => {
  let timer = null

  return (...args) => {
    clearTimeout(timer)

    if (typeof onStart === 'function') {
      console.log('DEBOUNCING START')
      onStart(...args)
    }

    timer = setTimeout(() => fn(...args), delay)
  }
}
