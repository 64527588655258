import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom'

// const LinkRef = React.forwardRef(function LinkRef(props, ref) {
//   return <Link {...props} innerRef={ref} />
// })

const AccountSpot = ({ user, classes }) => (
  <IconButton
    color="inherit"
    className={classes.userButton}
    to="/account/profile"
    component={Link}
    id="account-spot"
  >
    <Avatar alt={user.name} src={user.avatar || '/images/profile.jpg'} className={classes.avatar} />
  </IconButton>
)

export default AccountSpot
