import React, { useState } from 'react'
import clsx from 'clsx'
import SwipeableRoutes from 'react-swipeable-routes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'
import Units from './units'
import Areas from './areas'
import Gates from './gates'

const Resources = React.memo(({ classes, match: { params } }) => {
  const [tab, setTab] = useState(0)

  const onChange = value => {
    setTab(value)
  }

  const { domain, id } = params

  return (
    <Layout>
      {!id && (
        <Tabs
          value={tab}
          textColor="primary"
          indicatorColor="primary"
          className={classes.root}
          onChange={(e, tab) => onChange(tab)}
        >
          <Tab to={`/resources/${domain}/units`} label={i18n`Units`} component={Link} />
          <Tab to={`/resources/${domain}/areas`} label={i18n`Areas`} component={Link} />
          <Tab to={`/resources/${domain}/gates`} label={i18n`Gates`} component={Link} />
        </Tabs>
      )}
      <SwipeableRoutes onChangeIndex={onChange} slideClassName={clsx('slide', classes.slide)}>
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/resources/:domain/units/:id?"
          component={Units}
        />
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/resources/:domain/areas/:id?"
          component={Areas}
        />
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/resources/:domain/gates/:id?"
          component={Gates}
        />
      </SwipeableRoutes>
    </Layout>
  )
})

export default withTheme('tabs')(Resources)
