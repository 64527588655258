import React from 'react'
import i18n from 'lib/i18n'
import Button from '@material-ui/core/Button'
import Guide from 'components/guide'

const getSteps = ({ onClose }) => {
  return [
    {
      content: ({ goTo, ...opts }) => (
        <div>
          {console.log(opts)}
          <h2>{i18n`SPRY Quick Guide`}</h2>
          <p>{i18n`This quick tour will help you get started and get familiarized with SPRY main navigation options.`}</p>
          <Button variant="contained" color="primary" onClick={() => goTo(1)}>
            {i18n`Take me to the tour`} 🚌
          </Button>
        </div>
      ),
      props: {
        showButtons: false,
        showNavigation: false
      },
      style: {
        textAlign: 'center'
      }
    },
    {
      selector: '.sidebar',
      content: (
        <div>
          <h2>{i18n`Main menu`}</h2>
          <p>{i18n`This is the main menu, where you get access to all SPRY options.`}</p>
        </div>
      ),
      style: {
        top: 30
      }
    },
    {
      selector: '.minimize-sidebar-button',
      content: (
        <div>
          <h2>{i18n`Sidebar toggler`}</h2>
          <p>{i18n`From here you can minimize and maximize the sidebar menu.`}</p>
        </div>
      ),
      style: {
        top: 30,
        left: 10
      }
    },
    {
      selector: '#menu-link-communities',
      content: (
        <div>
          <h2>{i18n`Communities`}</h2>
          <p>{i18n`If this is the first time you are using SPRY you will need to create your community under this option. You can also view your existing communities from here.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-resources',
      content: (
        <div>
          <h2>{i18n`Resources`}</h2>
          <p>{i18n`View and configure community resources such as units, common areas and gates.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-members',
      content: (
        <div>
          <h2>{i18n`Members`}</h2>
          <p>{i18n`View and configure community members such as members, officers and admins.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-passes',
      content: (
        <div>
          <h2>{i18n`Passes`}</h2>
          <p>{i18n`Check your community passes activity and also create new passes.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-reservations',
      content: (
        <div>
          <h2>{i18n`Reservations`}</h2>
          <p>{i18n`Check your community recent reservations and create new reservations.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-incidents',
      content: (
        <div>
          <h2>{i18n`Incidents`}</h2>
          <p>{i18n`Manage and keep track of your community reported incidents.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-notifications',
      content: (
        <div>
          <h2>{i18n`Notifications`}</h2>
          <p>{i18n`Manage and send notifications to the members of your community.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-documents',
      content: (
        <div>
          <h2>{i18n`Documents`}</h2>
          <p>{i18n`Manage and upload important documents to your community.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-poll',
      content: (
        <div>
          <h2>{i18n`Polls`}</h2>
          <p>{i18n`Manage live polls.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-dashboard',
      content: (
        <div>
          <h2>{i18n`Dashboard`}</h2>
          <p>{i18n`Get a quick view with relevant information of your community activity.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-help',
      content: (
        <div>
          <h2>{i18n`Help`}</h2>
          <p>{i18n`Get help and download useful files like import CSV templates.`}</p>
        </div>
      )
    },
    {
      selector: '#menu-link-signout',
      content: (
        <div>
          <h2>{i18n`Signout`}</h2>
          <p>{i18n`Here is where you can logout the application.`}</p>
        </div>
      )
    },
    {
      selector: '#community-selector',
      content: (
        <div>
          <h2>{i18n`Community Selector`}</h2>
          <p>{i18n`Select or switch communities.`}</p>
        </div>
      ),

      style: {
        top: 10,
        left: 10
      }
    },
    {
      selector: '#locale-selector',
      content: (
        <div>
          <h2>{i18n`Language Selector`}</h2>
          <p>{i18n`For convinience you can use this option to switch between languages.`}</p>
          <p>{i18n`At the moment we support English and Spanish.`}</p>
        </div>
      ),
      style: {
        top: 10,
        right: 10
      }
    },
    {
      selector: '#account-spot',
      content: (
        <div>
          <h2>{i18n`Account`}</h2>
          <p>{i18n`Check and update your account profile information and revoke application open sessions.`}</p>
        </div>
      ),
      style: {
        top: 10,
        right: 10
      }
    },
    {
      content: ({ goTo }) => (
        <div>
          <h2>{i18n`Congratulations!`} 🥇</h2>
          <p>{i18n`You have successfully completed our quick tour guide and we hope you can start using SPRY with success.`}</p>
          <p>{i18n`For any additional help you can click on help button in the main menu.`}</p>
          <Button variant="contained" color="primary" onClick={onClose}>
            {i18n`Close`}
          </Button>
        </div>
      ),
      props: {
        showButtons: false,
        showNavigation: false
      },
      style: {
        textAlign: 'center'
      }
    }
  ]
}

export default props => <Guide steps={getSteps(props)} {...props} />
