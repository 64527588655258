import Fuse from 'fuse.js'

const searches = {}

export default (keys = [], options) => {
  const id = 'search_' + keys.join('-')

  if (searches[id]) return searches[id]

  const settings = {
    threshold: 0.1,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    findAllMatches: false,
    shouldSort: true,
    //include: ['score'],
    limit: 10,
    ...options
  }

  const custom = new Fuse([], {
    ...settings,
    keys
  })

  const pin = new Fuse([], {
    ...settings,
    threshold: 0,
    keys: ['pin']
  })

  const location = new Fuse([], {
    ...settings,
    threshold: 0,
    keys: ['locationTitle']
  })

  const key = new Fuse([], {
    ...settings,
    keys: ['name', 'owner.name', 'docId']
  })

  searches[id] = (data, term) => {
    if (keys.length) {
      custom.setCollection(data)
      return custom.search(term)
    }

    if (/^\d{6}$/.test(term)) {
      pin.setCollection(data)
      return pin.search(term)
    }

    if (/^\d{1,4}$/.test(term)) {
      location.setCollection(data)
      return location.search(`filial ${term}`)
    }

    key.setCollection(data)
    return key.search(term).slice(0, settings.limit)
  }

  return searches[id]
}
