import React, { Component } from 'react'
import createStyled from 'lib/styled'

export default (theme, options) => {
  const Styled = createStyled(theme, options)

  return Comp => class extends Component {
    render() {
      return (
        <Styled>
          {props => (
            <Comp
              {...this.props}
              {...props}
            />
          )}
        </Styled>
      )
    }
  }
}