export const POLL_CREATED = 'poll::created'
export const POLL_UPDATED = 'poll::updated'
export const POLL_DELETED = 'poll::deleted'
export const POLL_STARTED = 'poll::started'
export const POLL_ENDED = 'poll::ended'

export const COMMUNITY_CHANGED = 'community::change'

export const SEARCH_KEYS = ['title', 'text', 'status']
export const DRAFT = 'DRAFT'
export const ENDED = 'ENDED'
export const STARTED = 'STARTED'
