import { store as makeStore } from 'react-easy-state'
import { Queue, priorities } from '@nx-js/queue-util'
import { observe } from '@nx-js/observer-util'
import * as storage from './storage'
import { createMerge } from './utils'

export { view } from 'react-easy-state'
export { default as createList } from './list'

const scheduler = new Queue(priorities.HIGH)

export const persist = (store, key) => {
  if (!process.browser) {
    return store
  }

  const merge = createMerge(store)
  const sync = () => storage.set(key, store)

  // We need to make sure we first merge data from storage before starting to observe
  // the store else weird stuff happens like sudently user get signedout his account
  // because the default state is set and also persisted overwriting the storage data.
  merge(storage.get(key, {}))
  observe(sync, { scheduler })
  return store
}

export const createStore = (state = {}, key) => {
  const isPersisted = Boolean(key)

  if (isPersisted) {
    const storedState = storage.get(key, {})
    state = { ...state, ...storedState }
  }

  const store = makeStore(state)
  return isPersisted ? persist(store, key) : store
}
