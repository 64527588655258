import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { Dialog } from 'components'
import i18n from 'lib/i18n'

const OptionDialog = ({ className, onAction, options, ...props }) => (
  <Dialog
    noActions
    noPadding
    maxWidth="xs"
    title={i18n`Select option`}
    className={clsx('option-dialog', className)}
    {...props}
  >
    <List>
      {options.map(({ id, label, icon, ...rest }) => (
        <ListItem button key={id} onClick={() => onAction({ label, id, ok: true })}>
          {Boolean(icon) && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={label} />
        </ListItem>
      ))}
      <ListItem button onClick={() => onAction({ ok: false })}>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="button" gutterBottom>
            {i18n`Cancel`}
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  </Dialog>
)

export default OptionDialog
