import React from 'react'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Validator } from 'components'
import { withTheme } from 'hocs'
import { isRequired } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const rules = [runner('code', i18n`Unit`, isRequired), runner('title', i18n`Title`, isRequired)]

const UnitForm = ({ data, classes, isUpdate, onChange }) => (
  <Validator
    data={data}
    rules={rules}
    onValidate={onChange}
    render={validate => (
      <form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              type="text"
              name="code"
              id="code-field"
              label={i18n`Unit`}
              value={data.code || ''}
              className={classes.textField}
              onChange={value(validate, 'code')}
              helperText={!isUpdate && i18n`E.g. f1`}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              type="text"
              name="title"
              id="title-field"
              label={i18n`Title`}
              value={data.title || ''}
              className={classes.textField}
              onChange={value(validate, 'title')}
              helperText={!isUpdate && i18n`E.g. Filial 1`}
              autoComplete="off"
            />
          </Grid>
          {isUpdate && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                name="members"
                id="members-field"
                label={i18n`Members`}
                value={data.members}
                className={classes.textField}
                inputProps={{ readOnly: isUpdate }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={i18n`Active`}
              control={
                <Switch
                  name="active"
                  id="active-field"
                  className={classes.textField}
                  checked={Boolean(data.active)}
                  onChange={e => validate({ active: e.target.checked })}
                />
              }
            />
          </Grid>
        </Grid>
      </form>
    )}
  />
)

export default withTheme('form')(UnitForm)
