import React, { PureComponent } from 'react'
import toast from 'lib/toast'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import Form from './form'

class OfficerCreate extends PureComponent {
  state = {
    data: {},
    errors: {},
    isValid: false
  }

  get domain() {
    return this.props.match.params.domain
  }

  create = async data => {
    try {
      data.active = 'active' in data ? Boolean(data.active) : true
      await stores.officers.create(this.domain, data)
      toast.success(i18n`Created`)
      this.back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        this.back()
      } else {
        toast.error(i18n`Unable to create officer`)
      }
    }
  }

  back = () => {
    this.props.history.push(`/members/${this.domain}/officers`)
  }

  onFormChange = (data, isValid, errors) => {
    this.setState({ data, isValid, errors })
  }

  onCreate = () => {
    this.create(this.state.data)
  }

  render() {
    const { isValid } = this.state
    return (
      <Section
        noDelete
        noAssign
        onBack={this.back}
        onCancel={this.back}
        onSubmit={this.onCreate}
        title={i18n`Create Officer`}
        backButtonLabel={i18n`Officers`}
        disableSubmitButton={!isValid}
      >
        <Form {...this.state} onChange={this.onFormChange} />
      </Section>
    )
  }
}

export default view(OfficerCreate)
