import React from 'react'
import { Switch } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import NotificationList from './list'
import NotificationCreate from './create'
import NotificationDetail from './detail'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/notifications/:domain" component={NotificationList} />
      <PrivateRoute exact path="/notifications/:domain/create" component={NotificationCreate} />
      <PrivateRoute exact path="/notifications/:domain/:id" component={NotificationDetail} />
    </Switch>
  </Layout>
)
