import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'gates')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  return list.replace(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const upload = async (domain, data) => {
  const res = await api.upload(domain, data)
  return list.add(res)
}

const download = async (domain, data = []) => {
  const ids = data.map(item => item.id).join(',')
  return api.download(domain, { ids })
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.GATE_CREATED:
    case cons.GATE_UPDATED:
      return list.add(payload)
    case cons.GATE_DELETED:
      return list.remove(payload)
    default:
      break
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  destroy,
  upload,
  download,
  event
})
