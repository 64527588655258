import React, { useEffect } from 'react'
import subDays from 'date-fns/subDays'
import getTime from 'date-fns/getTime'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import capitalize from 'lodash.capitalize'
import { Layout, Section } from 'components'
import { withStyles } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import sorter from 'lib/sorter'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import { Doughnut, Line, Bar } from 'components/chart'

const styles = theme => ({
  root: {
    height: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  gridContainer: {
    '@media print': {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  grid: {
    '@media print': {
      width: '200px !important',
      border: '1px solid red'
    }
  },
  container: {
    maxWidth: 350,
    textAlign: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center'

    '@media print': {
      border: 'none'
    }
  },
  indicator: {
    color: theme.palette.primary.main
  },
  title: {
    paddingBottom: theme.spacing(2)
  }
})

const bardata = {
  labels: ['January', 'February'],
  datasets: [
    {
      label: 'Person',
      backgroundColor: 'rgba(33, 150, 243, 0.9)',
      borderColor: 'rgba(33, 150, 243, 0.9)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [5, 6]
    },
    {
      label: 'Service',
      backgroundColor: 'rgba(33, 150, 243, 0.6)',
      borderColor: 'rgba(33, 150, 243, 0.6)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [4, 7]
    }
  ]
}

const Home = ({ classes }) => {
  const lineOptions = {
    label: i18n`Visits`,
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(33, 150, 243, 0.9)',
    borderColor: 'rgba(33, 150, 243, 0.9)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(33, 150, 243, 0.9)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(33, 150, 243, 0.9)',
    pointHoverBorderColor: 'rgba(33, 150, 243, 0.9)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10
  }

  const donutOptions = {
    backgroundColor: [
      'rgba(33, 150, 243, 1)',
      'rgba(33, 150, 243, 0.6)',
      'rgba(33, 150, 243, 0.3)'
    ],
    hoverBackgroundColor: [
      'rgba(33, 150, 243, 0.9)',
      'rgba(33, 150, 243, 0.7)',
      'rgba(33, 150, 243, 0.4)'
    ]
  }

  const barOptions = {
    backgroundColor: 'rgba(33, 150, 243, 0.9)',
    borderColor: '#fff',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: '#fff'
  }

  const monthAbbrs = [
    i18n`Jan`,
    i18n`Feb`,
    i18n`Mar`,
    i18n`Apr`,
    i18n`May`,
    i18n`Jun`,
    i18n`Jul`,
    i18n`Aug`,
    i18n`Sep`,
    i18n`Oct`,
    i18n`Nov`,
    i18n`Dec`
  ]

  const monthNames = [
    i18n`January`,
    i18n`February`,
    i18n`March`,
    i18n`April`,
    i18n`May`,
    i18n`June`,
    i18n`July`,
    i18n`August`,
    i18n`September`,
    i18n`October`,
    i18n`November`,
    i18n`December`
  ]

  const domain = stores.communities.getDomain()
  const counts = stores.stats.counts || []
  const days = stores.stats.day || []
  const months = stores.stats.month || []
  const segments = stores.stats.segment || []
  const monthSegments = stores.stats.monthSegment || []

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const fetch = async () => {
    try {
      const start = getTime(subDays(new Date(), 5))
      const end = getTime(new Date())
      await stores.stats.fetch(domain, { start, end })
    } catch (error) {
      toast.error(i18n`Unable to fetch stats`)
    }
  }

  const getDays = () => {
    const sortedDays = days.sort(sorter(['month', 'day']))
    const options = lineOptions
    const labels = sortedDays.map(item => String(item.day))
    const data = sortedDays.map(item => item.checkins)
    return { labels, datasets: [{ ...options, data }] }
  }

  const getMonths = () => {
    const options = lineOptions
    const labels = months.map(item => monthNames[item.month - 1])
    const data = months.map(item => item.checkins)
    return { labels, datasets: [{ ...options, data }] }
  }

  const getSegments = () => {
    const orderedSegments = segments.reverse()
    const options = donutOptions
    const labels = orderedSegments.map(item => capitalize(item.type) + ` ( ${item.checkins} )`)
    const data = orderedSegments.map(item => item.checkins)
    return { labels, datasets: [{ ...options, data }] }
  }

  const getMonthSegments = () => {
    const labels = []
    const colors = [1, 0.6]
    const sortedMonthSegments = monthSegments.sort(sorter(['month', 'type']))

    const data = sortedMonthSegments.reduce((acc, { type, checkins, month }) => {
      type = capitalize(type)
      const monthName = monthNames[month - 1]

      if (labels.indexOf(monthName) === -1) {
        labels.push(monthName)
      }

      acc[type] = acc[type] || { data: [] }
      acc[type].data.push(checkins)
      acc[type].label = type
      return acc
    }, {})

    const datasets = Object.keys(data).map((segment, index) => ({
      ...barOptions,
      data: data[segment].data,
      label: data[segment].label,
      backgroundColor: `rgba(33, 150, 243, ${colors[index]})`,
      hoverBackgroundColor: `rgba(33, 150, 243, ${colors[index] - 0.1})`
    }))

    return { labels, datasets }
  }

  const stats = {
    days: getDays(),
    months: getMonths(),
    segments: getSegments(),
    monthSegments: getMonthSegments()
  }

  return (
    <Layout>
      <Section noBackButton noAssign noActions noDelete title={i18n`Dashboard`}>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid className={classes.grid} item xs={12} sm={3}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="subtitle1">
                Pases Hoy
              </Typography>
              <Typography className={classes.indicator} variant="h2">
                {counts.today.total}
              </Typography>
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={3}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="subtitle1">
                Pases por semana
              </Typography>
              <Typography className={classes.indicator} variant="h2">
                {counts.week.total}
              </Typography>
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={3}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="subtitle1">
                Pases por mes
              </Typography>
              <Typography className={classes.indicator} variant="h2">
                {counts.month.total}
              </Typography>
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={3}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="subtitle1">
                Pases por mes
              </Typography>
              <Typography className={classes.indicator} variant="h2">
                {counts.year.total}
              </Typography>
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={12}>
            <Typography className={classes.text} variant="h4">{i18n`Visits`}</Typography>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="subtitle1">{i18n`By day`}</Typography>
              <Line data={stats.days} legend={{ labels: { usePointStyle: true } }} />
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={1}>
              <Typography
                className={classes.title}
                variant="subtitle1"
              >{i18n`By month`}</Typography>
              <Line data={stats.months} legend={{ labels: { usePointStyle: true } }} />
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={1}>
              <Typography
                className={classes.title}
                variant="subtitle1"
              >{i18n`By segments`}</Typography>
              <Doughnut data={stats.segments} />
            </Paper>
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={6}>
            <Paper className={classes.paper} elevation={1}>
              <Typography
                className={classes.title}
                variant="subtitle1"
              >{i18n`By segments by month`}</Typography>
              <Bar
                data={stats.monthSegments}
                options={{
                  responsive: true,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0
                        }
                      }
                    ]
                  }
                }}
                legend={{ labels: { usePointStyle: true } }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

// class Home extends Component {
//   state = {
//     loading: true
//   }

//   get lineOptions() {
//     return {
//       label: i18n`Visits`,
//       fill: false,
//       lineTension: 0.1,
//       backgroundColor: 'rgba(33, 150, 243, 0.9)',
//       borderColor: 'rgba(33, 150, 243, 0.9)',
//       borderCapStyle: 'butt',
//       borderDash: [],
//       borderDashOffset: 0.0,
//       borderJoinStyle: 'miter',
//       pointBorderColor: 'rgba(33, 150, 243, 0.9)',
//       pointBackgroundColor: '#fff',
//       pointBorderWidth: 1,
//       pointHoverRadius: 5,
//       pointHoverBackgroundColor: 'rgba(33, 150, 243, 0.9)',
//       pointHoverBorderColor: 'rgba(33, 150, 243, 0.9)',
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10
//     }
//   }

//   get donutOptions() {
//     return {
//       backgroundColor: [
//         'rgba(33, 150, 243, 1)',
//         'rgba(33, 150, 243, 0.6)',
//         'rgba(33, 150, 243, 0.3)'
//       ],
//       hoverBackgroundColor: [
//         'rgba(33, 150, 243, 0.9)',
//         'rgba(33, 150, 243, 0.7)',
//         'rgba(33, 150, 243, 0.4)'
//       ]
//     }
//   }

//   get barOptions() {
//     return {
//       backgroundColor: 'rgba(33, 150, 243, 0.9)',
//       borderColor: '#fff',
//       borderWidth: 1,
//       hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//       hoverBorderColor: '#fff'
//     }
//   }

//   get monthAbbrs() {
//     return [
//       i18n`Jan`,
//       i18n`Feb`,
//       i18n`Mar`,
//       i18n`Apr`,
//       i18n`May`,
//       i18n`Jun`,
//       i18n`Jul`,
//       i18n`Aug`,
//       i18n`Sep`,
//       i18n`Oct`,
//       i18n`Nov`,
//       i18n`Dec`
//     ]
//   }

//   get monthNames() {
//     return [
//       i18n`January`,
//       i18n`February`,
//       i18n`March`,
//       i18n`April`,
//       i18n`May`,
//       i18n`June`,
//       i18n`July`,
//       i18n`August`,
//       i18n`September`,
//       i18n`October`,
//       i18n`November`,
//       i18n`December`
//     ]
//   }

//   get domain() {
//     return stores.communities.getDomain()
//   }

//   get counts() {
//     return stores.stats.counts || []
//   }

//   get days() {
//     return stores.stats.day || []
//   }

//   get months() {
//     return stores.stats.month || []
//   }

//   get segments() {
//     return stores.stats.segment || []
//   }

//   get monthSegments() {
//     return stores.stats.monthSegment || []
//   }

//   componentDidMount() {
//     fetch()
//   }

//   fetch = async () => {
//     try {
//       const start = getTime(subDays(new Date(), 5))
//       const end = getTime(new Date())
//       await stores.stats.fetch(this.domain, { start, end })
//     } catch (error) {
//       toast.error(i18n`Unable to fetch stats`)
//     }
//   }

//   getDays = () => {
//     const days = this.days.sort(sorter(['month', 'day']))
//     const options = this.lineOptions
//     const labels = days.map(item => String(item.day))
//     const data = days.map(item => item.checkins)
//     return { labels, datasets: [{ ...options, data }] }
//   }

//   getMonths = () => {
//     const months = this.months
//     const options = this.lineOptions
//     const labels = months.map(item => this.monthNames[item.month - 1])
//     const data = months.map(item => item.checkins)
//     return { labels, datasets: [{ ...options, data }] }
//   }

//   getSegments = () => {
//     const segments = this.segments.reverse()
//     const options = this.donutOptions
//     const labels = segments.map(item => capitalize(item.type) + ` ( ${item.checkins} )`)
//     const data = segments.map(item => item.checkins)
//     return { labels, datasets: [{ ...options, data }] }
//   }

//   getMonthSegments = () => {
//     const labels = []
//     const colors = [1, 0.6]
//     const monthSegments = this.monthSegments.sort(sorter(['month', 'type']))

//     const data = monthSegments.reduce((acc, { type, checkins, month }) => {
//       type = capitalize(type)
//       const monthName = this.monthNames[month - 1]

//       if (labels.indexOf(monthName) === -1) {
//         labels.push(monthName)
//       }

//       acc[type] = acc[type] || { data: [] }
//       acc[type].data.push(checkins)
//       acc[type].label = type
//       return acc
//     }, {})

//     const datasets = Object.keys(data).map((segment, index) => ({
//       ...this.barOptions,
//       data: data[segment].data,
//       label: data[segment].label,
//       backgroundColor: `rgba(33, 150, 243, ${colors[index]})`,
//       hoverBackgroundColor: `rgba(33, 150, 243, ${colors[index] - 0.1})`
//     }))

//     return { labels, datasets }
//   }

//   render() {
//     const { classes } = this.props
//     const days = this.getDays()
//     const months = this.getMonths()
//     const segments = this.getSegments()
//     const monthSegments = this.getMonthSegments()
//     return (
//       <Layout>
//         <Section noBackButton noAssign noActions noDelete title={i18n`Dashboard`}>
//           <Grid className={classes.gridContainer} container spacing={3}>
//             <Grid className={classes.grid} item xs={12} sm={3}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography className={classes.title} variant="subtitle1">
//                   Pases Hoy
//                 </Typography>
//                 <Typography className={classes.indicator} variant="h2">
//                   {this.counts.today.total}
//                 </Typography>
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={3}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography className={classes.title} variant="subtitle1">
//                   Pases por semana
//                 </Typography>
//                 <Typography className={classes.indicator} variant="h2">
//                   {this.counts.week.total}
//                 </Typography>
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={3}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography className={classes.title} variant="subtitle1">
//                   Pases por mes
//                 </Typography>
//                 <Typography className={classes.indicator} variant="h2">
//                   {this.counts.month.total}
//                 </Typography>
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={3}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography className={classes.title} variant="subtitle1">
//                   Pases por mes
//                 </Typography>
//                 <Typography className={classes.indicator} variant="h2">
//                   {this.counts.year.total}
//                 </Typography>
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={12}>
//               <Typography className={classes.text} variant="h4">{i18n`Visits`}</Typography>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={6}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography
//                   className={classes.title}
//                   variant="subtitle1"
//                 >{i18n`By day`}</Typography>
//                 <Line data={days} legend={{ labels: { usePointStyle: true } }} />
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={6}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography
//                   className={classes.title}
//                   variant="subtitle1"
//                 >{i18n`By month`}</Typography>
//                 <Line data={months} legend={{ labels: { usePointStyle: true } }} />
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={6}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography
//                   className={classes.title}
//                   variant="subtitle1"
//                 >{i18n`By segments`}</Typography>
//                 <Doughnut data={segments} />
//               </Paper>
//             </Grid>
//             <Grid className={classes.grid} item xs={12} sm={6}>
//               <Paper className={classes.paper} elevation={1}>
//                 <Typography
//                   className={classes.title}
//                   variant="subtitle1"
//                 >{i18n`By segments by month`}</Typography>
//                 <Bar
//                   data={monthSegments}
//                   options={{
//                     responsive: true,
//                     scales: {
//                       yAxes: [
//                         {
//                           ticks: {
//                             min: 0
//                           }
//                         }
//                       ]
//                     }
//                   }}
//                   legend={{ labels: { usePointStyle: true } }}
//                 />
//               </Paper>
//             </Grid>
//           </Grid>
//         </Section>
//       </Layout>
//     )
//   }
// }

export default compose(
  withStyles(styles),
  view
)(Home)
