import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiSelect from '@material-ui/core/Select'
import { withTheme } from 'hocs'

const Select = ({
  data,
  value,
  label,
  native,
  name,
  style,
  classes,
  onChange,
  noLabel,
  required,
  itemValue,
  itemLabel,
  variant,
  helperText,
  disabled,
  disableUnderline,
  component,
  inputRef,
  error,
  ...props
}) => (
  <FormControl
    variant={variant}
    className={classes.selectControl}
    error={error}
    style={style}
    disabled={disabled}
  >
    {!noLabel && (
      <InputLabel focused={false} required={required} htmlFor={props.id}>
        {label}
      </InputLabel>
    )}
    <MuiSelect
      name={name}
      value={value}
      native={native}
      inputRef={inputRef}
      inputProps={{ ...props, style: { backgroundColor: 'red' } }}
      disableUnderline={disableUnderline}
      classes={{ selectMenu: classes.selectMenu }}
      onChange={
        onChange
          ? ({ target }) =>
              onChange({
                [target.name]: target.value
              })
          : undefined
      }
    >
      {data.map(item =>
        native ? (
          <option key={item.id || item[itemValue]} value={item[itemValue]}>
            {item[itemLabel]}
          </option>
        ) : (
          <MenuItem key={item.id || item[itemValue]} value={item[itemValue]}>
            {item[itemLabel]}
          </MenuItem>
        )
      )}
    </MuiSelect>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
)

Select.defaultProps = {
  required: false,
  itemValue: 'id',
  itemLabel: 'label',
  noLabel: false
}

export default withTheme('form')(Select)
