import React from 'react'
import { withStyles } from 'hocs'
import Logo from 'components/svgs/logo-icon'

const Splash = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <Logo width="80" />
    </div>
  </div>
)

const styles = {
  root: {
    height: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  container: {
    maxWidth: 350,
    textAlign: 'center'
  }
}

export default withStyles(styles)(Splash)
