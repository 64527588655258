import React, { Fragment, useEffect, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import { PlaceHolder, Button, Table, DeleteDialog } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import { SEARCH_KEYS } from 'data/notification/constants'
import { ReactComponent as HeroImage } from 'svgs/mail_box.svg'
import createSearch from 'lib/search'
import { timeAgo, truncate } from 'lib/utils'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const NotificationList = ({ classes, match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { keyword } = state
  const { domain } = match.params
  const { list } = stores.notifications
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'title', label: i18n`Title` },
    { id: 'body', label: i18n`Message` },
    { id: 'views', label: i18n`Views` },
    { id: 'created', label: i18n`Published` },
    { id: 'updated', label: i18n`Updated` }
  ]

  const selected = state.selected.map(id => ({ id }))

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      console.log('FETCHING NOTIFICATION DATA', domain)
      await stores.notifications.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching notifications`)
    }
  }

  const destroy = async data => {
    try {
      await stores.notifications.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.areas.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete notification(s)`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <HeroImage width="250" height="250" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE A NOTIFICATION`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">{i18n`No notifications to show at this moment`}</Typography>
      </PlaceHolder>
    )
  }

  const renderRow = (node, key, data) => {
    // if (key === 'status') {
    //   node = <Typography className={classes[`STATUS_${data.status}`]}>{mapStatus(data.status)}</Typography>
    // }

    if (key === 'updated') {
      node = <div className={classes.noWrap}>{timeAgo(data.updated)}</div>
    } else if (key === 'created') {
      node = <div className={classes.noWrap}>{timeAgo(data.created)}</div>
    } else if (key === 'title') {
      node = truncate(data.title, 50)
    } else if (key === 'body') {
      node = truncate(data.body, 50)
    }

    return node
  }

  console.log(data)

  return (
    <Fragment>
      <Table
        {...props}
        noInvite
        noAssign
        noSwitch
        noUpload
        noDownload
        noCheckbox
        noItemSwitch
        noItemDelete
        noSelectedDownload
        data={data}
        selected={state.selected}
        columns={columns}
        className="notifications"
        title={i18n`Notifications`}
        onRow={page}
        onDetail={page}
        onCreate={() => page('create')}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
        row={renderRow}
      />
      <DeleteDialog
        open={state.deleteDialog}
        title={i18n`Delete notifications`}
        onAction={onDeleteAction}
      >
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected notifications?`
            : i18n`Are you sure you want to delete this notification?`}
        </p>
      </DeleteDialog>
      <Guide open={state.guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default compose(
  withTheme('notifications'),
  view
)(NotificationList)
