import React from 'react'
import { Switch } from "react-router-dom"
import { Layout, PrivateRoute } from 'components'
import PassList from './list'
import PassCreate from './create'
import PassDetail from './detail'
import PassLogs from './logs'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/passes/:domain" component={PassList} />
      <PrivateRoute exact path="/passes/:domain/create/:type" component={PassCreate} />
      <PrivateRoute exact path="/passes/:domain/:id/logs" component={PassLogs} />
      <PrivateRoute exact path="/passes/:domain/:id" component={PassDetail} />
    </Switch>
  </Layout>
)