export default ({ spacing }) => ({
  root: {
    width: '100%',
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },
  toolbar: {},
  backButton: {},
  buttonIcon: {
    marginRight: spacing()
  },
  content: {
    marginLeft: spacing(3),
    marginRight: spacing(3),
    marginBottom: spacing(4)
  },
  toolbarActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    marginTop: spacing(2)
  },
  button: {
    marginLeft: spacing(2)
  },
  title: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex'
  }
})
