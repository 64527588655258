import React, { Fragment, useRef, useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import Logo from 'components/svgs/logo-icon'
import toast from 'lib/toast'
import Account from './account'
import Plan from './plan'
import Community from './community'

const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '50px 20px',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(/images/logo-bg.png)',
    backgroundPosition: 'center',
    boxSizing: 'border-box'
  },
  content: {
    maxWidth: 500,
    minWidth: 400,
    minHeight: 500
  },

  steps: {
    padding: 20,
    paddingTop: 0
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(6)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Onboarding = ({ location }) => {
  const steps = [
    {
      label: i18n`Account`,
      step: Account
    },
    {
      label: i18n`Community`,
      step: Community
    },
    {
      label: i18n`Plan`,
      step: Plan
    }
  ]

  const getPlan = () => {
    const params = new URLSearchParams(location.search)
    return params.get('plan')
  }

  const plan = getPlan()
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const [data, setData] = useReducer(reducer, { plan })
  const [creating, setCreating] = useState(false)
  const refs = useRef()

  const reset = () => {
    setStep(0)
    setCreating(false)
  }

  const onNext = () => {
    refs.current.click()
  }

  const onBack = () => {
    setStep(step - 1)
  }

  const onSubmit = data => {
    setData(data)
    setStep(step + 1)
  }

  useEffect(() => {
    if (step === steps.length) {
      signup(normalize(data))
    }
  }, [step])

  const signup = async data => {
    try {
      setCreating(true)
      await stores.accounts.create(data)
      setCreating(false)
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
      } else {
        toast.error(i18n`Unable to process request, please try again.`)
      }
      reset()
    }
  }

  const clean = obj =>
    Object.keys(obj).reduce((acc, key) => {
      return obj[key] ? { ...acc, [key]: obj[key] } : acc
    }, {})

  const normalize = data => {
    data = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      community: {
        plan: data.plan,
        title: data.title,
        domain: data.domain,
        phone: data.contactPhone
      }
    }

    data.community = clean(data.community)
    return clean(data)
  }

  const renderContent = Content => {
    return <Content data={data} buttonRef={refs} onSubmit={onSubmit} />
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        {step !== steps.length && <Logo width="80" style={{ marginTop: 30 }} />}
        <div className={classes.steps}>
          {step !== steps.length && (
            <Stepper activeStep={step}>
              {steps.map(({ label }) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          )}
          <div>
            {step === steps.length ? (
              <div>
                {creating ? (
                  <Fragment>
                    <br />
                    <br />
                    <br />
                    <img src="/images/house-loading.gif" width="250" />
                    <Typography variant="h6">{i18n`Please be patient`}</Typography>
                    <Typography className={classes.instructions}>
                      {i18n`We are creating your account.`}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <br />
                    <br />
                    <br />
                    <img src="/images/ok.gif" width="200" />
                    <Typography variant="h6">{i18n`Congratulations!`}</Typography>
                    <Typography className={classes.instructions}>
                      {i18n`Your account has been successfully created.`}
                    </Typography>
                  </Fragment>
                )}
                {!creating && (
                  <Button color="primary" to="/signin" component={Link}>
                    {i18n`Sign in`}
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <div className={classes.instructions}>{renderContent(steps[step].step)}</div>

                <div>
                  {step === 0 ? (
                    <Button
                      className={classes.button}
                      color="primary"
                      to="/signin"
                      component={Link}
                    >
                      {i18n`Sign in`}
                    </Button>
                  ) : (
                    <Button color="primary" onClick={onBack} className={classes.button}>
                      {i18n`Back`}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                    className={classes.button}
                  >
                    {step === steps.length - 1 ? i18n`Finish` : i18n`Next`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default view(Onboarding)
