import { useState } from 'react'
import useBus from './use-bus'

const useSync = () => {
  const [syncing, setSyncing] = useState(false)

  useBus('online::syncing', isSyncing => {
    setSyncing(isSyncing)
  })

  return syncing
}

export default useSync
