import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Validator } from 'components'
import { withTheme } from 'hocs'
import { isRequired, isEmailOrMobile } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const PermanentForm = ({ data, classes, onChange }) => {
  const rules = [
    runner('firstName', i18n`First name`, isRequired),
    runner('lastName', i18n`Last name`, isRequired),
    runner('contact', i18n`Email or mobile`, isEmailOrMobile)
  ]

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                fullWidth
                id="first-name-field"
                label={i18n`First name`}
                className={classes.textField}
                onChange={value(validate, 'firstName')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                fullWidth
                id="last-name-field"
                label={i18n`Last name`}
                className={classes.textField}
                onChange={value(validate, 'lastName')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                name="contact"
                id="contact-field"
                label={i18n`Email or mobile`}
                className={classes.textField}
                onChange={value(validate, 'contact')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                id="docid-field"
                label={i18n`Identification`}
                className={classes.textField}
                onChange={value(validate, 'docId')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rowsMax="2"
                id="note-field"
                label={i18n`Note`}
                className={classes.textField}
                onChange={value(validate, 'note')}
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(PermanentForm)
