import React, { Fragment } from 'react'
import isDate from 'date-fns/isValid'
import toDate from 'date-fns/toDate'
import getTime from 'date-fns/getTime'
import formatTime from 'date-fns/format'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { KeyboardTimePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Validator, Editor, MultiSegment } from 'components'
import InputAdornment from '@material-ui/core/InputAdornment'
import { isRequired, isCoordinate } from 'lib/validate/rules'
import { withTheme } from 'hocs'
import { parseDuration } from 'lib/utils'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const AreaForm = ({ data, classes, isUpdate, onChange }) => {
  const rules = [
    runner('title', i18n`Title`, isRequired),
    runner('coords', i18n`Coordinates`, isCoordinate)
  ]

  const days = [
    i18n`Sunday`,
    i18n`Monday`,
    i18n`Tuesday`,
    i18n`Wednesday`,
    i18n`Thursday`,
    i18n`Friday`,
    i18n`Saturday`
  ]

  const onTimeChange = obj => {
    const startTime = getTime(obj.startTime)
    const endTime = getTime(data.endTime)

    if (obj.startTime && startTime > endTime) {
      obj.endTime = toDate(startTime)
    }

    onChange(obj)
  }

  const time = new Date()

  const startTime = isDate(data.startTime)
    ? data.startTime
    : new Date(formatTime(time, `MM/dd/yyyy`) + ' ' + data.startTime)

  const endTime = isDate(data.endTime)
    ? data.endTime
    : new Date(formatTime(time, `MM/dd/yyyy`) + ' ' + data.endTime)

  data.duration = parseDuration(data.duration)

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="title-field"
                label={i18n`Title`}
                value={data.title || ''}
                className={classes.textField}
                onChange={value(validate, 'title')}
                helperText={!isUpdate && i18n`E.g. BBQ Ranch`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="coords-field"
                label={i18n`Coordinates`}
                value={data.coords || ''}
                className={classes.textField}
                onChange={value(validate, 'coords')}
                helperText={i18n`E.g. ${'9.934739,‎-84.087502'} (lat, lng)`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label={i18n`Active`}
                control={
                  <Switch
                    name="active"
                    id="active-field"
                    className={classes.textField}
                    checked={Boolean(data.active)}
                    onChange={e => validate({ active: e.target.checked })}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label={i18n`Reservable`}
                control={
                  <Switch
                    name="reservable"
                    id="reservable-field"
                    className={classes.textField}
                    checked={Boolean(data.reservable)}
                    onChange={e => validate({ reservable: e.target.checked })}
                  />
                }
              />
            </Grid>
            {Boolean(data.reservable) && (
              <Fragment>
                <Grid item xs={12} sm={3}>
                  <KeyboardTimePicker
                    format="h:mm a"
                    label={i18n`Start time`}
                    value={startTime}
                    className={classes.textField}
                    onChange={startTime => onTimeChange({ startTime })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KeyboardTimePicker
                    format="h:mm a"
                    label={i18n`End time`}
                    value={endTime}
                    className={classes.textField}
                    onChange={endTime => onTimeChange({ endTime })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="duration-field"
                    label={i18n`Duration`}
                    type="number"
                    value={data.duration}
                    className={classes.textField}
                    onChange={value(validate, 'duration')}
                    inputProps={{ min: '1', max: '23' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{i18n`Hours`}</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiSegment
                    value={data.weekDays.map(day => String(day))}
                    name="weekdays"
                    label={i18n`Week days`}
                    onChange={weekDays => validate({ weekDays })}
                  >
                    <span value="7">{i18n`Sun`}</span>
                    <span value="1">{i18n`Mon`}</span>
                    <span value="2">{i18n`Tue`}</span>
                    <span value="3">{i18n`Wed`}</span>
                    <span value="4">{i18n`Thu`}</span>
                    <span value="5">{i18n`Fri`}</span>
                    <span value="6">{i18n`Sat`}</span>
                  </MultiSegment>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Editor
                    label={i18n`Terms of use`}
                    value={data.terms}
                    onChange={terms => validate({ terms })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Editor preview value={data.terms} />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(AreaForm)
