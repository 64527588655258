import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.members',
        content: (
          <div>
            <h2>{i18n`Member List`}</h2>
            <span>{i18n`This is the member list section where all members that belong to this community will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.members .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create, import, export members.`}</span>
          </div>
        )
      },
      {
        selector: '.members .search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for members.`}</span>
          </div>
        )
      },
      {
        selector: '.members .create-action',
        content: (
          <div>
            <h2>{i18n`Create Member`}</h2>
            <span>{i18n`Create a new member.`}</span>
          </div>
        )
      },
      {
        selector: '.members .upload-action',
        content: (
          <div>
            <h2>{i18n`Import Members`}</h2>
            <span>{i18n`Import members using a CSV file.`}</span>
            <br />
            <span>{i18n`Download the CSV import template from the help section under the main menu.`}</span>
          </div>
        )
      },
      {
        selector: '.members .download-action',
        content: (
          <div>
            <h2>{i18n`Download Members`}</h2>
            <span>{i18n`Export all members as a CSV file.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Member Record`}</h2>
            <span>{i18n`Row with basic information about the member. You can click on the row to get more details of the member.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child .cell-units',
        content: (
          <div>
            <h2>{i18n`Assigned Units`}</h2>
            <span>{i18n`Here you will see units assigned to the member.`}</span>
            <br />
            <span>{i18n`You can unassign a unit from a member by clicking on its X.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child td:last-child span',
        content: (
          <div>
            <h2>{i18n`Member Activation`}</h2>
            <span>{i18n`You can activate or inactivate a member by clicking this switch button.`}</span>
          </div>
        )
      },
      {
        selector: '.members .guide-action',
        content: (
          <div>
            <h2>{i18n`Member Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
