import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 86 101" {...props}>
  	<g fill="none">
  		<g fill="#2196F3">
  			<polyline points="5.52 53.167 5.52 28.644 43.136 6.926 86.212 31.796 86.212 25.493 43.136 0.622 0.061 25.493 0.061 62.624 5.52 65.775 17.185 72.51 43.144 87.49 75.293 68.927 75.293 44.404 69.834 41.253 69.834 65.776 43.136 81.19 5.52 59.472 5.52 53.167"/>
  			<polyline points="16.439 59.472 16.439 34.948 43.136 19.535 80.753 41.253 80.753 72.08 43.136 93.798 0.061 68.928 0.061 75.232 43.136 100.1 86.212 75.232 86.212 38.1 85.736 37.825 43.136 13.231 37.677 16.382 32.218 19.535 10.98 31.796 10.98 56.32 16.439 59.472"/>
  			<path d="m38.713 62.444h8.847l-2.544-9.33-0.841-3.076 0.937-0.334c2.338-0.835 3.907-3.052 3.907-5.541 0-3.249-2.634-5.883-5.883-5.883-3.248 0-5.882 2.635-5.882 5.883 0 2.489 1.568 4.706 3.906 5.541l0.937 0.334-3.384 12.406"/>
  		</g>
  	</g>
  </svg>
)
