import { get, post, put, del } from 'lib/api'

export const fetch = (domain, data) => get(`/${domain}/officers`, data)
export const create = (domain, data) => post(`/${domain}/officers`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/officers/${id}`, data)
export const destroy = (domain, data) => del(`/${domain}/officers`, data)

export const upload = (domain, { file }) => {
  const data = new FormData()
  data.append('file', file)
  return post(`/${domain}/officers/import`, data, { isFile: true })
}

export const download = (domain, data) => get(`/${domain}/officers/export`, data)
