import React, { useReducer } from 'react'
import toast from 'lib/toast'
import isEmail from 'is-email'
import capitalize from 'lodash.capitalize'
import getDate from 'date-fns/getDate'
import getHours from 'date-fns/getHours'
import setHours from 'date-fns/setHours'
import endOfDay from 'date-fns/endOfDay'
import getTime from 'date-fns/getTime'
import formatDate from 'date-fns/format'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import PermanentPass from './forms/permanent'
import TemporalPass from './forms/temporal'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const PassCreate = ({ match, history }) => {
  const resetData = () => {
    const startDate = new Date()
    const startTime = new Date()
    const endDate = new Date()
    let endTime = new Date()

    if (getHours(startTime) >= getHours(endTime) && getDate(startDate) === getDate(endDate)) {
      endTime = setHours(endTime, 23)
    }

    return { endTime, endDate, startTime, startDate, weekDays: [], type: 'person', active: false }
  }

  const [state, setState] = useReducer(reducer, {
    errors: {},
    isValid: false,
    data: resetData()
  })

  const { domain, type } = match.params
  const community = stores.communities.getCommunity()

  const create = async data => {
    try {
      data = normalize(data)
      await stores.passes.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create pass`)
      }
    }
  }

  const normalize = ({ type, firstName, lastName, company, contact, ...data }) => {
    data.startDate = getTime(data.startDate)
    data.endDate = endOfDay(data.endDate).getTime()
    data.startTime = formatDate(data.startTime, 'HH:mm')
    data.endTime = formatDate(data.endTime, 'HH:mm')
    data.active = 'active' in data ? Boolean(data.active) : true

    const startDay = formatDate(data.startDate, 'd/M/yy')
    const endDay = formatDate(data.endDate, 'd/M/yy')

    if (type !== 'permanent') {
      data.type = startDay === endDay ? 'temporal' : 'recurrent'
    } else {
      data.type = type
    }

    if (type !== 'service') data.name = `${firstName} ${lastName}`
    else data.company = company

    if (contact) {
      if (isEmail(contact)) data.email = contact
      else data.phone = contact
    }

    data.placeId = community.id
    return data
  }

  const back = () => {
    history.push(`/passes/${domain}`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({
      data: { ...state.data, ...data },
      errors: typeof errors !== 'undefined' ? errors : state.errors,
      isValid: typeof isValid !== 'undefined' ? isValid : state.isValid
    })
  }

  const onCreate = () => {
    create(state.data)
  }

  const capType = capitalize(type)
  const title = i18n`Create ${capType} Pass`

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={title}
      backButtonLabel={i18n`Passes`}
      disableSubmitButton={!state.isValid}
    >
      {type === 'permanent' ? (
        <PermanentPass {...state} data={{ ...state.data, type }} onChange={onFormChange} />
      ) : (
        <TemporalPass {...state} onChange={onFormChange} />
      )}
    </Section>
  )
}

// class PassCreate extends PureComponent {
//   constructor(props) {
//     super(props)

//     this.state = {
//       errors: {},
//       isValid: false,
//       data: this.resetData()
//     }
//   }

//   get domain() {
//     return this.props.match.params.domain
//   }

//   reset = () => {
//     this.setState({
//       data: this.resetData()
//     })
//   }

//   create = async data => {
//     try {
//       data = this.normalize(data)
//       await stores.passes.create(this.domain, data)
//       toast.success(i18n`Created`)
//       this.back()
//     } catch (error) {
//       if (error.message === 'Offline') {
//         toast.warn(i18n`Request queued`)
//         this.back()
//       } else {
//         toast.error(i18n`Unable to create pass`)
//       }
//     }
//   }

//   normalize = ({ type, firstName, lastName, company, contact, ...data }) => {
//     data.startDate = getTime(data.startDate)
//     data.endDate = endOfDay(data.endDate).getTime()
//     data.startTime = formatDate(data.startTime, 'HH:mm')
//     data.endTime = formatDate(data.endTime, 'HH:mm')
//     data.active = 'active' in data ? Boolean(data.active) : true

//     const startDay = formatDate(data.startDate, 'd/M/yy')
//     const endDay = formatDate(data.endDate, 'd/M/yy')

//     if (type !== 'permanent') {
//       data.type = startDay === endDay ? 'temporal' : 'recurrent'
//     } else {
//       data.type = type
//     }

//     if (type !== 'service') data.name = `${firstName} ${lastName}`
//     else data.company = company

//     if (contact) {
//       if (isEmail(contact)) data.email = contact
//       else data.phone = contact
//     }

//     return data
//   }

//   resetData = () => {
//     const startDate = new Date()
//     const startTime = new Date()
//     const endDate = new Date()

//     let endTime = new Date()

//     if (getHours(startTime) >= getHours(endTime) && getDate(startDate) === getDate(endDate)) {
//       endTime = setHours(endTime, 23)
//     }

//     return {
//       endTime,
//       endDate,
//       startTime,
//       startDate,
//       weekDays: [],
//       type: 'person',
//       active: false
//     }
//   }

//   back = () => {
//     this.props.history.push(`/passes/${this.domain}`)
//   }

//   onFormChange = (data, isValid, errors) => {
//     this.setState({
//       data: { ...this.state.data, ...data },
//       errors: typeof errors !== 'undefined' ? errors : this.state.errors,
//       isValid: typeof isValid !== 'undefined' ? isValid : this.state.isValid
//     })
//   }

//   onCreate = () => {
//     this.create(this.state.data)
//   }

//   render() {
//     const {
//       match: { params }
//     } = this.props
//     const { isValid } = this.state
//     const { type } = params
//     const capType = capitalize(type)
//     const title = i18n`Create ${capType} Pass`

//     return (
//       <Section
//         noDelete
//         noAssign
//         onBack={this.back}
//         onCancel={this.back}
//         onSubmit={this.onCreate}
//         title={title}
//         backButtonLabel={i18n`Passes`}
//         disableSubmitButton={!isValid}
//       >
//         {type === 'permanent' ? (
//           <PermanentPass
//             {...this.state}
//             data={{ ...this.state.data, type }}
//             onChange={this.onFormChange}
//           />
//         ) : (
//           <TemporalPass {...this.state} onChange={this.onFormChange} />
//         )}
//       </Section>
//     )
//   }
// }

export default view(PassCreate)
