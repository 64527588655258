import { get, post, put, del } from 'lib/api'

export const fetch = (domain, data) => get(`/${domain}/reservations`, data)
export const create = (domain, data) => post(`/${domain}/reservations`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/reservations/${id}`, data)
export const approve = (domain, { id, ...data }) =>
  put(`/${domain}/reservations/${id}/approve`, data)
export const cancel = (domain, { id, ...data }) => put(`/${domain}/reservations/${id}/cancel`, data)
export const destroy = (domain, data) => del(`/${domain}/reservations`, data)
export const download = (domain, data) => get(`/${domain}/reservations/export`, data)
