import { lighten } from '@material-ui/core/styles/colorManipulator'

export default theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  table: {
    minWidth: 800
  },
  tableWrapper: {
    overflowX: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    margin: theme.spacing(),
    width: '220px'
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  lastCell: {
    textAlign: 'right',
    paddingRight: `${theme.spacing()}px !important`
  },
  lastHeaderCell: {
    textAlign: 'right'
  },
  lastItemCell: {
    textAlign: 'right',
    paddingRight: theme.spacing(3)
  },
  chip: {
    margin: theme.spacing() / 2,
    height: 26
  },
  chipDeleteIcon: {
    margin: '0 2px 0 -8px'
  },
  toolbar: {
    paddingRight: theme.spacing()
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.dark,
          backgroundColor: lighten(theme.palette.secondary.light, 0.4)
        }
      : {
          color: lighten(theme.palette.secondary.light, 0.4),
          backgroundColor: theme.palette.secondary.dark
        },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  }
})
