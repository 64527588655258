import React from 'react'
import Button from '@material-ui/core/Button'
import createStyled from 'lib/styled'

const Styled = createStyled('button')

export default ({ classes, children, ...props }) => (
  <Styled>
    {({ classes }) => (
      <Button variant="contained" color="primary" className={classes.button} {...props}>
        {children}
      </Button>
    )}
  </Styled>
)
