import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { withTheme } from 'hocs'
import { isRequired, isEmail } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

export const rules = [runner('email', 'Email', isRequired, isEmail)]

const SignInForm = ({ data, errors, classes, isValid, onChange, onSignIn }) => (
  <form onSubmit={onSignIn}>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">{i18n`Authenticate`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="email"
          type="email"
          label={i18n`Email`}
          value={data.email}
          className={classes.textField}
          onChange={value(onChange, 'email')}
        />
      </Grid>
      <Grid className={classes.actions} item xs={12}>
        <Button color="primary" to="/onboarding" component={Link}>
          {i18n`Sign up`}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
          {i18n`Sign in`}
        </Button>
      </Grid>
    </Grid>
  </form>
)

export default withTheme('form')(SignInForm)
