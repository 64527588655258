import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'

export default (key, options) => {
  const Styled = ({ children, ...props }) => {
    return children(props)
  }

  Styled.propTypes = {
    children: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  }

  const fnStyles = ({ variables, ...theme }) => {
    console.log(variables)

    const styles = variables[key]
    return typeof styles === 'function' ? styles(theme) : styles
  }

  return withStyles(fnStyles, options)(Styled)
}
