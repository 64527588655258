import React from 'react'
import UnitDetail from './detail'
import UnitCreate from './create'
import UnitList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <UnitCreate {...props} />
  else if (id && id !== 'create') return <UnitDetail {...props} />
  else return <UnitList {...props} />
}
