import React from 'react'
import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import { withTheme } from 'hocs'

const TableToolbar = ({ renderActions, renderTitle, numSelected, classes }) => (
  <Toolbar
    className={clsx('table-toolbar', classes.toolbar, {
      [classes.highlight]: numSelected > 0
    })}
  >
    <div className={classes.title}>{renderTitle(numSelected)}</div>
    <div className={classes.actions}>{renderActions(numSelected)}</div>
  </Toolbar>
)

export default withTheme('table')(TableToolbar)
