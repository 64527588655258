import { lighten } from '@material-ui/core/styles/colorManipulator'

export default ({ palette, spacing }) => ({
  root: {
    position: 'relative',
    height: 300,
    // width: 300,
    border: '1px dashed rgba(0,0,0,.54)',
    borderRadius: 5,
    marginTop: spacing(3),
    marginBottom: spacing(3),
    backgroundColor: '#fafafa' //'rgba(33,150,243,.12)'
  },
  target: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    borderRadius: 2,
    /* --------------- */

    /* a little transition sizzle */
    transition: 'all 150ms linear',

    /* horizontally and vertically center all content */
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    padding: spacing(2)
  },
  p: {
    color: `rgba(0,0,0,.54)`,
    padding: `0px 30px`
  },
  frameDnD: {
    border: 'none',
    backgroundColor: lighten(palette.primary.light, 0.75), //'rgba(33,150,243,.12)',
    boxShadow: 'none',
    zIndex: 50
  },
  targetDnD: {
    boxShadow: `0 0 12px 0 ${lighten(palette.primary.light, 0.12)}`
  }
})
