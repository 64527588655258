const noop = () => {}

export default (url, file, { progressInterval = 1000, onProgress = noop } = {}) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    let interval = null
    let progress = 0

    xhr.upload.onprogress = ({ loaded, total }) => {
      if (progress === 0) {
        interval = setInterval(() => {
          onProgress(progress)
        }, progressInterval)

        onProgress(1)
      }

      progress = loaded / total * 100

      if (progress === 100) {
        clearInterval(interval)
        onProgress(progress)
        progress = 0
      }
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status <= 299) {
          return resolve(xhr.responseText)
        }
        return reject(new Error(`Upload failed. Status ${xhr.status}. Response: ${xhr.responseText}`))
      }
    }

    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.send(file)
  })
}
