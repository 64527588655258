import React, { Fragment, useReducer, useEffect } from 'react'
import getTime from 'date-fns/getTime'
import { Section } from 'components'
import { DeleteDialog } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const NotificationDetail = ({ match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const { user = {} } = stores.accounts
  const notification = stores.notifications.get(id)

  useEffect(() => {
    ack()
  }, [])

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const isSameData =
    notification &&
    notification.title === state.data.title &&
    notification.body === state.data.body &&
    notification.status === state.data.status

  const reset = () => {
    setState({ errors: {}, isValid: false, data: {} })
  }

  const fetch = async () => {
    try {
      await stores.notifications.fetch(domain, { id })
    } catch (error) {
      toast.error(i18n`Unable to fetch notification information`)
      back()
    }
  }

  const ack = async () => {
    try {
      await stores.notifications.ack(domain, { id }, user.id)
    } catch (err) {
      console.log(err)
    }
  }

  const update = async data => {
    try {
      await stores.notifications.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update notification`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.notifications.destroy(domain, data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete notification`)
      }
    }
  }

  const back = () => {
    history.push(`/notifications/${domain}`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    setState({
      data: { ...state.data, ...data },
      errors: typeof errors !== 'undefined' ? errors : state.errors,
      isValid: typeof isValid !== 'undefined' ? isValid : state.isValid
    })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id })
    closeDeleteDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  if (!notification) return back()

  const { isValid, deleteDialog } = state
  const data = { ...notification, ...state.data }
  const isCompleted = data.endTime < getTime(new Date())

  return (
    <Fragment>
      <Section
        {...props}
        noAssign
        data={data}
        onBack={back}
        noDelete={isCompleted}
        onCancel={back}
        onSubmit={onUpdate}
        title={i18n`Notification Detail`}
        backButtonLabel={i18n`Notifications`}
        submitButtonLabel={i18n`Save`}
        onDelete={openDeleteDialog}
        disableSubmitButton={!isValid || isSameData}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <DeleteDialog open={deleteDialog} title={i18n`Delete notification`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this notification?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

// class NotificationDetail extends Component {
//   state = {
//     data: {},
//     errors: {},
//     isValid: false,
//     deleteDialog: false
//   }

//   get id() {
//     return this.props.match.params.id
//   }

//   get user() {
//     return stores.accounts.state.user || {}
//   }

//   get domain() {
//     return this.props.match.params.domain
//   }

//   get notification() {
//     return stores.notifications.get(this.id)
//   }

//   get isSameData() {
//     return (
//       this.notification.title === this.state.data.title &&
//       this.notification.body === this.state.data.body &&
//       this.notification.status === this.state.data.status
//     )
//   }

//   componentDidMount() {
//     this.fetch()
//     this.ack()
//   }

//   componentDidUpdate(prevProps) {
//     if (this.domain !== prevProps.match.params.domain) {
//       this.fetch()
//     }
//   }

//   reset = () => {
//     this.setState({
//       errors: {},
//       isValid: false,
//       data: {}
//     })
//   }

//   fetch = async () => {
//     try {
//       console.log('XXXXXX')
//       await stores.notifications.fetch(this.domain, { id: this.id })
//     } catch (error) {
//       toast.error(i18n`Unable to fetch notification information`)
//       this.back()
//     }
//   }

//   ack = async () => {
//     try {
//       await stores.notifications.ack(this.domain, { id: this.id }, this.user.id)
//     } catch (err) {
//       console.log(err)
//     }
//   }

//   update = async data => {
//     try {
//       await stores.notifications.update(this.domain, data)
//       toast.success(i18n`Updated`)
//     } catch (error) {
//       if (error.message === 'Offline') toast.warn(i18n`Request queued`)
//       else toast.error(i18n`Unable to update notification`)
//     }
//     this.reset()
//   }

//   destroy = async data => {
//     try {
//       await stores.notifications.destroy(this.domain, data)
//       toast.success(i18n`Deleted`)
//       this.back()
//     } catch (error) {
//       console.log(error)
//       if (error.message === 'Offline') {
//         toast.warn(i18n`Request queued`)
//         this.back()
//       } else {
//         toast.error(i18n`Unable to delete notification`)
//       }
//     }
//   }

//   back = () => {
//     this.props.history.push(`/notifications/${this.domain}`)
//     return null
//   }

//   onFormChange = (data, isValid, errors) => {
//     this.setState({
//       data: { ...this.state.data, ...data },
//       errors: typeof errors !== 'undefined' ? errors : this.state.errors,
//       isValid: typeof isValid !== 'undefined' ? isValid : this.state.isValid
//     })
//   }

//   onUpdate = () => {
//     this.update(this.state.data)
//   }

//   onDeleteAction = ({ ok }) => {
//     if (ok) this.destroy({ id: this.id })
//     this.closeDeleteDialog()
//   }

//   openDeleteDialog = () => {
//     this.setState({ deleteDialog: true })
//   }

//   closeDeleteDialog = () => {
//     this.setState({ deleteDialog: false })
//   }

//   render() {
//     if (!this.notification) return this.back()
//     const { isValid, deleteDialog } = this.state

//     const data = {
//       ...this.notification,
//       ...this.state.data
//     }

//     const isCompleted = data.endTime < getTime(new Date())

//     return (
//       <Fragment>
//         <Section
//           {...this.props}
//           noAssign
//           data={data}
//           onBack={this.back}
//           noDelete={isCompleted}
//           onCancel={this.back}
//           onSubmit={this.onUpdate}
//           title={i18n`Notification Detail`}
//           backButtonLabel={i18n`Notifications`}
//           submitButtonLabel={i18n`Save`}
//           onDelete={this.openDeleteDialog}
//           disableSubmitButton={!isValid || this.isSameData}
//         >
//           <Form isUpdate data={data} onChange={this.onFormChange} />
//         </Section>
//         <DeleteDialog
//           open={deleteDialog}
//           title={i18n`Delete notification`}
//           onAction={this.onDeleteAction}
//         >
//           <p>{i18n`Are you sure you want to delete this notification?`}</p>
//         </DeleteDialog>
//       </Fragment>
//     )
//   }
// }

export default compose(
  withTheme('notifications'),
  view
)(NotificationDetail)
