import React, { Fragment } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import i18n from 'lib/i18n'

export default ({ classes, locale, el, open, onMenu, onChange, onClose }) => (
	<Fragment>
		<Button
			aria-owns={open ? 'locale-selector' : null}
			aria-haspopup="true"
			onClick={onMenu}
			color="inherit"
			className={classes.localeButton}
			to="/account/sessions"
			id="locale-selector"
		>
			{locale === 'es' ? 'ES' : 'EN'}
		</Button>
		<Menu id="locale-selector-menu" anchorEl={el} open={open} onClose={onClose}>
			<MenuItem selected={locale === 'en'} onClick={() => onChange('en')}>{i18n`English`}</MenuItem>
			<MenuItem selected={locale === 'es'} onClick={() => onChange('es')}>{i18n`Español`}</MenuItem>
		</Menu>
	</Fragment>
)
