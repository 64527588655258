import React from 'react'
import MemberDetail from './detail'
import MemberCreate from './create'
import MemberList from './list'

export default props => {
  const { id } = props.match.params
  if (id && id === 'create') return <MemberCreate {...props} />
  else if (id && id !== 'create') return <MemberDetail {...props} />
  else return <MemberList {...props} />
}
