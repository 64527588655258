import { detect } from 'detect-browser'
import { createStore } from 'lib/store'
import { createMerge } from 'lib/utils'
import sleep from 'lib/sleep'
import i18n from 'lib/i18n'
import bus from 'lib/bus'
import retry from 'lib/retry'
import * as storage from 'lib/storage'
import * as cons from './constants'
import * as api from './api'

const state = { creds: {}, status: cons.NOT_VERIFIED }
const store = createStore(state, 'auth')
const merge = createMerge(store)

const getEmailDomain = email => {
  if (!email) return
  return email.split('@')[1]
}

const setSession = token => {
  storage.set('token', token)
  return merge({ creds: {}, status: cons.VERIFIED })
}

const destroySession = () => {
  storage.clear()
  storage.set('signout', Date.now())
  merge({ creds: {}, status: cons.NOT_VERIFIED })
}

const signin = async ({ email }) => {
  const { name, os, version } = detect()
  const data = { service: 'cms', email, tokenName: `${os} (${name}@${version})` }
  const { token, code, ttl } = await api.signin(data)
  const creds = { email, token, code, expires: ttl, emailDomain: getEmailDomain(email) }
  return verify(creds)
}

const signout = async local => {
  if (!local) api.signout().catch(destroySession)
  destroySession()
}

const verify = async (creds = {}) => {
  merge({ creds, status: cons.VERIFYING })

  if (isSignedIn()) {
    await sleep(100)
    bus.emit('account::signedin')
    return
  }

  if (!creds.token) return

  let lastError

  const onRetry = error => {
    lastError = error || {}
  }

  try {
    return await retry(
      async bail => {
        if (typeof lastMessage !== 'undefined' && !/incomplete/.test(lastError.message)) {
          return bail(lastError)
        }

        if (Date.now() > store.creds.expires) {
          expire()
          return bail(new Error('Expired'))
        }

        if (store.status !== cons.VERIFYING) return
        const { token } = await api.verify(store.creds)
        if (!token) throw new Error(i18n`Unable to verify token`)
        setSession(token)
        await sleep(100)
        bus.emit('account::signedin')

        return store
      },
      { retries: 100, factor: 1, minTimeout: 2000, onRetry }
    )
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

const expire = () => {
  merge({ creds: {}, status: cons.VERIFICATION_EXPIRED })
}

const cancel = () => {
  merge({ creds: {}, status: cons.NOT_VERIFIED })
}

const isSignedIn = () => {
  const token = storage.get('token')
  return Boolean(token)
}

if (isSignedIn()) {
  setTimeout(() => bus.emit('account::signedin'), 100)
}

export default merge({
  signin,
  signout,
  cancel,
  verify,
  isSignedIn
})
