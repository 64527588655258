export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  STATUS_DRAFT: {
    color: '#F57F17'
  },
  STATUS_ENDED: {
    color: '#E91E63'
  },
  STATUS_STARTED: {
    color: '#388E3C'
  },
  startButton: {
    color: '#388E3C'
  },
  endButton: {
    color: '#E91E63'
  }
})
