import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import { Validator } from 'components'
import { withTheme } from 'hocs'
import { isRequired, isMobile } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const ProfileForm = ({ data, classes, onChange, onChangeAvatar }) => {
  const rules = [
    runner('name', i18n`Name`, isRequired),
    runner('phone', i18n`Mobile number`, isMobile)
  ]

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.avatarContainer}>
                <Avatar
                  alt={data.name}
                  src={data.avatar || '/images/profile.jpg'}
                  className={classes.avatar}
                />
                <Button
                  size="small"
                  color="primary"
                  onClick={onChangeAvatar}
                >{i18n`Change`}</Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name-field"
                label={i18n`Full name`}
                value={data.name || ''}
                className={classes.textField}
                onChange={value(validate, 'name')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email-field"
                label={i18n`Email`}
                value={data.email || ''}
                className={classes.textField}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phone-field"
                label={i18n`Mobile number`}
                value={data.phone || ''}
                className={classes.textField}
                onChange={value(validate, 'phone')}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(ProfileForm)
