import React from 'react'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Validator, Editor, Select } from 'components'
import { withTheme } from 'hocs'
import { isRequired } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const NotificationForm = ({ data, isUpdate, classes, onChange }) => {
  const rules = [runner('title', i18n`Title`, isRequired), runner('body', i18n`Body`, isRequired)]
  const statusOptions = [
    { id: 'DRAFT', label: i18n`Draft` },
    { id: 'PUBLISHED', label: i18n`Published` }
  ]

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    required
                    fullWidth
                    type="text"
                    name="title"
                    id="title-field"
                    autoComplete="off"
                    label={i18n`Message Title`}
                    value={data.title || ''}
                    className={classes.textField}
                    onChange={value(validate, 'title')}
                    helperText={!isUpdate && i18n`E.g. Tennis Field Maintenance`}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={10}>
              <TextField
                required
                fullWidth
                multiline
                type="text"
                name="body"
                id="body-field"
                autoComplete="off"
                label={i18n`Message Body`}
                value={data.body || ''}
                className={classes.textField}
                onChange={value(validate, 'body')}
              />
            </Grid>
            {
              // <Grid item xs={12} md={6}>
              //   <Editor preview value={data.body} />
              // </Grid>
              // <Grid item xs={12} md={6}>
              //   <Select
              //     required
              //     name="status"
              //     id="area-field"
              //     label={i18n`Status`}
              //     data={statusOptions}
              //     value={data.status || ''}
              //     onChange={onChange}
              //     className={classes.textField}
              //   />
              // </Grid>
            }
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(NotificationForm)
