import React, { PureComponent, useState, useEffect } from 'react'
import clsx from 'clsx'
import BaseTable from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from './footer'
import TableToolbar from './toolbar'
import TableHeader from './header'
import { withTheme } from 'hocs'

const Table = ({
  data: nextData,
  onSelected,
  className,
  classes,
  noFooter,
  noActions,
  noToolbar,
  noCheckbox,
  searching,
  columns,
  selected,
  placeHolder,
  noWrapperMargins,
  ...props
}) => {
  const [data, setData] = useState([])
  const [numRows, setNumRows] = useState(50)
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState('desc')
  const [order, setOrder] = useState('desc')

  useEffect(() => {
    setData(nextData)
  }, [nextData])

  // if (nextData && (nextData !== data || nextData !== data)) {
  //   setData(data)
  // }

  const onSort = key => {
    const newOrderBy = key
    let nweOrder = 'desc'

    if (orderBy === key && order === 'desc') {
      nweOrder = 'asc'
    }

    const data =
      nweOrder === 'desc'
        ? data.sort((a, b) => (b[newOrderBy] < a[newOrderBy] ? -1 : 1))
        : data.sort((a, b) => (a[newOrderBy] < b[newOrderBy] ? -1 : 1))

    setOrderBy(newOrderBy)
    setOrder(nweOrder)
    setData(data)
  }

  const onSelectAll = checked => {
    onSelected(checked ? data.map(n => n.id) : [])
  }

  const onRow = id => {
    const index = selected.indexOf(id)

    let newSelected = []

    console.log('row', id)

    if (index === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (index === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (index === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (index > 0) {
      newSelected = newSelected.concat(selected.slice(0, index), selected.slice(index + 1))
    }

    //this.setState({ selected: newSelected })
    onSelected(newSelected)
  }

  const onPageChange = page => {
    setPage(page)
  }

  const onNumRowsChange = numRows => {
    setNumRows(numRows)
  }

  const isSelected = id => {
    return selected.indexOf(id) !== -1
  }

  const emptyRows = numRows - Math.min(numRows, data.length - page * numRows)
  const colSpan = columns.length + (noActions ? 0 : 1) + (noCheckbox ? 0 : 1)

  return (
    <div className={clsx(className, classes.root)}>
      {Boolean(data && !noToolbar && (data.length || searching)) && (
        <TableToolbar
          numSelected={selected.length}
          renderTitle={props.title}
          renderActions={props.actions}
        />
      )}
      <div className={classes.tableWrapper} style={noWrapperMargins ? { margin: 0 } : {}}>
        {data && (data.length || searching) ? (
          <BaseTable className={classes.table}>
            <TableHeader
              data={columns}
              noActions={noActions}
              noCheckbox={noCheckbox}
              numSelected={selected.length}
              onSelectAll={onSelectAll}
              onSort={onSort}
              rowCount={data.length}
              render={props.header}
            />
            <TableBody>
              {data.slice(page * numRows, page * numRows + numRows).map(row =>
                props.rows(row, {
                  isSelected: isSelected(row.id),
                  onSelect: onRow
                })
              )}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }} colSpan={colSpan} />
                </TableRow>
              )}
            </TableBody>
            {!noFooter && (
              <TableFooter
                data={data}
                page={page}
                numRows={numRows}
                columns={columns}
                noCheckbox={noCheckbox}
                onPageChange={onPageChange}
                onNumRowsChange={onNumRowsChange}
              />
            )}
          </BaseTable>
        ) : (
          placeHolder()
        )}
      </div>
    </div>
  )
}

Table.defaultProps = {
  selected: [],
  onSelected: () => {},
  placeHolder: () => null
}

// class Table extends PureComponent {
//   static getDerivedStateFromProps(nextProps, prevState) {
//     let state = {}

//     if (
//       nextProps.data &&
//       (nextProps.data !== prevState.data || nextProps.data !== prevState.data)
//     ) {
//       state.data = nextProps.data
//     }

//     return Object.keys(state).length > 0 ? state : null
//   }

//   static defaultProps = {
//     onSelected: () => {},
//     placeHolder: () => null
//   }

//   state = {
//     selected: [],
//     data: [],
//     numRows: 50,
//     page: 0,
//     searching: false
//   }

//   onSort = key => {
//     const orderBy = key
//     let order = 'desc'

//     if (this.state.orderBy === key && this.state.order === 'desc') {
//       order = 'asc'
//     }

//     const data =
//       order === 'desc'
//         ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
//         : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

//     this.setState({ data, order, orderBy })
//   }

//   onSelectAll = checked => {
//     const selected = checked ? this.state.data.map(n => n.id) : []
//     this.props.onSelected(selected)
//   }

//   onRow = id => {
//     const { selected } = this.props
//     const index = selected.indexOf(id)

//     let newSelected = []

//     if (index === -1) {
//       newSelected = newSelected.concat(selected, id)
//     } else if (index === 0) {
//       newSelected = newSelected.concat(selected.slice(1))
//     } else if (index === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1))
//     } else if (index > 0) {
//       newSelected = newSelected.concat(selected.slice(0, index), selected.slice(index + 1))
//     }

//     //this.setState({ selected: newSelected })
//     this.props.onSelected(newSelected)
//   }

//   onPageChange = page => {
//     this.setState({ page })
//   }

//   onNumRowsChange = numRows => {
//     this.setState({ numRows })
//   }

//   isSelected = id => {
//     return this.props.selected.indexOf(id) !== -1
//   }

//   render() {
//     const {
//       className,
//       classes,
//       noFooter,
//       noActions,
//       noToolbar,
//       noCheckbox,
//       searching,
//       columns,
//       selected,
//       placeHolder,
//       noWrapperMargins
//     } = this.props

//     const { data, numRows, page } = this.state
//     const emptyRows = numRows - Math.min(numRows, data.length - page * numRows)
//     const colSpan = columns.length + (noActions ? 0 : 1) + (noCheckbox ? 0 : 1)

//     return (
//       <div className={clsx(className, classes.root)}>
//         {Boolean(data && !noToolbar && (data.length || searching)) && (
//           <TableToolbar
//             numSelected={selected.length}
//             renderTitle={this.props.title}
//             renderActions={this.props.actions}
//           />
//         )}
//         <div className={classes.tableWrapper} style={noWrapperMargins ? { margin: 0 } : {}}>
//           {data && (data.length || searching) ? (
//             <BaseTable className={classes.table}>
//               <TableHeader
//                 data={columns}
//                 noActions={noActions}
//                 noCheckbox={noCheckbox}
//                 numSelected={selected.length}
//                 onSelectAll={this.onSelectAll}
//                 onSort={this.onSort}
//                 rowCount={data.length}
//                 render={this.props.header}
//               />
//               <TableBody>
//                 {data.slice(page * numRows, page * numRows + numRows).map(row =>
//                   this.props.rows(row, {
//                     isSelected: this.isSelected(row.id),
//                     onSelect: this.onRow
//                   })
//                 )}
//                 {emptyRows > 0 && (
//                   <TableRow>
//                     <TableCell style={{ borderBottom: 'none' }} colSpan={colSpan} />
//                   </TableRow>
//                 )}
//               </TableBody>
//               {!noFooter && (
//                 <TableFooter
//                   data={data}
//                   page={page}
//                   numRows={numRows}
//                   columns={columns}
//                   noCheckbox={noCheckbox}
//                   onPageChange={this.onPageChange}
//                   onNumRowsChange={this.onNumRowsChange}
//                 />
//               )}
//             </BaseTable>
//           ) : (
//             placeHolder()
//           )}
//         </div>
//       </div>
//     )
//   }
// }

export default withTheme('table')(Table)
