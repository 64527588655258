import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { withTheme } from 'hocs'

const PlaceHolder = React.memo(({ media, actions, classes, children }) => {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <div className={classes.media}>{media && media()}</div>
        {children}
      </CardContent>
      <CardActions className={classes.actions}>{actions && actions()}</CardActions>
    </Card>
  )
})

export default withTheme('placeHolder')(PlaceHolder)
