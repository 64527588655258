import { grey } from '@material-ui/core/colors'

const displayConfig = {
  color: grey[900],
  fontWeight: 700
}

export default {
  display1: displayConfig,
  display2: displayConfig,
  display3: displayConfig,
  display4: displayConfig
}