import React, { useEffect, useReducer, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import toast from 'lib/toast'
import fileDownload from 'react-file-download'
import BusinessIcon from '@material-ui/icons/Business'
import { PlaceHolder, Button, Table, DeleteDialog, AssignDialog, UploadDialog } from 'components'
import { SEARCH_KEYS } from 'data/member/constants'
import { ReactComponent as HeroImage } from 'svgs/buy_house.svg'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const MemberList = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    uploadDialog: false,
    assignDialog: false,
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { list } = stores.members
  const { domain } = match.params
  const { keyword, guide, assignDialog, uploadDialog, deleteDialog } = state
  const data = keyword ? search(normalize(list), keyword) : list

  useEffect(() => {
    if (domain) fetch(domain)
  }, [domain])

  const columns = [
    { id: 'name', label: i18n`Name` },
    { id: 'email', label: i18n`Email` },
    { id: 'units', label: i18n`Units` }
  ]

  const selected = state.selected.map(id => ({ id }))

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    const promises = [stores.members.fetch(domain), stores.units.fetch(domain)]

    try {
      await Promise.all(promises)
    } catch (error) {
      toast.error(i18n`Unable to complete fetching members information`)
    }
  }

  const update = async data => {
    try {
      await stores.members.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update member`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.members.destroy(domain, data)
      toast.success(i18n`Deleted`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete member(s)`)
    }
    reset()
  }

  const assign = async (members, units) => {
    try {
      const data = members.reduce((acc, member) => {
        const ids = units.map(unit => ({ unitId: unit.id, userId: member.id, role: unit.role }))
        acc.push(...ids)
        return acc
      }, [])
      await stores.members.assign(domain, data)
      toast.success(i18n`Assigned`)
      stores.units.fetch(domain, true)
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign member(s)`)
    }
    reset()
  }

  const unassign = async data => {
    try {
      await stores.members.unassign(domain, data)
      toast.success(i18n`Unassigned`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign member`)
    }
  }

  const upload = async data => {
    try {
      await stores.members.upload(domain, data)
      toast.success(i18n`Data imported`)
      stores.units.fetch(domain, true)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to import data. Check your data and try again`)
    }
    reset()
  }

  const download = async data => {
    try {
      const file = await stores.members.download(domain, data)
      fileDownload(file, 'members.csv')
    } catch (error) {
      toast.error(i18n`Unable to download members`)
    }
    reset()
  }

  const normalize = data => {
    return data.map(member => {
      const unitsByTitle = member.units.map(unit => unit.title)
      return { ...member, unitsByTitle }
    })
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onUploadAction = ({ ok, data }) => {
    if (ok) upload(data)
    closeUploadDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...rest }) => {
    if (ok && rest.assign.length) assign(selected, rest.assign)
    if (ok && rest.unassign.length) unassign(selected, rest.unassign)
    if (!ok) reset()
    closeAssignDialog()
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onUpdate = data => {
    update(data)
  }

  const onUnassign = (data = []) => {
    const [userId, unitId] = data
    unassign({ userId, unitId })
  }

  const onDownload = () => {
    download(selected)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const openUploadDialog = () => {
    setState({ uploadDialog: true })
  }

  const closeUploadDialog = () => {
    setState({ uploadDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const role = 'resident'

  const units = stores.units.list.map(unit => {
    return { ...unit, role, value: `${unit.id}:${role}` }
  })

  console.log('MEMBERS DATA ====>', data)

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <HeroImage width="350" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE MEMBER`}</Button>
            <Button onClick={openUploadDialog}>{i18n`IMPORT MEMBERS`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5">
          {i18n`You haven't added any members to your community yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noInvite
        noItemDelete
        data={data}
        selected={state.selected}
        columns={columns}
        className="members"
        title={i18n`Members`}
        onRow={page}
        onDetail={page}
        onCreate={() => page('create')}
        onSearch={onSearch}
        onGuide={openGuide}
        onSwitch={onUpdate}
        onSelected={onSelected}
        onDownload={onDownload}
        onChipDelete={onUnassign}
        onUpload={openUploadDialog}
        onDelete={openDeleteDialog}
        onAssign={openAssignDialog}
        placeHolder={renderPlaceHolder}
        assignIcon={<BusinessIcon />}
      />
      <UploadDialog title={i18n`Import members`} open={uploadDialog} onAction={onUploadAction} />
      <AssignDialog
        title={i18n`Assign units`}
        open={assignDialog}
        data={units}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete members`} onAction={onDeleteAction}>
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected members?`
            : i18n`Are you sure you want to delete this member?`}
        </p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default view(MemberList)
