export default theme => ({
  title: {
    //backgroundColor: '#fafafa'
    borderBottom: '1px solid #fafafa'
  },
  content: {
    padding: 0
  },
  paper: {
    //width: '80%',
    maxHeight: '60%',
  }
})