import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import capitalize from 'lodash.capitalize'
import formatTs from 'date-fns/format'
import { Table, Section } from 'components'
import * as cons from 'data/pass/constants'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'

const getData = ({ type, ...log }, pass, { classes }) => {
  const title = capitalize(type.replace(/_/g, ' '))
  const time = formatTs(log.ts, 'd/m/yyyy h:mm a')

  log.user = log.user || {}

  switch (type) {
    case cons.PASS_CREATED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <CheckIcon />
          </Avatar>
        ),
        text: (
          <span>
            Pass created for <strong>{pass.name}</strong> by <strong>{log.user.name}</strong> with{' '}
            <strong>PIN {log.pin}</strong>.
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
    case cons.PASS_REVOKED:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <CloseIcon />
          </Avatar>
        ),
        text: (
          <span>
            Pass for <strong>{pass.name}</strong> revoked by <strong>{log.user.name}</strong>.
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
    case cons.PASS_CHECKED_IN:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <ArrowForwardIcon />
          </Avatar>
        ),
        text: (
          <span>
            Officer <strong>{log.user.name}</strong> authorized access to{' '}
            <strong>{pass.name}</strong> with <strong>PIN {log.pin}</strong>.
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
    case cons.PASS_CHECKED_OUT:
      return {
        time,
        avatar: (
          <Avatar className={classes[type]}>
            <ArrowBackIcon />
          </Avatar>
        ),
        text: (
          <span>
            Officer <strong>{log.user.name}</strong> authorized <strong>{pass.name}</strong> exit
            with <strong>PIN {log.pin}</strong>.
          </span>
        ),
        loggedBy: log.user.name,
        event: title
      }
  }
}

class PassLogs extends Component {
  state = {
    keyword: ''
  }

  columns = [
    { id: 'avatar', label: i18n`Indicator` },
    { id: 'event', label: i18n`Event` },
    { id: 'text', label: i18n`Description` },
    { id: 'time', label: i18n`TIme`, last: true }
  ]

  get id() {
    return this.props.match.params.id
  }

  get pass() {
    return stores.passes.get(this.id) || { logs: [] }
  }

  get domain() {
    return this.props.match.params.domain
  }

  get data() {
    return (this.pass.logs || []).map(log => getData(log, this.pass, this.props))
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.domain !== prevProps.match.params.domain || this.id !== prevProps.match.params.id) {
      this.fetch()
    }
  }

  fetch = async () => {
    try {
      await stores.passes.fetch(this.domain, { id: this.id })
      await stores.passes.fetchLogs(this.domain, { id: this.id })
    } catch (err) {
      toast.error(i18n`Unable to fetch logs`)
    }
  }

  back = () => {
    this.props.history.push(`/passes/${this.domain}/${this.id}`)
  }

  render() {
    return (
      <Section
        {...this.props}
        noAssign
        noActions
        noDelete
        title={i18n`Pass Logs`}
        backButtonLabel={i18n`Pass`}
        onBack={this.back}
      >
        <Table
          noFooter
          noToolbar
          noActions
          noDownload
          noCheckbox
          noItemSwitch
          noItemDelete
          noWrapperMargins
          data={this.data}
          columns={this.columns}
          style={{ margin: 0 }}
        />
      </Section>
    )
  }
}

export default compose(
  withTheme('passes'),
  view
)(PassLogs)
