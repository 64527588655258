import { toast } from 'react-toastify'

export default {
  success: (msg, opts = {}) => {
    toast.success(msg, opts)
  },
  error: (msg, opts = {}) => {
    toast.error(msg, opts)
  },
  warn: (msg, opts = {}) => {
    toast.warn(msg, { ...opts, className: 'warn-toast' })
  }
}
