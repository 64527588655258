import React from 'react'
import formatDate from 'date-fns/format'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { mapWeekDays, mapType, mapStatus } from 'data/pass/maps'
import { toTime, timeAgo } from 'lib/utils'
import createStyled from 'lib/styled'
import i18n from 'lib/i18n'
import { withTheme } from 'hocs'

const Styled = createStyled('passes')

const TemporalForm = ({ data, classes }) => {
  const formatStartDate = formatDate(data.startDate, 'd/MM/yy')
  const formatEndDate = formatDate(data.endDate, 'd/MM/yy')

  const dateRange =
    formatStartDate !== formatEndDate ? formatStartDate + ' - ' + formatEndDate : formatStartDate

  const timeRange = toTime(data.startTime) + ' - ' + toTime(data.endTime)
  const weekDays = (data.weekDays || []).map(day => mapWeekDays(String(day)))

  return (
    <Styled>
      {({ classes: passClasses }) => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid className={classes.gridTitle} item xs={12}>
              <Typography variant="h6">{i18n`Guest`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name-field"
                label={i18n`Name`}
                value={data.guest.name}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            {Boolean(data.guest.email) && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="email"
                  id="email-field"
                  label={i18n`Email`}
                  value={data.guest.email}
                  className={classes.fields}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            {Boolean(data.guest.phone) && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="phone-field"
                  label={i18n`Phone`}
                  value={data.guest.phone}
                  className={classes.fields}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            {Boolean(data.guest.docId) && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="docid-field"
                  label={i18n`Identification`}
                  value={data.guest.docId}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            <Grid className={classes.gridTitle} item xs={12}>
              <Typography variant="h6">{i18n`Pass`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="pin-field"
                label={i18n`PIN`}
                value={data.pin}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="type-field"
                label={i18n`Type`}
                value={mapType(data.type)}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            {data.type !== 'permanent' && data.startDate && data.endDate && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="date-field"
                  label={i18n`Date(s)`}
                  value={dateRange || ''}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            {data.type !== 'permanent' && data.startTime && data.endTime && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="time-field"
                  label={i18n`Time`}
                  value={timeRange || ''}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            {data.type === 'recurrent' && (
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  id="weekdays-field"
                  label={i18n`Week Days`}
                  value={weekDays || ''}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
            <Grid className={classes.gridTitle} item xs={12}>
              <Typography variant="h6">{i18n`Sender`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="sender-name-field"
                label={i18n`Name`}
                value={data.owner.name}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="place-field"
                label={i18n`Place`}
                value={data.place.title}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid className={classes.gridTitle} item xs={12}>
              <Typography variant="h6">{i18n`Status`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="status-field"
                label={i18n`Status`}
                value={mapStatus(data.status)}
                inputProps={{ readOnly: true, className: passClasses[`STATUS_${data.status}`] }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="activity-field"
                label={i18n`Activity`}
                value={timeAgo(data.updated)}
                inputProps={{ readOnly: true }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>

            {Boolean(data.note) && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="2"
                  id="note-field"
                  label={i18n`Note`}
                  value={data.note}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Styled>
  )
}

export default withTheme('form')(TemporalForm)
