export const INCIDENT_CREATED = 'incident::created'
export const INCIDENT_UPDATED = 'incident::updated'
export const INCIDENT_DELETED = 'incident::deleted'
export const INCIDENT_COMMENTED = 'incident::commented'
export const INCIDENT_STATUS_CHANGED = 'incident::status:changed'

export const STATUS_OPEN = 'OPEN'
export const STATUS_ON_HOLD = 'ON_HOLD'
export const STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const STATUS_RESOLVED = 'RESOLVED'
export const STATUS_DUPLICATE = 'DUPLICATE'
export const STATUS_CLOSED = 'CLOSED'
export const STATUS_INVALID = 'INVALID'

export const PRIVATE = 'PRIVATE'
export const PUBLIC = 'PUBLIC'

export const SEARCH_KEYS = [ 'type', 'description', 'visibility', 'status' ]

export const VISIBILITIES = [ PRIVATE, PUBLIC ]

export const STATUSES = [
  STATUS_OPEN,
  STATUS_ON_HOLD,
  STATUS_IN_PROGRESS,
  STATUS_RESOLVED,
  STATUS_DUPLICATE,
  STATUS_CLOSED,
  STATUS_INVALID
]

export const FIRE = 'FIRE'
export const THEFT = 'THEFT'
export const PLAGUE = 'PLAGUE'
export const HAZARD = 'HAZARD'
export const ACCIDENT = 'ACCIDENT'
export const INCIDENTE_WITH_SECURITY_PERSONNEL = 'INCIDENTE_WITH_SECURITY_PERSONNEL'
export const ANIMAL_INCIDENT = 'ANIMAL_INCIDENT'
export const ANIMAL_WASTE = 'ANIMAL_WASTE'
export const CLEANSINESS = 'CLEANSINESS'
export const MAINTENANCE_PROBLEMS = 'MAINTENANCE_PROBLEMS'
export const INFRASTRUCTURE_PROBLEMS = 'INFRASTRUCTURE_PROBLEMS'
export const GENERAL_IMPROVEMENT = 'GENERAL_IMPROVEMENT'
export const PROPERTY_DAMAGE = 'PROPERTY_DAMAGE'
export const HEALTH_EMERGENCY = 'HEALTH_EMERGENCY'
export const SUSPICIOUS_ACTIVITY = 'SUSPICIOUS_ACTIVITY'
export const GENERAL_SECURITY = 'GENERAL_SECURITY'
export const GENERAL_EMERGENCY = 'GENERAL_EMERGENCY'
export const RULES_VIOLATION = 'RULES_VIOLATION'
export const LOST_PETS = 'LOST_PETS'
export const LOST_PERSON = 'LOST_PERSON'
export const LOST_OBJECT = 'LOST_OBJECT'
export const VIOLENCE = 'VIOLENCE'
export const DEBRIS = 'DEBRIS'
export const BAD_PARKING = 'BAD_PARKING'
export const STREET_LIGHTING_FAILURE = 'STREET_LIGHTING_FAILURE'
export const FALLEN_TREE_LIMB = 'FALLEN_TREE_LIMB'
export const NOISE_POLLUTION = 'NOISE_POLLUTION'
export const POLLUTION = 'POLLUTION'
export const UNSAFE_CONSTRUCTION_WORK = 'UNSAFE_CONSTRUCTION_WORK'
export const OTHER = 'OTHER'

export const TYPES = [
  FIRE,
  THEFT,
  PLAGUE,
  HAZARD,
  ACCIDENT,
  INCIDENTE_WITH_SECURITY_PERSONNEL,
  ANIMAL_INCIDENT,
  ANIMAL_WASTE,
  CLEANSINESS,
  MAINTENANCE_PROBLEMS,
  INFRASTRUCTURE_PROBLEMS,
  GENERAL_IMPROVEMENT,
  PROPERTY_DAMAGE,
  HEALTH_EMERGENCY,
  SUSPICIOUS_ACTIVITY,
  GENERAL_SECURITY,
  GENERAL_EMERGENCY,
  RULES_VIOLATION,
  LOST_PETS,
  LOST_PERSON,
  LOST_OBJECT,
  VIOLENCE,
  DEBRIS,
  BAD_PARKING,
  STREET_LIGHTING_FAILURE,
  FALLEN_TREE_LIMB,
  NOISE_POLLUTION,
  POLLUTION,
  UNSAFE_CONSTRUCTION_WORK,
  OTHER
]
