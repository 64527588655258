import React, { Fragment } from 'react'
import { Button } from 'components'
import { withTheme } from 'hocs'

const FileInput = React.memo(({ id, accept, classes, multiple, ...props }) => {
  const onChange = e => {
    const { files } = e.target
    if (!multiple) props.onChange(files[0] || null, e)
    else props.ArrayonChange(Array.prototype.slice.call(files), e)
  }

  return (
    <Fragment>
      <input
        multiple
        id={id}
        type="file"
        accept={accept}
        className={classes.input}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <Button component="span" className={classes.button} {...props} />
      </label>
    </Fragment>
  )
})

FileInput.defaultProps = {
  onChange: () => {}
}

export default withTheme('fileInput')(FileInput)
