export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  STATUS_REVOKED: {
    color: '#E91E63'
  },
  STATUS_CREATED: {
    color: '#2196F3'
  },
  STATUS_CHECKED_IN: {
    color: '#4CAF50'
  },
  STATUS_CHECKED_OUT: {
    color: '#4CAF50'
  },
  PASS_REVOKED: {
    backgroundColor: '#E91E63',
    color: '#FFF',
    margin: theme.spacing()
  },
  PASS_CREATED: {
    backgroundColor: '#2196F3',
    color: '#FFF',
    margin: theme.spacing()
  },
  PASS_CHECKED_IN: {
    backgroundColor: '#4CAF50',
    color: '#FFF',
    margin: theme.spacing()
  },
  PASS_CHECKED_OUT: {
    backgroundColor: '#4CAF50',
    color: '#FFF',
    margin: theme.spacing()
  }
})
