import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { mapStatus, mapStatusColor } from 'data/incident/maps'
import { Section } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

class IncidentDetail extends Component {
  state = {
    data: {},
    deleteDialog: false
  }

  get id() {
    return this.props.match.params.id
  }

  get user() {
    return stores.accounts.user || {}
  }

  get domain() {
    return this.props.match.params.domain
  }

  get incident() {
    return stores.incidents.get(this.id)
  }

  get isSameData() {
    const { data } = this.state
    const { type, visibility, status } = this.incident
    return type === data.type && visibility === data.visibility && status === data.status
  }

  componentDidMount() {
    this.fetch()
    this.ack()
  }

  componentDidUpdate(prevProps) {
    if (this.domain !== prevProps.match.params.domain) {
      this.fetch()
    }
  }

  setData = data => {
    this.setState({
      data: {
        ...this.state.data,
        ...data
      }
    })
  }

  fetch = async () => {
    try {
      await stores.incidents.fetch(this.domain, { id: this.id })
      await stores.incidents.fetchLogs(this.domain, { id: this.id })
    } catch (err) {
      toast.error(i18n`Unable to fetch incident information`)
      this.back()
    }
  }

  ack = async () => {
    try {
      await stores.incidents.ack(this.domain, { id: this.id }, this.user.id)
    } catch (err) {
      console.log(err)
    }
  }

  normalize = data => {
    const update = { id: this.id }
    const { type, visibility, status } = this.incident
    if (type !== data.type) update.type = data.type
    if (visibility !== data.visibility) update.visibility = data.visibility
    if (status !== data.status) update.status = data.status
    return update
  }

  update = async data => {
    try {
      data = this.normalize(data)
      await stores.incidents.update(this.domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update incident`)
    }
  }

  comment = async data => {
    try {
      await stores.incidents.comment(this.domain, { ...data, id: this.id })
      toast.success(i18n`Comment added`)
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
      } else {
        toast.error(i18n`Unable to add comment`)
      }
    }
  }

  back = () => {
    this.props.history.push(`/incidents/${this.domain}`)
    return null
  }

  logs = () => {
    this.props.history.push(`/incidents/${this.domain}/${this.id}/logs`)
  }

  onFormChange = data => {
    this.setData(data)
  }

  onUpdate = () => {
    this.update(this.state.data)
  }

  onCreateComment = data => {
    this.comment(data)
  }

  renderTitle = () => {
    const { countId, status } = this.incident
    return (
      <Fragment>
        <Typography variant="h4">
          {i18n`Incident`} #{countId || 0}
        </Typography>
        <Chip
          label={mapStatus(status).toUpperCase()}
          style={{
            marginLeft: 16,
            backgroundColor: mapStatusColor(status),
            color: '#FFF',
            fontWeight: '600'
          }}
        />
      </Fragment>
    )
  }

  renderToolbarActions = () => {
    return (
      <Fragment>
        <Tooltip title={i18n`Event logs`}>
          <IconButton aria-label={i18n`Event logs`} onClick={this.logs}>
            <AccessTimeIcon />
          </IconButton>
        </Tooltip>
      </Fragment>
    )
  }

  render() {
    if (!this.incident) return this.back()

    const data = {
      ...this.incident,
      ...this.state.data,
      comments: this.incident.comments,
      currentStatus: this.incident.status
    }

    console.log(
      'RENDERING DETAILS',
      JSON.stringify(data, null, 2),
      JSON.stringify(this.incident.comments, null, 2)
    )

    return (
      <Fragment>
        <Section
          {...this.props}
          noAssign
          noDelete
          noCancelButton
          data={data}
          onBack={this.back}
          onSubmit={this.onUpdate}
          title={this.renderTitle()}
          backButtonLabel={i18n`Incidents`}
          toolbarActions={this.renderToolbarActions}
          submitButtonLabel={i18n`Save`}
          disableSubmitButton={this.isSameData}
        >
          {
            <Form
              isUpdate
              user={this.user}
              data={data}
              onChange={this.onFormChange}
              onCreateComment={this.onCreateComment}
            />
          }
        </Section>
      </Fragment>
    )
  }
}

export default compose(
  withTheme('incidents'),
  view
)(IncidentDetail)
