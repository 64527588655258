import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.notifications',
        content: (
          <div>
            <h2>{i18n`Notification List`}</h2>
            <span>{i18n`This is the notification list section where all recent notifications will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.notifications .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search and create notifications.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for notifications.`}</span>
          </div>
        )
      },
      {
        selector: '.create-action',
        content: (
          <div>
            <h2>{i18n`Create Notification`}</h2>
            <span>{i18n`Create a new notification.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Notification Record`}</h2>
            <span>{i18n`This is a notification. You can click on any individual notification row to get additional details.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Notification Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
