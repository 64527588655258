import React, { useState, useEffect, Fragment } from 'react'
import nanoid from 'nanoid'
import { isNil } from 'lodash'
import getTime from 'date-fns/getTime'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import Grid from '@material-ui/core/Grid'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { KeyboardDatePicker } from '@material-ui/pickers'
import useForm from 'react-hook-form'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Form } from 'components'
import { STARTED, ENDED } from 'data/poll/constants'
import { mapStatus } from 'data/poll/maps'
import createStyled from 'lib/styled'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'

const PickerRightIcon = <KeyboardArrowRightIcon />
const PickerLeftIcon = <KeyboardArrowLeftIcon />
const Styled = createStyled('polls')

const getId = () => nanoid(4)

const DragHandle = SortableHandle(() => (
  <DragIndicatorIcon style={{ margin: '24px 10px 24px 0', color: '#999' }} />
))

const SortableItem = SortableElement(
  ({ data, disabled, answer, form, status, idx: index, count, onAdd, onRemove }) => {
    const { register, errors } = form
    const isEnded = status === ENDED
    const isStarted = status === STARTED
    const name = `${index}:${answer}:answer`

    return (
      <Grid item xs={12} md={10}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DragHandle />

          <TextField
            required
            fullWidth
            multiline
            type="text"
            name={name}
            defaultValue={(data && data.text) || ''}
            inputRef={register({ required: i18n`Field required` })}
            inputProps={{ readOnly: isEnded || isStarted }}
            error={Boolean(errors[name])}
            helperText={errors[name] ? errors[name].message : ''}
            id={`text-field-${index}`}
            autoComplete="off"
            label={i18n`Option ${index + 1}`}
          />

          {!isStarted && !isEnded && (
            <IconButton
              color="primary"
              disabled={count <= 2 || disabled}
              aria-label="Delete option"
              style={{
                height: 36,
                width: 36,
                padding: 0,
                marginTop: 18,
                marginRight: index === count - 1 && count < 7 ? 0 : 40
              }}
              onClick={() => onRemove(answer)}
            >
              <CloseIcon />
            </IconButton>
          )}

          {!isStarted && !isEnded && index === count - 1 && count < 7 ? (
            <IconButton
              color="primary"
              disabled={disabled}
              aria-label="Add option"
              style={{ height: 36, width: 36, marginTop: 18, padding: 0 }}
              onClick={onAdd}
            >
              <AddIcon />
            </IconButton>
          ) : null}
        </div>
      </Grid>
    )
  }
)

SortableItem.defaultProps = {
  value: {},
  disabled: false
}

const SortableList = SortableContainer(({ data, answers, ...props }) => {
  return (
    <Grid container spacing={2}>
      {answers.map((id, index) => (
        <SortableItem
          key={id}
          idx={index}
          index={index}
          answer={id}
          data={data.answers.find(a => a.id === id)}
          count={answers.length}
          {...props}
        />
      ))}
    </Grid>
  )
})

const PollForm = ({ data, classes, onSubmit, buttonRef }) => {
  const ids = data.answers.map(a => a.id)
  const [answers, setAnswers] = useState(ids)
  const form = useForm({ mode: 'onBlur' })
  const { setValue, register, handleSubmit, errors, watch } = form
  const defaultDates = { startDate: new Date(), endDate: endOfDay(new Date()) }
  const dates = watch(['startDate', 'endDate'], { ...defaultDates })
  const scheduled = watch('scheduled', Boolean(data.startDate || data.endDate))
  const multiAnswer = watch('multiAnswer', Boolean(data.multiAnswer))
  const changeAnswer = watch('changeAnswer', Boolean(data.changeAnswer))
  const hideResponses = watch('hideResponses', Boolean(data.hideResponses))

  useEffect(() => {
    register({ name: 'startDate' })
    register({ name: 'endDate' })
  }, [])

  useEffect(() => {
    setValue('startDate', scheduled ? data.startDate || defaultDates.startDate : undefined)
    setValue('endDate', scheduled ? data.endDate || defaultDates.endDate : undefined)
  }, [scheduled])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAnswers(arrayMove(answers || [], oldIndex, newIndex))
  }

  const onAdd = () => {
    const id = getId()
    setAnswers([...answers, id])
  }

  const onRemove = id => {
    if (answers.length <= 2) return
    setAnswers(answers.filter(a => a !== id))
  }

  const onFormSubmit = event => {
    const data = Object.keys(event).reduce(
      (obj, key) => {
        if (key.indexOf(':answer') !== -1) {
          const [index] = key.split(':')
          obj.answers.push({ index: parseInt(index, 10), text: event[key] })
        } else if (!isNil(event[key])) {
          obj[key] = event[key]
        }

        return obj
      },
      { answers: [] }
    )

    data.answers.sort((a, b) => a.index - b.index)
    onSubmit(data)
  }

  const onDateChange = (obj = {}) => {
    if (obj.startDate) {
      const startDate = getTime(obj.startDate)
      const endDate = getTime(dates.endDate)

      if (startDate > endDate) {
        obj.endDate = endOfDay(startDate)
      }
    }

    if (obj.endDate) {
      const endDate = getTime(endOfDay(obj.endDate))
      const startDate = getTime(obj.startDate || dates.startDate || new Date())

      if (startDate > endDate) {
        obj.endDate = endOfDay(startDate)
      }

      obj.startDate = startOfDay(startDate)
    }

    if (!obj.endDate) {
      obj.endDate = dates.endDate
    }

    if (obj.startDate) setValue('startDate', obj.startDate)
    if (obj.endDate) setValue('endDate', obj.endDate)
  }

  const isEnded = data.status === ENDED
  const isStarted = data.status === STARTED

  return (
    <Styled>
      {({ classes: pollClasses }) => (
        <Form
          noValidate
          className={classes.form}
          buttonRef={buttonRef}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Grid container spacing={2}>
            {Boolean(data.status) && (
              <Grid item xs={12} md={10}>
                <TextField
                  fullWidth
                  value={mapStatus(data.status)}
                  label={i18n`Status`}
                  className={classes.textField}
                  inputProps={{
                    readOnly: true,
                    className: pollClasses[`STATUS_${data.status}`]
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={10}>
              <TextField
                required
                fullWidth
                type="text"
                name="title"
                autoComplete="off"
                label={i18n`Title`}
                defaultValue={data.title || ''}
                error={Boolean(errors.title)}
                className={classes.textField}
                inputProps={{ readOnly: isEnded || isStarted }}
                inputRef={register({ required: i18n`Field required` })}
                helperText={errors.title ? errors.title.message : ''}
              />
            </Grid>
            <Grid item xs={12} md={10} style={{ marginBottom: 20 }}>
              <TextField
                required
                fullWidth
                multiline
                type="text"
                name="text"
                id="text-field"
                autoComplete="off"
                label={i18n`Question`}
                defaultValue={data.text || ''}
                error={Boolean(errors.text)}
                className={classes.textField}
                inputProps={{ readOnly: isEnded || isStarted }}
                inputRef={register({ required: i18n`Field required` })}
                helperText={errors.text ? errors.text.message : ''}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <SortableList
              useDragHandle
              lockAxis="y"
              form={form}
              data={data}
              classes={classes}
              answers={answers}
              onAdd={onAdd}
              onRemove={onRemove}
              onSortEnd={onSortEnd}
              status={data.status}
              disabled={isEnded || isStarted}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="changeAnswer"
                    inputRef={register}
                    defaultChecked={changeAnswer}
                    disabled={isEnded || isStarted}
                  />
                }
                label={i18n`User can change answers`}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="multiAnswer"
                    inputRef={register}
                    defaultChecked={multiAnswer}
                    disabled={isEnded || isStarted}
                  />
                }
                label={i18n`User can select multiple answers`}
              />
            </Grid>
            {
              //   <Grid item xs={12} md={4}>
              //   <FormControlLabel
              //     control={
              //       <Checkbox
              //         name="hideResponses"
              //         inputRef={register}
              //         defaultChecked={hideResponses}
              //         disabled={isEnded || isStarted}
              //       />
              //     }
              //     label={i18n`Hidden responses`}
              //   />
              // </Grid>
            }

            <Grid item xs={12} md={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="scheduled"
                    inputRef={register}
                    defaultChecked={scheduled}
                    disabled={isEnded || isStarted}
                  />
                }
                label={i18n`Scheduled`}
              />
            </Grid>
            {scheduled && (
              <Fragment>
                <Grid item xs={6} md={4}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disablePast
                    name="startDate"
                    format="d/MM/yyyy"
                    label={i18n`Start Date`}
                    value={dates.startDate}
                    className={classes.textField}
                    rightArrowIcon={PickerRightIcon}
                    leftArrowIcon={PickerLeftIcon}
                    onChange={startDate => onDateChange({ startDate })}
                    disabled={isEnded || isStarted}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disablePast
                    name="endDate"
                    format="d/MM/yyyy"
                    label={i18n`End Date`}
                    minDate={dates.startDate || new Date()}
                    value={dates.endDate}
                    className={classes.textField}
                    rightArrowIcon={PickerRightIcon}
                    leftArrowIcon={PickerLeftIcon}
                    onChange={endDate => onDateChange({ endDate })}
                    disabled={isEnded || isStarted}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Form>
      )}
    </Styled>
  )
}

export default withTheme('form')(PollForm)
