import React from 'react'
import toDate from 'date-fns/toDate'
import getTime from 'date-fns/getTime'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import addDays from 'date-fns/addDays'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { Validator, MultiSegment } from 'components'
import { withTheme } from 'hocs'
import { isRequired, isEmailOrMobile } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const PickerRightIcon = <KeyboardArrowRightIcon />
const PickerLeftIcon = <KeyboardArrowLeftIcon />

const TemporalForm = ({ data, classes, onChange }) => {
  const rules = [runner('contact', i18n`Email or mobile`, isEmailOrMobile)]

  if (data.type === 'service') {
    rules.push(
      runner('company', i18n`Service name`, isRequired),
      runner('contact', i18n`Email or mobile`, isEmailOrMobile)
    )
  } else {
    rules.push(
      runner('firstName', i18n`First name`, isRequired),
      runner('lastName', i18n`Last name`, isRequired)
    )
  }

  const today = new Date()
  const minDate = startOfDay(today)
  const maxDate = endOfDay(addDays(today, 30))

  const onDateChange = obj => {
    const startDate = getTime(obj.startDate)
    const endDate = getTime(data.endDate)

    if (obj.startDate && startDate > endDate) {
      obj.endDate = toDate(startDate)
    }

    if (obj.endDate) {
      obj.endDate = endOfDay(obj.endDate)
    }

    onChange(obj)
  }

  const onTimeChange = obj => {
    const startTime = getTime(obj.startTime)
    const endTime = getTime(data.endTime)

    if (obj.startTime && startTime > endTime) {
      obj.endTime = toDate(startTime)
    }

    onChange(obj)
  }

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" required>
                <RadioGroup
                  name="type"
                  aria-label="type"
                  value={data.type}
                  onChange={value(onChange, 'type')}
                  className={classes.horizontal}
                >
                  <FormControlLabel
                    className={classes.radio}
                    value="person"
                    control={<Radio />}
                    label={i18n`For person`}
                  />
                  <FormControlLabel
                    className={classes.radio}
                    value="service"
                    control={<Radio />}
                    label={i18n`For service`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.type === 'service' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="company-field"
                  label={i18n`Service name`}
                  className={classes.textField}
                  onChange={value(validate, 'company')}
                  autoComplete="off"
                />
              </Grid>
            )}
            {data.type === 'person' && (
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="first-name-field"
                  label={i18n`First name`}
                  className={classes.textField}
                  onChange={value(validate, 'firstName')}
                  autoComplete="off"
                />
              </Grid>
            )}
            {data.type === 'person' && (
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="last-name-field"
                  label={i18n`Last name`}
                  className={classes.textField}
                  onChange={value(validate, 'lastName')}
                  autoComplete="off"
                />
              </Grid>
            )}
            <Grid item xs={6} sm={data.type === 'person' ? 3 : 6}>
              <TextField
                fullWidth
                id="contact-field"
                label={i18n`Email or mobile`}
                className={classes.textField}
                onChange={value(validate, 'contact')}
                autoComplete="off"
              />
            </Grid>
            {data.type === 'person' && (
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  type="text"
                  id="docid-field"
                  label={i18n`Identification`}
                  className={classes.textField}
                  onChange={value(validate, 'docId')}
                  autoComplete="off"
                />
              </Grid>
            )}
            <Grid item xs={6} sm={3}>
              <DatePicker
                fullWidth
                format="d/MM/yyyy"
                label={i18n`Start date`}
                minDate={minDate}
                maxDate={maxDate}
                value={data.startDate}
                className={classes.textField}
                rightArrowIcon={PickerRightIcon}
                leftArrowIcon={PickerLeftIcon}
                onChange={startDate => onDateChange({ startDate })}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TimePicker
                fullWidth
                label={i18n`Start time`}
                value={data.startTime}
                className={classes.textField}
                onChange={startTime => onTimeChange({ startTime })}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DatePicker
                fullWidth
                format="d/MM/yyyy"
                label={i18n`End date`}
                minDate={data.startDate}
                maxDate={maxDate}
                value={data.endDate}
                className={classes.textField}
                rightArrowIcon={PickerRightIcon}
                leftArrowIcon={PickerLeftIcon}
                onChange={endDate => onDateChange({ endDate })}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TimePicker
                fullWidth
                label={i18n`End time`}
                value={data.endTime}
                className={classes.textField}
                onChange={endTime => onTimeChange({ endTime })}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSegment
                value={[]}
                name="weekdays"
                label={i18n`Week days`}
                onChange={weekDays => validate({ weekDays })}
              >
                <span value="7">{i18n`Sun`}</span>
                <span value="1">{i18n`Mon`}</span>
                <span value="2">{i18n`Tue`}</span>
                <span value="3">{i18n`Wed`}</span>
                <span value="4">{i18n`Thu`}</span>
                <span value="5">{i18n`Fri`}</span>
                <span value="6">{i18n`Sat`}</span>
              </MultiSegment>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rowsMax="2"
                id="note-field"
                label={i18n`Note`}
                className={classes.textField}
                onChange={value(validate, 'note')}
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(TemporalForm)
