import React from 'react'
import { Switch } from "react-router-dom"
import { Layout, PrivateRoute } from 'components'
import ReservationList from './list'
import ReservationCreate from './create'
import ReservationDetail from './detail'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/reservations/:domain" component={ReservationList} />
      <PrivateRoute exact path="/reservations/:domain/create" component={ReservationCreate} />
      <PrivateRoute exact path="/reservations/:domain/:id" component={ReservationDetail} />
    </Switch>
  </Layout>
)