import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withTheme } from 'hocs'

class FileDrop extends Component {
  static propTypes = {
    onDrop: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    dropEffect: PropTypes.oneOf(['copy', 'move', 'link', 'none']),
    targetAlwaysVisible: PropTypes.bool,
    frame: (props, propName, componentName) => {
      const prop = props[propName]
      if (prop == null) {
        return new Error(`Warning: Required prop ${propName} was not specified in ${componentName}`)
      }
      if (prop !== document && prop !== window && !(prop instanceof HTMLElement)) {
        return new Error(
          `Warning: Prop ${propName} must be one of the following: document, window, or an HTMLElement!`
        )
      }
    },
    onFrameDragEnter: PropTypes.func,
    onFrameDragLeave: PropTypes.func,
    onFrameDrop: PropTypes.func
  }

  static defaultProps = {
    onFrameDragEnter: () => {},
    onFrameDragLeave: () => {},
    onFrameDrop: () => {},
    onDragLeave: () => {},
    onDragOver: () => {},
    onDrop: () => {},
    frame: document,
    dropEffect: 'copy'
  }

  dragCount = 0

  componentWillReceiveProps(nextProps) {
    if (nextProps.frame !== this.props.frame) {
      this.resetDragging()
      this.stopFrameListeners(this.props.frame)
      this.startFrameListeners(nextProps.frame)
    }
  }

  componentWillMount() {
    this.startFrameListeners()
    this.resetDragging()
    window.addEventListener('dragover', this.onWindowDragOverOrDrop)
    window.addEventListener('drop', this.onWindowDragOverOrDrop)
  }

  componentWillUnmount() {
    this.stopFrameListeners()
    window.removeEventListener('dragover', this.onWindowDragOverOrDrop)
    window.removeEventListener('drop', this.onWindowDragOverOrDrop)
  }

  stopFrameListeners = frame => {
    frame = frame || this.props.frame
    frame.removeEventListener('dragenter', this.onFrameDrag)
    frame.removeEventListener('dragleave', this.onFrameDrag)
    frame.removeEventListener('drop', this.onFrameDrop)
  }

  startFrameListeners = frame => {
    frame = frame || this.props.frame
    frame.addEventListener('dragenter', this.onFrameDrag)
    frame.addEventListener('dragleave', this.onFrameDrag)
    frame.addEventListener('drop', this.onFrameDrop)
  }

  resetDragging = () => {
    this.dragCount = 0
    this.setState({
      draggingOverFrame: false,
      draggingOverTarget: false
    })
  }

  onDrop = event => {
    event.preventDefault()
    const files = event.dataTransfer
      ? event.dataTransfer.files
      : event.frame
      ? event.frame.files
      : undefined
    this.props.onDrop(files, event)
    this.resetDragging()
  }

  onDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
    event.dataTransfer.dropEffect = this.props.dropEffect

    // set active drag state only when file is dragged into
    // (in mozilla when file is dragged effect is "uninitialized")
    const effectAllowed = event.dataTransfer.effectAllowed
    if (effectAllowed === 'all' || effectAllowed === 'uninitialized') {
      this.setState({ draggingOverTarget: true })
    }

    this.props.onDragOver(event)
  }

  onDragLeave = event => {
    this.setState({ draggingOverTarget: false })
    this.props.onDragLeave(event)
  }

  onFrameDrag = event => {
    // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
    // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
    // "draggingOverFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
    this.dragCount += event.type === 'dragenter' ? 1 : -1
    if (this.dragCount === 1) {
      if (this.props.onFrameDragEnter(event) === false) {
        return
      }
      this.setState({ draggingOverFrame: true })
    } else if (this.dragCount === 0) {
      this.props.onFrameDragLeave(event)
      this.setState({ draggingOverFrame: false })
    }
  }

  onFrameDrop = event => {
    if (!this.state.draggingOverTarget) {
      this.resetDragging()
      this.props.onFrameDrop(event)
    }
  }

  onWindowDragOverOrDrop = event => {
    event.preventDefault()
  }

  render() {
    const { classes, children, className } = this.props
    const { draggingOverFrame, draggingOverTarget } = this.state

    return (
      <div
        className={clsx(classes.root, className)}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        <div
          className={clsx(classes.target, {
            [classes.frameDnD]: draggingOverFrame,
            [classes.targetDnD]: draggingOverTarget
          })}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default withTheme('fileDrop')(FileDrop)
