import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import * as themes from 'themes'

export default ({ theme = 'spry', shade = 'light', ...props }) => {
  const selectedTheme = themes[theme]
  selectedTheme.palette.type = shade
  const appTheme = createMuiTheme({
    ...selectedTheme,
    overrides: {
      MuiIconButton: {
        root: {
          // padding: 0,
          // width: 48,
          // height: 48
        }
      }
    }
  })

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}
