export const PASS_CREATED = 'pass::created'
export const PASS_REVOKED = 'pass::revoked'
export const PASS_CANCELED = 'pass::canceled'
export const PASS_CHECKED_IN = 'pass::checkedin'
export const PASS_CHECKED_OUT = 'pass::checkedout'

export const SEARCH_KEYS = [
  'pin',
  'type',
  'guest.name',
  'guest.email',
  'guest.phone',
  'owner.name',
  'owner.email',
  'place.title',
  'status'
]
