export default () => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    //alignItems: 'flex-end',
    display: 'flex'
  },
  card: {
    width: '100%',
    margin: '8px 0',
    //border: '1px solid rgba(0, 0, 0, 0.12)',
    //boxShadow: 'none',
    border: 'none'
  },
  header: {
    padding: '16px',
    paddingBottom: '8px'
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },

  content: {
    //padding: '8px 16px !important'
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16
  },

  title: {
    fontWeight: 500
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: 8,
    height: 48,
    width: 48,
    border: '2px solid #FFF',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  button: {
    marginRight: 16,
    alignSelf: 'flex-end'
  }
})
