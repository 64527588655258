import { useState } from 'react'
import useBus from './use-bus'

const useCommunity = () => {
  const [community, setCommunity] = useState()

  useBus('community::change', community => {
    setCommunity(community)
  })

  return community
}

export default useCommunity
