import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { Splash } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const domain = stores.communities.getDomain()

  useEffect(() => {
    fetch()
  }, [domain])

  const fetch = async () => {
    setLoading(true)
    await stores.communities.fetch()
    setLoading(false)
  }

  return <Redirect to="/communities" />

  // return loading ? (
  //   <Splash />
  // ) : domain ? (
  //   <Redirect to={`/dashboard/${domain}`} />
  // ) : (
  //   <Redirect to="/communities" />
  // )
}

export default view(Home)
