import React, { useReducer, useEffect, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import fileDownload from 'react-file-download'
import { ReactComponent as HeroImage } from 'svgs/goal.svg'
import DeleteDialog from 'components/delete-dialog'
import UploadDialog from 'components/upload-dialog'
import PlaceHolder from 'components/placeholder'
import Button from 'components/button'
import Table from 'components/table'
import { SEARCH_KEYS } from 'data/area/constants'
import { stores } from 'data'
import { view } from 'lib/store'
import createSearch from 'lib/search'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const AreaList = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    uploadDialog: false,
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS)
  const { list } = stores.areas
  const { domain } = match.params
  const { keyword, guide, uploadDialog, deleteDialog } = state
  const data = keyword ? search(list, keyword) : list

  useEffect(() => {
    if (domain) fetch(domain)
  }, [domain])

  const columns = [{ id: 'title', label: i18n`Title` }, { id: 'coords', label: i18n`Coordinates` }]
  const selected = state.selected.map(id => ({ id }))

  const reset = () => {
    setState({ selected: [] })
  }

  const fetch = async () => {
    try {
      await stores.areas.fetch(domain)
    } catch (error) {
      toast.error(i18n`Unable to fetch areas`)
    }
  }

  const update = async data => {
    try {
      await stores.areas.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update area`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.areas.destroy(domain, data)
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete area(s)`)
    }
    reset()
  }

  const upload = async data => {
    try {
      await stores.areas.upload(domain, data)
      toast.success(i18n`Data imported`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to import data. Check your data and try again`)
    }
    reset()
  }

  const download = async data => {
    try {
      const file = await stores.areas.download(domain, data)
      fileDownload(file, 'areas.csv')
    } catch (error) {
      toast.error(i18n`Unable to download areas`)
    }
    reset()
  }

  const page = id => {
    history.push(`${match.url}/${id}`)
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const onUpdate = data => {
    update(data)
  }

  const onDownload = () => {
    download(selected)
  }

  const onSelected = selected => {
    setState({ selected })
  }

  const onUploadAction = ({ ok, data }) => {
    if (ok) upload(data)
    closeUploadDialog()
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy(selected)
    else reset()
    closeDeleteDialog()
  }

  const openUploadDialog = () => {
    setState({ uploadDialog: true })
  }

  const closeUploadDialog = () => {
    setState({ uploadDialog: false })
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <HeroImage width="400" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => page('create')}>{i18n`CREATE AREA`}</Button>
            <Button onClick={openUploadDialog}>{i18n`IMPORT AREAS`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5" component="h2">
          {i18n`You haven't created any common area yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noInvite
        noAssign
        noItemDelete
        data={data}
        selected={state.selected}
        className="areas"
        title={i18n`Areas`}
        columns={columns}
        onRow={page}
        onDetail={page}
        onSwitch={onUpdate}
        onSearch={onSearch}
        onGuide={openGuide}
        onSelected={onSelected}
        onDownload={onDownload}
        onUpload={openUploadDialog}
        onDelete={openDeleteDialog}
        placeHolder={renderPlaceHolder}
        onCreate={() => page('create')}
      />
      <UploadDialog open={uploadDialog} title={i18n`Import areas`} onAction={onUploadAction} />
      <DeleteDialog open={deleteDialog} title={i18n`Delete areas`} onAction={onDeleteAction}>
        <p>
          {state.selected.length > 1
            ? i18n`Are you sure you want to delete the selected areas?`
            : i18n`Are you sure you want to delete this area?`}
        </p>
      </DeleteDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default view(AreaList)
