import React, { useReducer, Fragment } from 'react'
import MapView from 'google-map-react'
import ImageFallback from 'react-image-fallback'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import VideocamIcon from '@material-ui/icons/Videocam'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MicIcon from '@material-ui/icons/Mic'
import Paper from '@material-ui/core/Paper'
import * as config from 'config'
import AspectRatio from 'react-aspect-ratio'
import { Validator, Select } from 'components'
import Comments from 'components/comments'
import Image from 'components/bigpicture/image'
import Video from 'components/bigpicture/video'
import Audio from 'components/bigpicture/audio'
import * as cons from 'data/incident/constants'
import { mapTypes, mapStatus, mapVisibility } from 'data/incident/maps'
import { withTheme } from 'hocs'
import { isRequired } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import { timeAgo } from 'lib/utils'
import i18n from 'lib/i18n'
import 'react-aspect-ratio/aspect-ratio.css'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const IncidentForm = ({ data, user, classes, isUpdate, onCreateComment, onChange, ...props }) => {
  const [lat, lng] = data.coords || []
  const files = data.files || []
  const comments = data.comments || []
  const owner = data.owner || {}

  const [state, setState] = useReducer(reducer, {
    zoom: 16,
    statusMenuEl: null,
    center: { lat, lng }
  })

  const openStatusMenu = e => {
    setState({ statusMenuEl: e.currentTarget })
  }

  const closeStatusMenu = () => {
    setState({ statusMenuEl: null })
  }

  const onStatusChange = data => {
    props.onStatusChange(data)
    closeStatusMenu()
  }

  const getMapOptions = maps => {
    return {
      streetViewControl: false,
      scaleControl: false,
      fullscreenControl: true,
      // styles: [
      //   {
      //     featureType: 'poi.business',
      //     elementType: 'labels',
      //     stylers: [
      //       {
      //         visibility: 'on'
      //       }
      //     ]
      //   }
      // ],
      gestureHandling: 'none',
      disableDoubleClickZoom: true,
      minZoom: 11,
      maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_RIGHT,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
      },

      zoomControl: true,
      clickableIcons: false
    }
  }

  const rules = [
    runner('type', i18n`Type`, isRequired),
    runner('description', i18n`Description`, isRequired)
  ]

  const types = cons.TYPES.map(type => ({
    id: type,
    label: mapTypes(type)
  }))

  const visibilities = cons.VISIBILITIES.map(visibility => ({
    id: visibility,
    label: mapVisibility(visibility)
  }))

  const statuses = cons.STATUSES.map(status => ({
    id: status,
    label: mapStatus(status)
  }))

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Select
                    required
                    name="type"
                    id="type-field"
                    label={i18n`Incident Type`}
                    data={types}
                    value={data.type || ''}
                    onChange={onChange}
                    className={classes.textField}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Select
                    required
                    name="visibility"
                    id="visibility-field"
                    label={i18n`Incident Visibility`}
                    data={visibilities}
                    value={data.visibility || ''}
                    onChange={onChange}
                    className={classes.textField}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Select
                    required
                    name="status"
                    id="status-field"
                    label={i18n`Status`}
                    data={statuses}
                    value={data.status || ''}
                    onChange={onChange}
                    className={classes.textField}
                  />
                </Grid>
                {isUpdate && (
                  <Fragment>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="ownerName"
                        label={i18n`Created by`}
                        value={owner.name}
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="created"
                        label={i18n`Created`}
                        value={timeAgo(data.created)}
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="updated"
                        label={i18n`Updated`}
                        value={timeAgo(data.updated)}
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="views"
                        label={i18n`Views`}
                        value={data.views}
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    name="description"
                    id="description-field"
                    label={i18n`Description`}
                    value={data.description || ''}
                    className={classes.textField}
                    inputProps={{ readOnly: true }}
                    InputProps={{ disableUnderline: false }}
                  />
                </Grid>

                <Typography variant="h5" style={{ alignSelf: 'flex-start', marginTop: 16 }}>
                  {i18n`Media Files`} ({files.length})
                </Typography>
                <Grid container spacing={2}>
                  {files.map(file => (
                    <Grid item xs={3}>
                      {file.role === 'image' && (
                        <Image src={file.url}>
                          <Paper className={classes.paper}>
                            <AspectRatio ratio="1/1" key={file.url}>
                              <ImageFallback
                                src={file.thumUrl || file.url}
                                fallbackImage={'/images/photo.png'}
                                initialImage={'/images/photo.png'}
                              />
                              <div className={classes.gradient}>
                                <PhotoCameraIcon style={{ margin: 4, color: '#FFF' }} />
                              </div>
                            </AspectRatio>
                          </Paper>
                        </Image>
                      )}

                      {file.role === 'audio' && (
                        <Audio src={file.url}>
                          <Paper className={classes.paper}>
                            <AspectRatio ratio="1/1" key={file.url}>
                              <ImageFallback
                                src={file.thumUrl || file.url}
                                fallbackImage={'/images/audio.png'}
                                initialImage={'/images/audio.png'}
                              />
                              <div className={classes.gradient}>
                                <MicIcon style={{ margin: 4, color: '#FFF' }} />
                              </div>
                            </AspectRatio>
                          </Paper>
                        </Audio>
                      )}

                      {file.role === 'video' && (
                        <Video src={file.url}>
                          <Paper className={classes.paper}>
                            <AspectRatio ratio="1/1" key={file.url}>
                              <ImageFallback
                                src={file.thumUrl || file.url}
                                fallbackImage={'/images/video.png'}
                                initialImage={'/images/video.png'}
                              />
                              <div className={classes.gradient}>
                                <VideocamIcon style={{ margin: 4, color: '#FFF' }} />
                              </div>
                            </AspectRatio>
                          </Paper>
                        </Video>
                      )}
                    </Grid>
                  ))}
                </Grid>

                <Typography variant="h5" style={{ alignSelf: 'flex-start', marginTop: 16 }}>
                  {i18n`Location`} ({parseFloat(lat).toFixed(5)}, {parseFloat(lng).toFixed(5)})
                </Typography>

                <Grid item xs={12}>
                  <Paper elevation={3} className={classes.paper} style={{ height: 300 }}>
                    <MapView
                      bootstrapURLKeys={{ key: config.MAPS_API_KEY }}
                      defaultCenter={state.center}
                      defaultZoom={state.zoom}
                      options={getMapOptions}
                    >
                      <LocationOnIcon
                        lat={state.center.lat}
                        lng={state.center.lng}
                        style={{ fontSize: 44, color: '#2196f3', marginTop: -444 }}
                      />
                    </MapView>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" style={{ alignSelf: 'flex-start', marginBottom: 16 }}>
                    {i18n`Comments`} ({comments.length})
                  </Typography>

                  <Comments user={user} comments={comments} onCreate={onCreateComment} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

IncidentForm.defaultProps = {
  data: {
    files: [],
    coords: []
  }
}

// class IncidentForm extends Component {
//   constructor(props) {
//     super(props)

//     const [lat, lng] = props.data.coords

//     this.state = {
//       zoom: 16,
//       statusMenuEl: null,
//       center: { lat, lng }
//     }
//   }

//   openStatusMenu = e => {
//     this.setState({ statusMenuEl: e.currentTarget })
//   }

//   closeStatusMenu = () => {
//     this.setState({ statusMenuEl: null })
//   }

//   onStatusChange = data => {
//     this.props.onStatusChange(data)
//     this.closeStatusMenu()
//   }

//   getMapOptions = maps => {
//     return {
//       streetViewControl: false,
//       scaleControl: false,
//       fullscreenControl: true,
//       // styles: [
//       //   {
//       //     featureType: 'poi.business',
//       //     elementType: 'labels',
//       //     stylers: [
//       //       {
//       //         visibility: 'on'
//       //       }
//       //     ]
//       //   }
//       // ],
//       gestureHandling: 'none',
//       disableDoubleClickZoom: true,
//       minZoom: 11,
//       maxZoom: 18,

//       mapTypeControl: true,
//       mapTypeId: maps.MapTypeId.HYBRID,
//       mapTypeControlOptions: {
//         style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
//         position: maps.ControlPosition.TOP_RIGHT,
//         mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
//       },

//       zoomControl: true,
//       clickableIcons: false
//     }
//   }

//   render() {
//     const { data, user, classes, onCreateComment, onChange } = this.props
//     const rules = [
//       runner('type', i18n`Type`, isRequired),
//       runner('description', i18n`Description`, isRequired)
//     ]
//     const [lat, lng] = data.coords

//     const types = cons.TYPES.map(type => ({
//       id: type,
//       label: mapTypes(type)
//     }))

//     const visibilities = cons.VISIBILITIES.map(visibility => ({
//       id: visibility,
//       label: mapVisibility(visibility)
//     }))

//     const statuses = cons.STATUSES.map(status => ({
//       id: status,
//       label: mapStatus(status)
//     }))

//     return (
//       <Validator
//         data={data}
//         rules={rules}
//         onValidate={onChange}
//         render={validate => (
//           <form className={classes.form}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={10}>
//                 <Grid container spacing={1}>
//                   <Grid item xs={12} md={6}>
//                     <Select
//                       required
//                       name="type"
//                       id="type-field"
//                       label={i18n`Incident Type`}
//                       data={types}
//                       value={data.type || ''}
//                       onChange={onChange}
//                       className={classes.textField}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <Select
//                       required
//                       name="visibility"
//                       id="visibility-field"
//                       label={i18n`Incident Visibility`}
//                       data={visibilities}
//                       value={data.visibility || ''}
//                       onChange={onChange}
//                       className={classes.textField}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <Select
//                       required
//                       name="status"
//                       id="status-field"
//                       label={i18n`Status`}
//                       data={statuses}
//                       value={data.status || ''}
//                       onChange={onChange}
//                       className={classes.textField}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <TextField
//                       fullWidth
//                       name="userName"
//                       label={i18n`Created by`}
//                       value={data.userName}
//                       className={classes.textField}
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <TextField
//                       fullWidth
//                       name="created"
//                       label={i18n`Created`}
//                       value={timeAgo(data.created)}
//                       className={classes.textField}
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <TextField
//                       fullWidth
//                       name="updated"
//                       label={i18n`Updated`}
//                       value={timeAgo(data.updated)}
//                       className={classes.textField}
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <TextField
//                       fullWidth
//                       name="views"
//                       label={i18n`Views`}
//                       value={data.views}
//                       className={classes.textField}
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <TextField
//                       fullWidth
//                       multiline
//                       name="description"
//                       id="description-field"
//                       label={i18n`Description`}
//                       value={data.description || ''}
//                       className={classes.textField}
//                       inputProps={{ readOnly: true }}
//                       InputProps={{ disableUnderline: false }}
//                     />
//                   </Grid>

//                   <Typography variant="h5" style={{ alignSelf: 'flex-start', marginTop: 16 }}>
//                     {i18n`Media Files`} ({data.files.length})
//                   </Typography>
//                   <Grid container spacing={2}>
//                     {data.files.map(file => (
//                       <Grid item xs={3}>
//                         {file.role === 'image' && (
//                           <Image src={file.url}>
//                             <Paper className={classes.paper}>
//                               <AspectRatio ratio="1/1" key={file.url}>
//                                 <ImageFallback
//                                   src={file.thumUrl || file.url}
//                                   fallbackImage={'/images/photo.png'}
//                                   initialImage={'/images/photo.png'}
//                                 />
//                                 <div className={classes.gradient}>
//                                   <PhotoCameraIcon style={{ margin: 4, color: '#FFF' }} />
//                                 </div>
//                               </AspectRatio>
//                             </Paper>
//                           </Image>
//                         )}

//                         {file.role === 'audio' && (
//                           <Audio src={file.url}>
//                             <Paper className={classes.paper}>
//                               <AspectRatio ratio="1/1" key={file.url}>
//                                 <ImageFallback
//                                   src={file.thumUrl || file.url}
//                                   fallbackImage={'/images/audio.png'}
//                                   initialImage={'/images/audio.png'}
//                                 />
//                                 <div className={classes.gradient}>
//                                   <MicIcon style={{ margin: 4, color: '#FFF' }} />
//                                 </div>
//                               </AspectRatio>
//                             </Paper>
//                           </Audio>
//                         )}

//                         {file.role === 'video' && (
//                           <Video src={file.url}>
//                             <Paper className={classes.paper}>
//                               <AspectRatio ratio="1/1" key={file.url}>
//                                 <ImageFallback
//                                   src={file.thumUrl || file.url}
//                                   fallbackImage={'/images/video.png'}
//                                   initialImage={'/images/video.png'}
//                                 />
//                                 <div className={classes.gradient}>
//                                   <VideocamIcon style={{ margin: 4, color: '#FFF' }} />
//                                 </div>
//                               </AspectRatio>
//                             </Paper>
//                           </Video>
//                         )}
//                       </Grid>
//                     ))}
//                   </Grid>

//                   <Typography variant="h5" style={{ alignSelf: 'flex-start', marginTop: 16 }}>
//                     {i18n`Location`} ({parseFloat(lat).toFixed(5)}, {parseFloat(lng).toFixed(5)})
//                   </Typography>

//                   <Grid item xs={12}>
//                     <Paper elevation={3} className={classes.paper} style={{ height: 300 }}>
//                       <MapView
//                         bootstrapURLKeys={{ key: config.MAPS_API_KEY }}
//                         defaultCenter={this.state.center}
//                         defaultZoom={this.state.zoom}
//                         options={this.getMapOptions}
//                       >
//                         <LocationOnIcon
//                           lat={this.state.center.lat}
//                           lng={this.state.center.lng}
//                           style={{ fontSize: 44, color: '#2196f3', marginTop: -444 }}
//                         />
//                       </MapView>
//                     </Paper>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <Typography variant="h5" style={{ alignSelf: 'flex-start', marginBottom: 16 }}>
//                       {i18n`Comments`} ({data.comments.length})
//                     </Typography>

//                     <Comments user={user} comments={data.comments} onCreate={onCreateComment} />
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         )}
//       />
//     )
//   }
// }

export default withTheme('form')(IncidentForm)
