import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import uploadFile from 'lib/upload'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'documents')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  return list.replace(res)
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const destroy = async (domain, data) => {
  const res = await api.destroy(domain, data)
  return list.remove(res)
}

const upload = async (domain, file, options = {}) => {
  const data = { name: file.name, type: file.type, size: file.size }
  const { url, uploadUrl } = await api.getSignedUrl(domain, data)
  await uploadFile(uploadUrl, file, options)
  return create(domain, { ...data, url })
}

const ack = async (domain, data, userId) => {
  const item = list.get(data.id)
  if (userId && item.viewers.includes(userId)) return
  const res = await api.ack(domain, data)
  return list.set(data.id, res)
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.DOCUMENT_CREATED:
      return list.add(payload)
    case cons.DOCUMENT_DELETED:
      return list.remove(payload)
    default:
      break
  }
}

export default merge({
  get,
  ack,
  fetch,
  create,
  destroy,
  upload,
  event
})
