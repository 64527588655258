import React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Layout, Section } from 'components'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'
import bus from 'lib/bus'

const Help = ({ classes }) => {
  const openGuide = () => {
    console.log('OPEN GUIDE')
    bus.emit('guide::open')
  }

  return (
    <Layout>
      <Section noBackButton noAssign noActions noDelete title={i18n`Help center`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subtitle} variant="h5">{i18n`Guided Tour`}</Typography>
            <Typography
              className={classes.text}
              variant="body2"
            >{i18n`Open the guided tour from here to learn more about SPRY main navigation options.`}</Typography>
            <Button variant="contained" color="primary" onClick={openGuide}>
              {i18n`Open Guided Tour`}
            </Button>
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.subtitle}
              variant="h5"
            >{i18n`CSV templates for data imports`}</Typography>
            <Typography
              className={classes.text}
              variant="body2"
            >{i18n`Use these templates for creating and uploding your community data in the most simple and fast way, just open and modify them in your favorite spreedsheet software (Excel, Numbers, Open Office, etc) and then upload to SPRY.`}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Members CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/members.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Admins CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/admins.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Officers CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/officers.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Units CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/units.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Gates CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/gates.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`Areas CSV Template`}</Typography>
              <Button className={classes.button} color="primary" href="templates/areas.csv">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h6">{i18n`All CSV Templates`}</Typography>
              <Button className={classes.button} color="primary" href="templates/all.zip">
                <GetAppIcon className={classes.buttonIcon} />
                {i18n`Download`}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export default withTheme('help')(Help)
