import React from 'react'
import Guide from 'reactour'
import i18n from 'lib/i18n'

const noop = () => {}

export default ({ open, step = 0, steps, onChange = noop, onClose = noop, ...props }) => {
  const currentStep = steps[step] || {}
  const stepProps = currentStep.props || {}

  return open ? (
    <Guide
      disableInteraction
      startAt={0}
      maskSpace={5}
      steps={steps}
      isOpen={open}
      closeWithMask={false}
      onRequestClose={onClose}
      lastStepNextButton={i18n`Finish`}
      maskClassName="mask"
      className="helper"
      getCurrentStep={onChange}
      {...stepProps}
      {...props}
    />
  ) : null
}
