import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { Link, Route } from 'react-router-dom'

export default ({
  activeClassName,
  id,
  label,
  to,
  exact,
  icon,
  divider,
  avatar,
  tag,
  ...props
}) => (
  <Route path={to} exact={exact}>
    {({ match }) => {
      // const leftIcon = icon ? <FontIcon>{icon}</FontIcon> : null
      // const leftAvatar = avatar ? <Avatar>{avatar}</Avatar> : null
      // const isActive = Boolean(match)

      return divider ? (
        <Divider />
      ) : (
        <ListItem button id={id} to={to} component={Link} {...props} disableGutters={false}>
          <ListItemIcon className={activeClassName}>{icon}</ListItemIcon>
          <ListItemText primary={label} classes={{ primary: activeClassName }} />
        </ListItem>
      )
    }}
  </Route>
)
