import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.communities',
        content: (
          <div>
            <h2>{i18n`Communities`}</h2>
            <span>{i18n`This is the communities section where all communities you are part of will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search and create communities.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for communities.`}</span>
          </div>
        )
      },
      {
        selector: '.create-action',
        content: (
          <div>
            <h2>{i18n`Create Community`}</h2>
            <span>{i18n`Create a new community.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Community Record`}</h2>
            <span>{i18n`This is a community row with basic information about the community.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child td:last-child button',
        content: (
          <div>
            <h2>{i18n`Delete Community`}</h2>
            <span>{i18n`You can delete a community by clicking this icon.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Community Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)