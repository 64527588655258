export default theme => ({
  form: {
    marginTop: theme.spacing(2),
    padding: theme.spacing()
  },
  textField: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    textAlign: 'left'
  },
  field: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    color: 'red'
  },
  selectControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  selectMenu: {
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  focused: {
    backgroundColor: 'red',
    border: '1px solid red'
  },
  horizontal: {
    flexDirection: 'row'
  },
  radio: {
    marginRight: theme.spacing(3)
  },
  gridTitle: {
    borderBottom: '1px dotted rgba(0, 0, 0, 0.54)',
    marginBottom: theme.spacing()
  },
  actions: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between'
  },
  fieldset: {
    marginTop: theme.spacing()
  },
  slots: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)}px 0`
  },
  slotButton: {
    width: 188,
    color: 'rgba(0, 0, 0, 0.54)',
    margin: theme.spacing(),
    border: '1px solid rgba(0, 0, 0, 0.42)'
  },
  activeSlotButton: {
    border: 'none',
    boxShadow: 'none',
    color: '#FFF'
  },
  errorText: {
    color: '#E91E63'
  },
  avatarContainer: {
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    marginBottom: 24
  },
  avatar: {
    height: 240,
    width: 240,
    border: '6px solid #e0e0e0',
    marginBottom: 16,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  gradient: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.4) , rgba(0,0,0,0), rgba(0,0,0,0))',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  paper: {
    //height: 300,
    width: '100%',
    padding: 4,
    margin: '16px 0'
  }
})
