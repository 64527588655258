import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChartJS from 'chart.js'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import 'chartjs-plugin-datalabels'

// ChartJS.plugins.unregister(ChartDataLabels)

class Chart extends Component {
  static getLabelAsKey = d => d.label

  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    getDatasetAtEvent: PropTypes.func,
    getElementAtEvent: PropTypes.func,
    getElementsAtEvent: PropTypes.func,
    height: PropTypes.number,
    legend: PropTypes.object,
    onElementsClick: PropTypes.func,
    options: PropTypes.object,
    plugins: PropTypes.arrayOf(PropTypes.object),
    redraw: PropTypes.bool,
    type: function(props, propName, componentName) {
      if (!ChartJS.controllers[props[propName]]) {
        return new Error(
          'Invalid chart type `' + props[propName] + '` supplied to' + ' `' + componentName + '`.'
        )
      }
    },
    width: PropTypes.number,
    datasetKeyProvider: PropTypes.func
  }

  static defaultProps = {
    legend: {
      display: true,
      position: 'bottom'
    },
    type: 'doughnut',
    height: 150,
    width: 300,
    redraw: false,
    options: {},
    datasetKeyProvider: Chart.getLabelAsKey
  }

  componentWillMount() {
    this.chart = undefined
  }

  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate() {
    if (this.props.redraw) {
      this.chart.destroy()
      this.renderChart()
      return
    }

    this.updateChart()
  }

  shouldComponentUpdate(nextProps) {
    const { type, options, plugins, legend, height, width } = this.props

    if (nextProps.redraw === true) {
      return true
    }

    if (height !== nextProps.height || width !== nextProps.width) {
      return true
    }

    if (type !== nextProps.type) {
      return true
    }

    if (!isEqual(legend, nextProps.legend)) {
      return true
    }

    if (!isEqual(options, nextProps.options)) {
      return true
    }

    const nextData = this.transformDataProp(nextProps)

    if (!isEqual(this.shadowDataProp, nextData)) {
      return true
    }

    return !isEqual(plugins, nextProps.plugins)
  }

  componentWillUnmount() {
    this.chart.destroy()
  }

  transformDataProp(props) {
    const { data } = props
    if (typeof data == 'function') {
      const node = this.element
      return data(node)
    } else {
      return data
    }
  }

  // Chart.js directly mutates the data.dataset objects by adding _meta proprerty
  // this makes impossible to compare the current and next data changes
  // therefore we memoize the data prop while sending a fake to Chart.js for mutation.
  // see https://github.com/chartjs/Chart.js/blob/master/src/core/core.controller.js#L615-L617
  memoizeDataProps() {
    if (!this.props.data) {
      return
    }

    const data = this.transformDataProp(this.props)

    this.shadowDataProp = {
      ...data,
      datasets:
        data.datasets &&
        data.datasets.map(set => {
          return {
            ...set
          }
        })
    }

    return data
  }

  updateChart() {
    const { options } = this.props

    const data = this.memoizeDataProps(this.props)

    if (!this.chart) return

    if (options) {
      this.chart.options = ChartJS.helpers.configMerge(this.chart.options, options)
    }

    // Pipe datasets to chart instance datasets enabling
    // seamless transitions
    let currentDatasets = (this.chart.config.data && this.chart.config.data.datasets) || []
    const nextDatasets = data.datasets || []

    const currentDatasetsIndexed = keyBy(currentDatasets, this.props.datasetKeyProvider)

    // We can safely replace the dataset array, as long as we retain the _meta property
    // on each dataset.
    this.chart.config.data.datasets = nextDatasets.map(next => {
      const current = currentDatasetsIndexed[this.props.datasetKeyProvider(next)]
      if (current && current.type === next.type) {
        // The data array must be edited in place. As chart.js adds listeners to it.
        current.data.splice(next.data.length)
        next.data.forEach((point, pid) => {
          current.data[pid] = next.data[pid]
        })
        const { data, ...otherProps } = next
        // Merge properties. Notice a weakness here. If a property is removed
        // from next, it will be retained by current and never disappears.
        // Workaround is to set value to null or undefined in next.
        return {
          ...current,
          ...otherProps
        }
      } else {
        return next
      }
    })

    const { datasets, ...rest } = data

    this.chart.config.data = {
      ...this.chart.config.data,
      ...rest
    }

    this.chart.update()
  }

  renderChart() {
    const { options, legend, type, plugins } = this.props
    const node = this.element
    const data = this.memoizeDataProps()

    if (typeof legend !== 'undefined' && !isEqual(Chart.defaultProps.legend, legend)) {
      options.legend = legend
    }

    this.chart = new ChartJS(node, {
      type,
      data,
      options,
      plugins
    })
  }

  handleOnClick = event => {
    const instance = this.chart

    const { getDatasetAtEvent, getElementAtEvent, getElementsAtEvent, onElementsClick } = this.props

    getDatasetAtEvent && getDatasetAtEvent(instance.getDatasetAtEvent(event), event)
    getElementAtEvent && getElementAtEvent(instance.getElementAtEvent(event), event)
    getElementsAtEvent && getElementsAtEvent(instance.getElementsAtEvent(event), event)
    onElementsClick && onElementsClick(instance.getElementsAtEvent(event), event) // Backward compatibility
  }

  ref = element => {
    this.element = element
  }

  render() {
    const { height, width } = this.props

    return <canvas ref={this.ref} height={height} width={width} onClick={this.handleOnClick} />
  }
}

export default Chart

export class Doughnut extends Component {
  render() {
    return <Chart {...this.props} ref={ref => (this.chart = ref && ref.chart)} type="doughnut" />
  }
}

export class Pie extends Component {
  render() {
    return <Chart {...this.props} ref={ref => (this.chart = ref && ref.chart)} type="pie" />
  }
}

export class Line extends Component {
  render() {
    return <Chart {...this.props} ref={ref => (this.chart = ref && ref.chart)} type="line" />
  }
}

export class Bar extends Component {
  render() {
    const { horizontal } = this.props
    return (
      <Chart
        {...this.props}
        ref={ref => (this.chart = ref && ref.chart)}
        type={horizontal ? 'horizontalBar' : 'bar'}
      />
    )
  }
}
