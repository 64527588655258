import React, { Fragment, useEffect, useReducer } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import toast from 'lib/toast'
import formatDate from 'date-fns/format'
import { PlaceHolder, Table, DeleteDialog as RevokeDialog } from 'components'
import { ReactComponent as HeroImage } from 'svgs/empty.svg'
import { SEARCH_KEYS } from 'data/session/constants'
import createSearch from 'lib/search'
import { timeAgo } from 'lib/utils'
import i18n from 'lib/i18n'
import { stores } from 'data'
import { view } from 'lib/store'
import Guide from './guide'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const SessionList = () => {
  const [state, setState] = useReducer(reducer, {
    guide: false,
    revokeDialog: false,
    selected: [],
    keyword: ''
  })

  const search = createSearch(SEARCH_KEYS, {
    threshold: 0.8,
    location: 10,
    distance: 15
  })

  const { keyword, guide, revokeDialog } = state
  const { list } = stores.sessions
  const data = keyword ? search(list, keyword) : list

  const columns = [
    { id: 'name', label: i18n`Name` },
    { id: 'created', label: i18n`Created` },
    { id: 'ttl', label: i18n`Expires` },
    { id: 'active', label: i18n`Last active` }
  ]

  useEffect(() => {
    fetch()
  }, [])

  const reset = () => {
    setState({ data: {} })
  }

  const fetch = async () => {
    try {
      await stores.sessions.fetch()
    } catch (error) {
      toast.error(i18n`Unable to fetch sessions`)
    }
  }

  const revoke = async data => {
    try {
      await stores.sessions.revoke(data)
      toast.success(i18n`Revoked`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to revoke session(s)`)
    }
    reset()
  }

  const onSearch = keyword => {
    setState({ keyword })
  }

  const onRevokeAction = ({ ok }) => {
    if (ok) revoke(state.data)
    else reset()
    closeRevokeDialog()
  }

  const openRevokeDialog = data => {
    setState({ revokeDialog: true, data })
  }

  const closeRevokeDialog = () => {
    setState({ revokeDialog: false })
  }

  const openGuide = () => {
    setState({ guide: true })
  }

  const closeGuide = () => {
    setState({ guide: false })
  }

  const renderDeleteButton = (node, data) => {
    if (!data.current) return node
    return (
      <IconButton disabled aria-label={i18n`Delete`}>
        <DeleteIcon />
      </IconButton>
    )
  }

  const renderRow = (node, key, data) => {
    console.log('SESSIONS DATA ===>', key, data, data.created, data.ttl)

    switch (key) {
      case 'active':
        return data.current ? i18n`( current session )` : timeAgo(data.created)
      case 'created':
        return data.created ? formatDate(new Date(data.created), 'dd/MM/yyyy h:m a') : null
      case 'ttl':
        return data.ttl ? formatDate(new Date(data.ttl), 'dd/MM/yyyy h:m a') : null
      case 'delete':
        return renderDeleteButton(node, data)
      default:
        return node
    }
  }

  const renderPlaceHolder = () => {
    return (
      <PlaceHolder media={() => <HeroImage width="300" height="250" />}>
        <Typography variant="h5">{i18n`No session data to show`}</Typography>
      </PlaceHolder>
    )
  }

  return (
    <Fragment>
      <Table
        noCreate
        noInvite
        noAssign
        noUpload
        noDownload
        noCheckbox
        noItemSwitch
        noItemDetails
        noSelectedDownload
        data={data}
        row={renderRow}
        className="sessions"
        title={i18n`Sessions`}
        columns={columns}
        onGuide={openGuide}
        onSearch={onSearch}
        onDelete={openRevokeDialog}
        placeHolder={renderPlaceHolder}
      />
      <RevokeDialog
        open={revokeDialog}
        title={i18n`Revoke sessions`}
        onAction={onRevokeAction}
        okLabel={i18n`Revoke`}
      >
        <p>i18n`Are you sure you want to revoke this session?`</p>
      </RevokeDialog>
      <Guide open={guide} onClose={closeGuide} />
    </Fragment>
  )
}

export default view(SessionList)
