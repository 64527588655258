import React from 'react'
import TableFoot from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import i18n from 'lib/i18n'

const TableFooter = ({
  data,
  page,
  numRows,
  columns,
  noCheckbox,
  onPageChange,
  onNumRowsChange
}) => (
  <TableFoot>
    <TableRow>
      <TablePagination
        colSpan={columns.length + (noCheckbox ? 0 : 1) + 1}
        page={page}
        count={data.length}
        rowsPerPage={numRows}
        labelRowsPerPage={i18n`Rows per page:`}
        backIconButtonProps={{
          'aria-label': i18n`Previous Page`
        }}
        nextIconButtonProps={{
          'aria-label': i18n`Next Page`
        }}
        onChangePage={(e, page) => onPageChange(page)}
        onChangeRowsPerPage={e => onNumRowsChange(e.target.value)}
      />
    </TableRow>
  </TableFoot>
)

export default TableFooter
