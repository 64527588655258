import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Dialog } from 'components'
import i18n from 'lib/i18n'

const PeriodDialog = ({ className, periods, onAction, ...props }) => {
  const { value } = periods[0]
  const [period, setPeriod] = useState(value)

  useEffect(() => {
    if (props.open) setPeriod(value)
  }, [props.open])

  const onChange = e => {
    setPeriod(e.target.value)
  }

  return (
    <Dialog
      maxWidth="xs"
      title={i18n`Select period`}
      className={clsx('period-dialog', className)}
      onAction={({ ok }) => onAction({ ok, period })}
      {...props}
    >
      <RadioGroup
        id="periods-radio"
        aria-label="period"
        name="periods"
        value={period}
        onChange={onChange}
      >
        {periods.map(({ value, label }) => (
          <FormControlLabel
            key={`${value}${label}`}
            control={<Radio />}
            value={value}
            label={label}
          />
        ))}
      </RadioGroup>
    </Dialog>
  )
}

PeriodDialog.defaultProps = {
  onAction: () => {},
  periods: []
}

export default PeriodDialog
