import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Button } from 'components'
import Forest from 'components/svgs/forest'
import i18n from 'lib/i18n'

export default ({ onRetry }) => (
  <div>
    <Typography variant="h6">{i18n`Authentication Expired`}</Typography>
    <br />
    <Typography variant="subtitle1">{i18n`Please try again`}</Typography>
    <br />
    <Button variant="contained" color="primary" onClick={onRetry}>{i18n`Sign in`}</Button>
  </div>
)
