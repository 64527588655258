export default ({ palette, spacing }) => ({
  avatar: {
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.54)',
    backgroundColor: 'transparent'
  },
  addAvatar: {
    borderStyle: 'dashed'
  }
})
