import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { withTheme } from 'hocs'
import { isRequired, isEmail } from 'lib/validate/rules'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

export const rules = [
  runner('email', i18n`Email`, isRequired, isEmail),
  runner('name', i18n`Name`, isRequired)
]

const SignUpForm = ({ data, errors, isValid, classes, onChange, onSignUp }) => (
  <form onSubmit={onSignUp}>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">{i18n`Create new account`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="name"
          name="name"
          type="text"
          value={data.name}
          label={i18n`Full name`}
          className={classes.textField}
          onChange={value(onChange, 'name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="email"
          label={i18n`Email`}
          type="email"
          value={data.email}
          className={classes.textField}
          onChange={value(onChange, 'email')}
        />
      </Grid>
      <Grid className={classes.actions} item xs={12}>
        <Button color="primary" to="/signin" component={Link}>
          {i18n`Sign in`}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
          {i18n`Sign up`}
        </Button>
      </Grid>
    </Grid>
  </form>
)

export default withTheme('form')(SignUpForm)
