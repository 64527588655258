export default theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  STATUS_PENDING: {
    color: '#F57F17'
  },
  STATUS_CANCELLED: {
    color: '#E91E63'
  },
  STATUS_APPROVED: {
    color: '#388E3C'
  },
  approveButton: {
    color: '#388E3C'
  },
  cancelButton: {
    color: '#E91E63'
  }
})
