import React, { useState } from 'react'
import clsx from 'clsx'
import SwipeableRoutes from 'react-swipeable-routes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'
import Members from './members'
import Officers from './officers'
import Admins from './admins'

const MembersHome = ({ classes, match: { params } }) => {
  const [tab, setTab] = useState(0)

  const onChange = value => {
    setTab(value)
  }

  const { domain, id } = params

  return (
    <Layout>
      {!id && (
        <Tabs
          value={tab}
          textColor="primary"
          indicatorColor="primary"
          className={classes.root}
          onChange={(e, tab) => onChange(tab)}
        >
          <Tab to={`/members/${domain}/members`} label={i18n`Members`} component={Link} />
          <Tab to={`/members/${domain}/admins`} label={i18n`Admins`} component={Link} />
          <Tab to={`/members/${domain}/officers`} label={i18n`Officers`} component={Link} />
        </Tabs>
      )}
      <SwipeableRoutes onChangeIndex={onChange} slideClassName={clsx('slide', classes.slide)}>
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/members/:domain/members/:id?"
          component={Members}
        />
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/members/:domain/admins/:id?"
          component={Admins}
        />
        <PrivateRoute
          exact
          defaultParams={{ domain }}
          path="/members/:domain/officers/:id?"
          component={Officers}
        />
      </SwipeableRoutes>
    </Layout>
  )
}

export default withTheme('tabs')(MembersHome)
