import React, { useRef } from 'react'
import BigPicture from 'bigpicture'

const Image = ({ src, caption, children }) => {
  const link = useRef()

  const onZoom = () => {
    BigPicture({ el: link.current, imgSrc: src })
  }

  return (
    <div className="bigpicture link picture" onClick={onZoom} ref={link} caption={caption}>
      {children}
    </div>
  )
}

export default Image
