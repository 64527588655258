import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.areas',
        content: (
          <div>
            <h2>{i18n`Area List`}</h2>
            <span>{i18n`This is the common area list section where all common areas that belong to this community will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create, import, export common areas.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for areas.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .create-action',
        content: (
          <div>
            <h2>{i18n`Create Area`}</h2>
            <span>{i18n`Create a new common area.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .upload-action',
        content: (
          <div>
            <h2>{i18n`Import Areas`}</h2>
            <span>{i18n`Import areas using a CSV file.`}</span><br />
            <span>{i18n`Download the CSV import template from the help section under the main menu.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .download-action',
        content: (
          <div>
            <h2>{i18n`Download Areas`}</h2>
            <span>{i18n`Export all areas as a CSV file.`}</span>
          </div>
        )
      },
      {
        selector: '.areas tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Area Record`}</h2>
            <span>{i18n`Row with basic information about the common area. You can click on the row to get more details of the common area.`}</span>
          </div>
        )
      },
      {
        selector: '.areas tbody tr:first-child td:last-child span',
        content: (
          <div>
            <h2>{i18n`Area Activation`}</h2>
            <span>{i18n`You can activate or inactivate an area by clicking this switch button.`}</span>
          </div>
        )
      },
      {
        selector: '.areas .guide-action',
        content: (
          <div>
            <h2>{i18n`Area Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
