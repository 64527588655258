export const DOCUMENT_CREATED = 'document::created'
export const DOCUMENT_DELETED = 'document::deleted'
export const SEARCH_KEYS = [ 'name', 'url', 'type' ]

export const MIME_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'audio/mp3',
  'audio/mpeg',
  'video/mp4',
  'text/plain',
  'application/pdf',
  'application/vnd.msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.apple.keynote',
  'application/vnd.apple.pages',
  'application/vnd.apple.numbers'
]
