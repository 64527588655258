import React, { useEffect } from 'react'
import { LogoIconImg, GoodBye } from 'components'
import { withStyles } from 'hocs'
import { stores } from 'data'
import { view } from 'lib/store'
import compose from 'lib/compose'
import sleep from 'lib/sleep'

const SignOut = ({ classes, history }) => {
  const signout = async () => {
    await stores.auth.signout()
    await sleep(1000)
    history.push('/signin')
  }

  useEffect(() => {
    signout()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <LogoIconImg width="80" style={{ marginBottom: 25 }} />
        <GoodBye />
      </div>
    </div>
  )
}

const styles = {
  root: {
    height: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  container: {
    maxWidth: 350,
    textAlign: 'center'
  }
}

export default compose(
  withStyles(styles),
  view
)(SignOut)
