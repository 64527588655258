import React from 'react'
import clsx from 'clsx'
import { Dialog } from 'components'
import i18n from 'lib/i18n'

export default ({ children, className, onAction, ...props }) => (
  <Dialog
    title={i18n`Create item`}
    okLabel={i18n`Create`}
    onAction={onAction}
    className={clsx('create-dialog', className)}
    {...props}
  >
    {children}
  </Dialog>
)
