import React from 'react'
import GateDetail from './detail'
import GateCreate from './create'
import GateList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <GateCreate {...props} />
  else if (id && id !== 'create') return <GateDetail {...props} />
  else return <GateList {...props} />
}