import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import useForm from 'react-hook-form'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { Form } from 'components'
import { isPhone } from 'lib/phone'
import { isSlug } from 'lib/utils'
import slug from 'lib/slug'
import i18n from 'lib/i18n'

// const countries = [{ id: 'Costa Rica', label: 'Costa Rica' }]

// const codes = [
//   { id: '506', label: '+506' },
//   { id: '507', label: '+507' },
//   { id: '505', label: '+505' }
// ]

const Community = ({ data, buttonRef, classes, onSubmit }) => {
  // const [country, setCountry] = useState(data.country || '')
  const [domain, setDomain] = useState(data.domain || '')
  const form = useForm({ mode: 'onSubmit' })
  const { setValue, register, handleSubmit, errors, watch, formState } = form
  const code = 'CR'
  const title = watch('title')

  useEffect(() => {
    // register({ name: 'country' }, { required: i18n`Field required` })
    register(
      { name: 'domain' },
      {
        required: i18n`Field required`,
        validate: async value => {
          if (isSlug(value)) {
            const { ok } = await stores.communities.exists(value)
            return !ok || i18n`Domain is already taken`
          }
          return i18n`Domain can only have lower case letters and dashes`
        }
      }
    )
    // setValue('country', country)

    if (data.domain) {
      setValue('domain', domain)
    }
  }, [register])

  useEffect(() => {
    if (data.domain || formState.touched.includes('domain')) return
    setDomain(slug(title))
  }, [title])

  // const onCountryChange = ({ country }) => {
  //   setCountry(country)
  //   setValue('country', country)
  // }

  const onDomainChange = ({ target: { value: domain } }) => {
    setDomain(domain)
    setValue('domain', domain)
  }

  return (
    <Form noValidate buttonRef={buttonRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container style={{ padding: `0px 60px` }}>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <Typography variant="h6">{i18n`Community Information`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="title"
            label={i18n`Name`}
            onBlur={() => setValue('domain', domain)}
            defaultValue={data.title || ''}
            className={classes.textField}
            helperText={errors.title ? errors.title.message : i18n`Eg. ${'Condominio Acme'}`}
            error={Boolean(errors.title)}
            inputRef={register({
              required: i18n`Field required`
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="domain"
            label={i18n`Domain`}
            value={domain}
            className={classes.textField}
            onChange={onDomainChange}
            helperText={errors.domain ? errors.domain.message : i18n`Eg. ${'condominio-acme'}`}
            error={Boolean(errors.domain)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="contactPhone"
            label={i18n`Phone Number`}
            defaultValue={data.contactPhone || ''}
            className={classes.textField}
            inputRef={register({
              required: i18n`Field required`,
              validate: value => isPhone(value, code) || i18n`Invalid phone number`
            })}
            error={Boolean(errors.contactPhone)}
            helperText={
              errors.contactPhone
                ? errors.contactPhone.message
                : i18n`Eg. ${'88887777'} or ${'22608888'}`
            }
          />
        </Grid>
        {
          // <Grid item xs={12}>
          //   <Select
          //     required
          //     name="country"
          //     label={i18n`Country`}
          //     data={countries}
          //     value={country}
          //     onChange={onCountryChange}
          //     className={classes.textField}
          //     helperText={errors.country ? errors.country.message : ''}
          //     error={Boolean(errors.country)}
          //   />
          // </Grid>
        }
      </Grid>
    </Form>
  )
}

Community.defaultProps = {
  data: {},
  isValid: true,
  onChange: () => {},
  onSignUp: () => {}
}

export default withTheme('form')(Community)
