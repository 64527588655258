import React from 'react'

export default (props) => (
  <svg version="1.1" viewBox="0 0 496 496" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <polygon fill="#2196F3" points="270.288 177.784 223.84 112.984 201.408 135.416 247.856 200.208"></polygon>
        <polygon fill="#2196F3" points="279.344 57.48 326.36 121.712 303.928 144.144 256.912 79.904"></polygon>
        <polygon fill="#2196F3" points="90.392 247.792 135.72 312.352 158.144 289.928 112.824 225.36"></polygon>
        <polygon fill="#2196F3" points="191.792 256.28 145.904 190.92 168.328 168.496 214.216 233.856"></polygon>
        <path d="M440,192 C409.128,192 384,217.128 384,248 L384,496 L496,496 L496,248 C496,217.128 470.872,192 440,192 Z M480,480 L400,480 L400,248 C400,225.944 417.944,208 440,208 C462.056,208 480,225.944 480,248 L480,480 Z" fill="#666666"></path>
        <path d="M440,224 C426.768,224 416,234.768 416,248 C416,261.232 426.768,272 440,272 C453.232,272 464,261.232 464,248 C464,234.768 453.232,224 440,224 Z M440,256 C435.584,256 432,252.416 432,248 C432,243.584 435.584,240 440,240 C444.416,240 448,243.584 448,248 C448,252.416 444.416,256 440,256 Z" fill="#666666"></path>
        <path d="M96,228.616 L96,192 C96,168.264 78.664,148.552 56,144.72 L56,95.28 C78.664,91.456 96,71.744 96,48 C96,21.528 74.472,0 48,0 C21.528,0 0,21.528 0,48 C0,71.736 17.336,91.448 40,95.28 L40,144.728 C17.336,148.552 0,168.264 0,192 L0,496 L96,496 L96,356.96 C102.32,354.656 108.272,351.104 113.336,346.04 L371.312,88.064 L303.928,20.68 L96,228.616 Z M16,48 C16,30.352 30.352,16 48,16 C65.648,16 80,30.352 80,48 C80,65.648 65.648,80 48,80 C30.352,80 16,65.648 16,48 Z M48,160 C65.648,160 80,174.352 80,192 L16,192 C16,174.352 30.352,160 48,160 Z M80,480 L16,480 L16,208 L80,208 L80,244.616 L45.952,278.664 C27.384,297.232 27.376,327.464 45.952,346.048 C55.24,355.336 67.448,359.976 79.648,359.976 C79.768,359.976 79.88,359.944 80,359.944 C80,359.944 80,480 80,480 Z M102.024,334.736 C89.68,347.08 69.608,347.064 57.264,334.736 C44.928,322.392 44.928,302.312 57.264,289.976 L303.928,43.312 L348.688,88.072 L102.024,334.736 Z" fill="#666666"></path>
        <rect fill="#2196F3" x="16" y="448" width="64" height="32"></rect>
        <rect fill="#2196F3" x="400" y="448" width="80" height="32"></rect>
      </g>
    </g>
  </svg>
)