import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createBrowserHistory } from 'history'
import registerServiceWorker from 'registerServiceWorker'
import ThemeProvider from 'components/theme-provider'
import { ToastContainer } from 'react-toastify'
import { stores } from 'data'
import 'react-toastify/dist/ReactToastify.css'
import 'typeface-roboto'
import 'typeface-roboto-slab'
import App from './app'
import './index.css'

const history = createBrowserHistory()

render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <ThemeProvider>
      <ToastContainer autoClose={5000} position="bottom-right" />
      <Router history={history}>
        <App stores={stores} />
      </Router>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)

registerServiceWorker()
