import React, { useReducer } from 'react'
import setHours from 'date-fns/setHours'
import formatTime from 'date-fns/format'
import startOfHour from 'date-fns/startOfHour'
import { Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { formatDuration } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const AreaCreate = ({ match, history }) => {
  const resetData = () => {
    const time = startOfHour(new Date())
    const startTime = setHours(time, 7)
    const endTime = setHours(time, 19)

    return {
      endTime,
      startTime,
      duration: 1,
      terms: '',
      weekDays: [],
      reservable: false,
      active: true
    }
  }

  const [state, setState] = useReducer(reducer, {
    errors: {},
    isValid: false,
    data: resetData()
  })

  const { domain } = match.params

  const normalize = ({ startTime, endTime, weekDays, duration, terms, ...data }) => {
    if (data.reservable) {
      data.terms = terms
      data.startTime = formatTime(startTime, 'HH:mm')
      data.endTime = formatTime(endTime, 'HH:mm')
      data.weekDays = weekDays.map(day => parseInt(day, 10)).sort()
      data.duration = formatDuration(duration)
    }

    data.active = 'active' in data ? Boolean(data.active) : true

    if ('coords' in data) {
      data.coords = data.coords ? data.coords.split(',') : []
    }
    return data
  }

  const create = async data => {
    try {
      data = normalize(data)
      await stores.areas.create(domain, data)
      toast.success(i18n`Created`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to create area`)
      }
    }
  }

  const back = () => {
    history.push(`/resources/${domain}/areas`)
  }

  const onFormChange = (data, isValid, errors) => {
    setState({
      data: { ...state.data, ...data },
      errors: typeof errors !== 'undefined' ? errors : state.errors,
      isValid: typeof isValid !== 'undefined' ? isValid : state.isValid
    })
  }

  const onCreate = () => {
    create(state.data)
  }

  return (
    <Section
      noDelete
      noAssign
      onBack={back}
      onCancel={back}
      onSubmit={onCreate}
      title={i18n`Create Area`}
      backButtonLabel={i18n`Areas`}
      disableSubmitButton={!state.isValid}
    >
      <Form {...state} onChange={onFormChange} />
    </Section>
  )
}

export default view(AreaCreate)
