const sidebarMaxWidth = 260
const sidebarMinWidth = 70

export default theme => ({
  root: {
    //zIndex: 1,
    //overflow: 'hidden',
    //height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%'
  },
  container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  paper: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#FFF',
    padding: theme.spacing(2),
    '@media print': {
      boxShadow: 'none',
      border: '1px solid #999'
    }
  },
  toolbar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '@media print': {
      display: 'none'
    }
  },
  toolbarShift: {
    marginLeft: sidebarMaxWidth,
    width: `calc(100% - ${sidebarMaxWidth}px) !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbarLeft: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  toolbarMiddle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  toolbarRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  toolbarOffline: {
    backgroundColor: '#E65100'
  },
  content: {
    width: '100%',
    overflow: 'hidden',
    padding: theme.spacing(),
    marginTop: 64 + theme.spacing(),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(),
    marginLeft: sidebarMinWidth + theme.spacing(),
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    '@media print': {
      margin: 0
    }
  },
  contentShift: {
    marginLeft: sidebarMaxWidth + theme.spacing(),
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  userButton: {
    marginRight: `-${theme.spacing() + 4}px`
  },
  localeButton: {
    minWidth: 36
  },
  menuButton: {
    marginLeft: `-${theme.spacing() + 4}px`,
    marginRight: theme.spacing(4)
  },
  communitySelector: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  sidebar: {
    height: '100%',
    position: 'fixed',
    '@media print': {
      display: 'none !important'
    }
  },
  sidebarList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  sidebarPaper: {
    position: 'relative',
    width: sidebarMaxWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  sidebarClose: {
    width: sidebarMinWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  sidebarInner: {
    // Make the items inside not wrap when transitioning:
    display: 'flex',
    flexDirection: 'column',
    width: sidebarMaxWidth,
    height: '100%'
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  sidebarLogo: {
    marginTop: 5,
    marginLeft: theme.spacing(2)
  },
  sidebarSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  },
  sidebarItem: {
    paddingLeft: 24
  },
  chevron: {
    color: theme.palette.primary.main
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  },
  active: {
    color: `${theme.palette.primary.main} !important`
  },
  hide: {
    display: 'none'
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    pointerEvents: 'none'
  },
  avatar: {
    height: 38,
    width: 38,
    border: '2px solid #FFF',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  guide: {
    maxWidth: 500
  }
})
