import React, { Fragment, useReducer, useRef, useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import LockIcon from '@material-ui/icons/Lock'
import DeleteIcon from '@material-ui/icons/Delete'
import { Section } from 'components'
import { DeleteDialog } from 'components'
import { withTheme } from 'hocs'
import { stores } from 'data'
import { ENDED, STARTED } from 'data/poll/constants'
import { view } from 'lib/store'
import compose from 'lib/compose'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'
import { Bar } from 'components/chart'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const PollDetail = ({ classes, match, history, ...props }) => {
  const [state, setState] = useReducer(reducer, {
    endDialog: false,
    deleteDialog: false
  })

  const button = useRef()

  const { id, domain } = match.params
  const poll = stores.polls.get(id)

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ data: {} })
  }

  const start = async data => {
    try {
      await stores.polls.start(domain, data)
      toast.success(i18n`Started`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to start poll`)
    }
    reset()
  }

  const end = async data => {
    try {
      await stores.polls.end(domain, data)
      toast.success(i18n`Ended`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to end poll`)
    }
    reset()
  }

  const fetch = async () => {
    try {
      await stores.polls.fetch(domain, { id })
    } catch (error) {
      toast.error(i18n`Unable to fetch poll information`)
      back()
    }
  }

  const update = async data => {
    try {
      await stores.polls.update(domain, { ...data, id })
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update poll`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.polls.destroy(domain, data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      console.log(error)
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete poll`)
      }
    }
  }

  const back = () => {
    history.push(`/polls/${domain}`)
    return null
  }

  const onUpdate = data => {
    update(data)
  }

  const onStart = () => {
    start({ id })
  }

  const onEndAction = ({ ok }) => {
    if (ok) end({ id })
    closeEndDialog()
  }

  const openEndDialog = () => {
    setState({ endDialog: true })
  }

  const closeEndDialog = () => {
    setState({ endDialog: false })
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id })
    closeDeleteDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const onSubmit = () => {
    button.current.click()
  }

  const renderToolbarActions = data => {
    return (
      <Fragment>
        {data.status !== ENDED && (
          <Fragment>
            {data.status !== STARTED && (
              <Tooltip title={i18n`Start`}>
                <IconButton
                  aria-label={i18n`Start`}
                  className={classes.startButton}
                  onClick={() => onStart()}
                >
                  <PlayCircleFilledIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={i18n`End`}>
              <IconButton
                aria-label={i18n`End`}
                className={classes.endButton}
                onClick={() => openEndDialog()}
              >
                <LockIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
        <Tooltip title={i18n`Delete`}>
          <IconButton aria-label={i18n`Delete`} onClick={() => openDeleteDialog()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Fragment>
    )
  }

  if (!poll) return back()

  const { deleteDialog, endDialog } = state
  const data = { ...poll }

  console.log(data)

  const resultsData = {
    labels: data.answers.map(answer => answer.text),
    datasets: [
      {
        label: data.text,

        backgroundColor: 'rgba(33, 150, 243, 0.9)',
        borderColor: 'rgba(33, 150, 243, 0.9)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: data.answers.map(answer => answer.count + 0)
      }
    ]
  }

  const isEnded = data.status === ENDED
  const isStarted = data.status === STARTED

  return (
    <Fragment>
      <Section
        {...props}
        noAssign
        data={data}
        onBack={back}
        onCancel={back}
        onSubmit={onSubmit}
        noActions={isStarted || isEnded}
        title={i18n`Poll Detail`}
        backButtonLabel={i18n`Polls`}
        submitButtonLabel={i18n`Save`}
        onDelete={openDeleteDialog}
        toolbarActions={() => renderToolbarActions(data)}
      >
        <div style={{ padding: 16 }}>
          <div style={{ height: '70%', width: '70%' }}>
            <Bar
              horizontal
              data={resultsData}
              options={{
                responsive: true,

                plugins: {
                  datalabels: {
                    color: '#FFF',
                    align: 'start',
                    anchor: 'end',
                    offset: 20,
                    font: {
                      weight: 'bold',
                      size: 16
                    },
                    formatter: (value, ctx) => {
                      const { datasets } = ctx.chart.data
                      const sum = datasets[0].data.reduce((a, b) => a + b, 0)
                      return value ? Math.round((value * 100) / sum) + '%' : ''
                    }
                  }
                },
                tooltips: {
                  position: 'nearest'
                },
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: i18n`Respondents`
                      },
                      barPercentage: 0.5,
                      barThickness: 6,
                      maxBarThickness: 8,
                      minBarLength: 2,
                      ticks: {
                        min: 0
                      }
                    }
                  ]
                }
              }}
              legend={{ labels: { usePointStyle: false, fontSize: 18 } }}
            />
          </div>
        </div>
        <Divider />
        <Form isUpdate data={data} onSubmit={onUpdate} buttonRef={button} />
      </Section>
      <DeleteDialog open={deleteDialog} title={i18n`Delete poll`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this poll?`}</p>
      </DeleteDialog>
      <DeleteDialog
        open={endDialog}
        title={i18n`End poll`}
        okLabel={i18n`End poll`}
        onAction={onEndAction}
      >
        <p>
          {i18n`Are you sure you want to end this poll? Once a poll is ended it can't be started again.`}
        </p>
      </DeleteDialog>
    </Fragment>
  )
}

export default compose(
  withTheme('polls'),
  view
)(PollDetail)
