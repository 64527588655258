import React from 'react'
import { Switch } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import IncidentList from './list'
import IncidentCreate from './create'
import IncidentDetail from './detail'
import IncidentLogs from './logs'

export default () => (
  <Layout>
    <Switch>
      <PrivateRoute exact path="/incidents/:domain" component={IncidentList} />
      <PrivateRoute exact path="/incidents/:domain/create" component={IncidentCreate} />
      <PrivateRoute exact path="/incidents/:domain/:id/logs" component={IncidentLogs} />
      <PrivateRoute exact path="/incidents/:domain/:id" component={IncidentDetail} />
    </Switch>
  </Layout>
)
