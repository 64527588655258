import React, { useEffect } from 'react'
import Routes from 'routes'
import { stores } from 'data'
import useCommunity from 'hooks/use-community'
import useEventListener from 'hooks/use-listener'
import * as socket from 'lib/socket'
import { view } from 'lib/store'
import './app.css'

// test

const App = props => {
  useCommunity(community => {
    socket.connect(community)
  })

  useEventListener('storage', event => {
    if (event.key === 'signout' && event.oldValue !== event.newValue) {
      window.location.href = '/signin'
    }
  })

  useEffect(() => {
    initialize()
    return () => socket.close()
  }, [])

  const { locale } = stores.settings
  const { community = {} } = stores.communities

  const initialize = async () => {
    if (!stores.auth.isSignedIn()) return
    await fetch()
    await socket.connect()

    if (community && community.domain) {
      await socket.connect(community)
    }
  }

  const fetch = () => {
    stores.settings.fetch()
  }

  return (
    <div className="app">
      <Routes {...props} locale={locale} />
    </div>
  )
}

export default view(App)
