import diffInHours from 'date-fns/differenceInHours'
import * as storage from './storage'
import * as socket from './socket'
import sleep from './sleep'
import bus from './bus'

bus.on('online::change', () => {
  console.log('ONLINE CHANGING QUICK')
  check()
})

const REQUEST_TTL = 4 // hours

const getKey = data => {
  return JSON.stringify(data)
}

const getRequests = () => {
  return storage.get('requests', [])
}

const saveRequests = requests => {
  return storage.set('requests', requests)
}

const isNotQueued = (requests, key) => {
  return !requests.find(req => key === req.key)
}

const check = async () => {
  if (!socket.state.online) return

  const requests = await getRequests()
  const now = new Date()

  console.log('PROCESSING', requests.length)

  bus.emit('online::syncing', true)

  while (requests.length > 0) {
    const { url, time: ts, ...rest } = requests.shift()
    const time = new Date(ts)
    const hours = diffInHours(now, time)

    if (hours < REQUEST_TTL) {
      try {
        console.log('SENDING RREQUES TO ', url, rest.method)
        await fetch(url, rest)
      } catch (error) {
        console.log(error)
      }
      bus.emit('online::syncing', true)
    }
  }

  if (!requests.length) {
    await storage.del('requests')
  }

  await sleep(1000)
  bus.emit('online::syncing', false)
}

export default async (url, options) => {
  const { method, body, ...rest } = options

  if (socket.state.online || method === 'GET' || /signin|verify|users/.test(url)) {
    return fetch(url, options)
  }

  const requests = await getRequests()
  const key = getKey({ url, method, body })

  if (isNotQueued(requests, key)) {
    const time = new Date().getTime()
    requests.push({ ...rest, url, key, method, body, time })
    await saveRequests(requests)
  }

  throw new Error('Offline')
}

check()
