import React from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { withStyles } from 'hocs'

const StatusLine = ({ children, open, classes }) => (
  <Button
    fullWidth
    size="large"
    className={clsx(classes.root, open ? classes.open : classes.close)}
  >
    {children}
  </Button>
)

const styles = {
  root: {
    borderRadius: 0,
    backgroundColor: '#FFF',
    color: '#f50057',
    float: 'right',
    textAlign: 'right',
    justifyContent: 'flex-end',
    paddingRight: 0,
    marginBottom: 16,
    fontWeight: '600'
  },

  open: {
    borderBottom: '3px solid #4CAF50',
    color: '#f50057'
  },

  close: {
    borderBottom: '3px solid #558B2F',
    color: '#558B2F'
  }
}

export default withStyles(styles)(StatusLine)
