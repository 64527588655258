import React from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Badge from '@material-ui/core/Badge'
import PublicIcon from '@material-ui/icons/Public'
import BusinessIcon from '@material-ui/icons/Business'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import PeopleIcon from '@material-ui/icons/People'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DonutSmallIcon from '@material-ui/icons/DonutSmall'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import FolderIcon from '@material-ui/icons/Folder'
import BarChartIcon from '@material-ui/icons/BarChart'
import EventIcon from '@material-ui/icons/Event'
import NotficationsIcon from '@material-ui/icons/Notifications'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { NavItem, LogoImg } from 'components'
import { withTheme, withRouter } from 'hocs'
import compose from 'lib/compose'
import i18n from 'lib/i18n'
import { stores } from 'data'
import { view } from 'lib/store'

const Sidebar = ({ classes, location, open, onClose, match: { params } }) => {
  const { user = {} } = stores.accounts
  const domain = params.domain || stores.communities.getDomain()
  const notificationCount = stores.notifications.notificationCount(user.id) || 0
  const incidentCount = stores.incidents.incidentCount(user.id) || 0

  const isSelected = path => {
    const { pathname } = location
    return pathname.indexOf(path) > -1
  }

  const getActiveClassName = path => {
    return clsx({
      [classes.active]: isSelected(`/${path}`)
    })
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.sidebarPaper, !open && classes.sidebarClose)
      }}
      className={clsx('sidebar', classes.sidebar)}
      open={open}
    >
      <div className={classes.sidebarInner}>
        <div className={classes.sidebarHeader}>
          <div className={classes.sidebarLogo}>
            <LogoImg width="130" />
          </div>
          <IconButton
            className={clsx('minimize-sidebar-button', classes.chevron)}
            onClick={onClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List component="div" className={classes.sidebarList}>
          <div className={classes.sidebarSection}>
            <NavItem
              to="/communities"
              label={i18n`Communities`}
              icon={<PublicIcon />}
              id="menu-link-communities"
              activeClassName={getActiveClassName('communities')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/resources/${domain}/units`}
              label={i18n`Resources`}
              icon={<BusinessIcon />}
              id="menu-link-resources"
              activeClassName={getActiveClassName('resources')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/members/${domain}/members`}
              label={i18n`Members`}
              icon={<PeopleIcon />}
              id="menu-link-members"
              activeClassName={getActiveClassName('members')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/passes/${domain}`}
              label={i18n`Passes`}
              icon={<VpnKeyIcon />}
              id="menu-link-passes"
              activeClassName={getActiveClassName('passes')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/reservations/${domain}`}
              label={i18n`Reservations`}
              icon={<EventIcon />}
              id="menu-link-reservations"
              activeClassName={getActiveClassName('reservations')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/incidents/${domain}`}
              label={i18n`Incidents`}
              icon={
                incidentCount ? (
                  <Badge className={classes.margin} badgeContent={incidentCount} color="secondary">
                    <SmartphoneIcon />
                  </Badge>
                ) : (
                  <SmartphoneIcon />
                )
              }
              id="menu-link-incidents"
              activeClassName={getActiveClassName('incidents')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/notifications/${domain}`}
              label={i18n`Notifications`}
              icon={
                notificationCount ? (
                  <Badge
                    className={classes.margin}
                    badgeContent={notificationCount}
                    color="secondary"
                  >
                    <NotficationsIcon />
                  </Badge>
                ) : (
                  <NotficationsIcon />
                )
              }
              id="menu-link-notifications"
              activeClassName={getActiveClassName('notifications')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/documents/${domain}`}
              label={i18n`Documents`}
              icon={<FolderIcon />}
              id="menu-link-documents"
              activeClassName={getActiveClassName('documents')}
              className={classes.sidebarItem}
            />
            <NavItem
              disabled={!domain}
              to={`/polls/${domain}`}
              label={i18n`Polls`}
              icon={<BarChartIcon />}
              id="menu-link-polls"
              activeClassName={getActiveClassName('polls')}
              className={classes.sidebarItem}
            />
            {
              // <NavItem
              //   disabled={!domain}
              //   to={`/dashboard/${domain}`}
              //   label={i18n`Dashboard`}
              //   icon={<DonutSmallIcon />}
              //   id="menu-link-dashboard"
              //   activeClassName={getActiveClassName('dashboard')}
              //   className={classes.sidebarItem}
              // />
            }
          </div>
          <div className={clsx(classes.sidebarSection, classes.justifyEnd)}>
            <NavItem
              to="/help"
              label={i18n`Help`}
              icon={<HelpOutlineIcon />}
              id="menu-link-help"
              activeClassName={getActiveClassName('help')}
              className={classes.sidebarItem}
            />
            <NavItem
              to="/signout"
              label={i18n`Exit`}
              icon={<ExitToAppIcon />}
              id="menu-link-signout"
              className={classes.sidebarItem}
            />
          </div>
        </List>
      </div>
    </Drawer>
  )
}

export default compose(
  withTheme('layout'),
  withRouter,
  view
)(Sidebar)
