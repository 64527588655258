import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import fileDownload from 'react-file-download'
import { PlaceHolder, Button, Table, BarrierImg, DeleteDialog, UploadDialog } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { SEARCH_KEYS } from 'data/gate/constants'
import createSearch from 'lib/search'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Guide from './guide'

class GateList extends Component {
  state = {
    uploadDialog: false,
    deleteDialog: false,
    guide: false,
    selected: [],
    keyword: ''
  }

  search = createSearch(SEARCH_KEYS)

  get domain() {
    return this.props.match.params.domain
  }

  get data() {
    const { keyword } = this.state
    const list = stores.gates.list
    return keyword ? this.search(list, keyword) : list
  }

  get columns() {
    return [{ id: 'title', label: i18n`Title` }, { id: 'coords', label: i18n`Coordinates` }]
  }

  get selected() {
    return this.state.selected.map(id => ({ id }))
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.domain !== prevProps.match.params.domain) {
      this.fetch()
    }
  }

  reset = () => {
    this.setState({ selected: [] })
  }

  fetch = async () => {
    try {
      await stores.gates.fetch(this.domain)
    } catch (error) {
      toast.error(i18n`Unable to fetch gates`)
    }
  }

  update = async data => {
    try {
      await stores.gates.update(this.domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update gate`)
    }
    this.reset()
  }

  destroy = async data => {
    try {
      await stores.gates.destroy(this.domain, data)
      toast.success(i18n`Deleted`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to delete gate(s)`)
    }
    this.reset()
  }

  upload = async data => {
    try {
      await stores.gates.upload(this.domain, data)
      toast.success(i18n`Data imported`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to import data. Check your data and try again`)
    }
    this.reset()
  }

  download = async data => {
    try {
      const file = await stores.gates.download(this.domain, data)
      fileDownload(file, 'gates.csv')
    } catch (error) {
      toast.error(i18n`Unable to download gates`)
    }
    this.reset()
  }

  page = id => {
    this.props.history.push(`${this.props.match.url}/${id}`)
  }

  onSearch = keyword => {
    this.setState({ keyword })
  }

  onUpdate = data => {
    this.update(data)
  }

  onDownload = () => {
    this.download(this.selected)
  }

  onSelected = selected => {
    this.setState({ selected })
  }

  onUploadAction = ({ ok, data }) => {
    if (ok) this.upload(data)
    this.closeUploadDialog()
  }

  onDeleteAction = ({ ok }) => {
    if (ok) this.destroy(this.selected)
    else this.reset()
    this.closeDeleteDialog()
  }

  openUploadDialog = () => {
    this.setState({ uploadDialog: true })
  }

  closeUploadDialog = () => {
    this.setState({ uploadDialog: false })
  }

  openDeleteDialog = () => {
    this.setState({ deleteDialog: true })
  }

  closeDeleteDialog = () => {
    this.setState({ deleteDialog: false })
  }

  openGuide = () => {
    this.setState({ guide: true })
  }

  closeGuide = () => {
    this.setState({ guide: false })
  }

  renderPlaceHolder = () => {
    return (
      <PlaceHolder
        media={() => <BarrierImg width="200" height="200" />}
        actions={() => (
          <Fragment>
            <Button onClick={() => this.page('create')}>{i18n`CREATE GATE`}</Button>
            <Button onClick={this.openUploadDialog}>{i18n`IMPORT GATES`}</Button>
          </Fragment>
        )}
      >
        <Typography variant="h5" component="h2">
          {i18n`You haven't created any access gate for your community yet`}
        </Typography>
      </PlaceHolder>
    )
  }

  render() {
    const { guide, selected, uploadDialog, deleteDialog } = this.state

    return (
      <Fragment>
        <Table
          noInvite
          noAssign
          noItemDelete
          data={this.data}
          selected={selected}
          className="gates"
          title={i18n`Gates`}
          columns={this.columns}
          onRow={this.page}
          onDetail={this.page}
          onSwitch={this.onUpdate}
          onSearch={this.onSearch}
          onGuide={this.openGuide}
          onSelected={this.onSelected}
          onDownload={this.onDownload}
          onUpload={this.openUploadDialog}
          onDelete={this.openDeleteDialog}
          placeHolder={this.renderPlaceHolder}
          onCreate={() => this.page('create')}
        />
        <UploadDialog
          open={uploadDialog}
          title={i18n`Import gates`}
          onAction={this.onUploadAction}
        />
        <DeleteDialog open={deleteDialog} title={i18n`Delete gates`} onAction={this.onDeleteAction}>
          <p>
            {selected.length > 1
              ? i18n`Are you sure you want to delete the selected gates?`
              : i18n`Are you sure you want to delete this gate?`}
          </p>
        </DeleteDialog>
        <Guide open={guide} onClose={this.closeGuide} />
      </Fragment>
    )
  }
}

export default view(GateList)
