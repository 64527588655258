import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.sessions',
        content: (
          <div>
            <h2>{i18n`Session List`}</h2>
            <span>{i18n`This is the session list section where all your open sessions from different devices or browsers will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.sessions .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can search by name for a particular session.`}</span>
          </div>
        )
      },
      {
        selector: '.search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for sessions.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Session Record`}</h2>
            <span>{i18n`This is a session row with information about the sessions.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child td:last-child button',
        content: (
          <div>
            <h2>{i18n`Revoke session`}</h2>
            <span>{i18n`You can revoke a session by clicking this icon.`}</span>
          </div>
        )
      },
      {
        selector: '.guide-action',
        content: (
          <div>
            <h2>{i18n`Session Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]} {...props}
  />
)
