import React, { useReducer, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Slider from 'react-slider-simple'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import clsx from 'clsx'
import { Dialog, FileDrop, FileInput } from 'components'
import { withTheme } from 'hocs'
import bytesToSize from 'lib/bytes-to-size'
import i18n from 'lib/i18n'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const AvatarDialog = props => {
  const [state, setState] = useReducer(reducer, {
    scale: 1,
    file: null,
    error: null,
    maxSize: 2000000
  })

  const editor = useRef()
  const { file, scale, error } = state

  const onDrop = files => {
    reset()
    if (files.length) onFile(files[0])
  }

  const onFile = file => {
    if (!isFileTypeValid(file)) {
      return setState({
        error: i18n`File must be a CSV document`
      })
    }

    if (!isFileSizeValid(file)) {
      return setState({
        error: i18n`File size must be under ${bytesToSize(state.maxSize)}`
      })
    }

    setState({ file })
  }

  const onRemove = () => {
    setState({ file: null, scale: 1 })
  }

  const onAction = ({ ok }) => {
    let avatar

    if (ok) {
      const canvas = editor.current.getImage()
      avatar = canvas.toDataURL('image/jpeg', 0.8)
    }

    props.onAction({ ok, avatar })
    onRemove()
    reset()
  }

  const reset = () => {
    setState({ file: null, error: null, scale: 1 })
  }

  const isFileTypeValid = ({ type }) => {
    return /^image/.test(type)
  }

  const isFileSizeValid = ({ size }) => {
    return size < state.maxSize
  }

  const onScale = range => {
    const scale = parseFloat(((1.5 - 1) * range) / 100) + 1
    setState({ scale })
  }

  const { open, classes, className, title = i18n`Import data` } = props

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="xs"
      okLabel={i18n`Save`}
      okDisabled={!file}
      className={clsx('uplopad-dialog', className)}
      onAction={onAction}
    >
      {error && <p className={classes.error}>{error}</p>}

      <FileDrop onDrop={onDrop}>
        {file ? (
          <AvatarEditor
            ref={editor}
            image={file}
            width={280}
            height={280}
            border={0}
            borderRadius={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
          />
        ) : (
          <div>
            <CloudUploadIcon className={classes.icon} />
            <p>{i18n`Drag and drop your image here or`}</p>
            <FileInput
              variant="text"
              name="file"
              id="file-input"
              accept="image/*"
              onChange={onFile}
            >
              {i18n`Select file`}
            </FileInput>
          </div>
        )}
      </FileDrop>
      {file && (
        <Slider
          onChange={onScale}
          thumbColor="#2196f3"
          shadowColor="#2196f3"
          sliderPathColor="#CCC"
        />
      )}
    </Dialog>
  )
}

// class AvatarDialog extends PureComponent {
//   state = {
//     scale: 1,
//     file: null,
//     error: null,
//     maxSize: 2000000
//   }

//   onDrop = files => {
//     this.reset()
//     if (files.length) this.onFile(files[0])
//   }

//   onFile = file => {
//     if (!this.isFileTypeValid(file)) {
//       return this.setState({
//         error: i18n`File must be a CSV document`
//       })
//     }

//     if (!this.isFileSizeValid(file)) {
//       return this.setState({
//         error: i18n`File size must be under ${bytesToSize(this.state.maxSize)}`
//       })
//     }

//     this.setState({ file })
//   }

//   onRemove = () => {
//     this.setState({ file: null, scale: 1 })
//   }

//   onAction = ({ ok }) => {
//     let avatar

//     if (ok) {
//       const canvas = this.editor.getImage()
//       avatar = canvas.toDataURL('image/jpeg', 0.8)
//     }

//     this.props.onAction({ ok, avatar })
//     this.onRemove()
//     this.reset()
//   }

//   reset() {
//     this.setState({ file: null, error: null, scale: 1 })
//   }

//   isFileTypeValid({ type }) {
//     return /^image/.test(type)
//   }

//   isFileSizeValid({ size }) {
//     return size < this.state.maxSize
//   }

//   onScale = range => {
//     const scale = parseFloat(((1.5 - 1) * range) / 100) + 1
//     this.setState({ scale })
//   }

//   setEditorRef = editor => {
//     this.editor = editor
//   }

//   render() {
//     const { file, scale, error } = this.state
//     const { open, classes, className, title = i18n`Import data` } = this.props

//     return (
//       <Dialog
//         open={open}
//         title={title}
//         maxWidth="xs"
//         okLabel={i18n`Save`}
//         okDisabled={!file}
//         className={clsx('uplopad-dialog', className)}
//         onAction={this.onAction}
//       >
//         {error && <p className={classes.error}>{error}</p>}

//         <FileDrop onDrop={this.onDrop}>
//           {file ? (
//             <AvatarEditor
//               ref={this.setEditorRef}
//               image={file}
//               width={280}
//               height={280}
//               border={0}
//               borderRadius={0}
//               color={[255, 255, 255, 0.6]} // RGBA
//               scale={scale}
//               rotate={0}
//             />
//           ) : (
//             <div>
//               <CloudUploadIcon className={classes.icon} />
//               <p>{i18n`Drag and drop your image here or`}</p>
//               <FileInput
//                 variant="text"
//                 name="file"
//                 id="file-input"
//                 accept="image/*"
//                 onChange={this.onFile}
//               >
//                 {i18n`Select file`}
//               </FileInput>
//             </div>
//           )}
//         </FileDrop>
//         {file && (
//           <Slider
//             onChange={this.onScale}
//             thumbColor="#2196f3"
//             shadowColor="#2196f3"
//             sliderPathColor="#CCC"
//           />
//         )}
//       </Dialog>
//     )
//   }
// }

export default withTheme('uploadDialog')(AvatarDialog)
