import React from 'react'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { isRequired, isCoordinate } from 'lib/validate/rules'
import Validator from 'components/validator'
import { withTheme } from 'hocs'
import { runner } from 'lib/validate/runner'
import value from 'lib/value'
import i18n from 'lib/i18n'

const GateForm = ({ data, classes, isUpdate, onChange }) => {
  const rules = [
    runner('title', i18n`Title`, isRequired),
    runner('coords', i18n`Coordinates`, isCoordinate)
  ]

  return (
    <Validator
      data={data}
      rules={rules}
      onValidate={onChange}
      render={validate => (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                name="title"
                id="title-field"
                label={i18n`Title`}
                value={data.title || ''}
                className={classes.textField}
                onChange={value(validate, 'title')}
                helperText={!isUpdate && i18n`E.g. Primary gate`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="text"
                name="code"
                id="coords-field"
                label={i18n`Coordinates`}
                value={data.coords || ''}
                className={classes.textField}
                onChange={value(validate, 'coords')}
                helperText={i18n`E.g. ${'9.934739,‎-84.087502'} (lat, lng)`}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={i18n`Active`}
                control={
                  <Switch
                    name="active"
                    id="active-field"
                    className={classes.textField}
                    checked={Boolean(data.active)}
                    onChange={e => validate({ active: e.target.checked })}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

export default withTheme('form')(GateForm)
