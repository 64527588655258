import { get, post, put, del } from 'lib/api'

const toId = id => (id ? `/${id}` : '')

export const fetch = (domain, { id, ...data } = {}) => get(`/${domain}/members${toId(id)}`, data)
export const create = (domain, data) => post(`/${domain}/members`, data)
export const invite = (domain, data) => post(`/${domain}/members/invite`, data)
export const assign = (domain, data) => post(`/${domain}/members/assign`, data)
export const download = (domain, data) => get(`/${domain}/members/export`, data)
export const update = (domain, { id, ...data }) => put(`/${domain}/members/${id}`, data)
export const destroy = (domain, data) => del(`/${domain}/members`, data)
export const unassign = (domain, data) => del(`/${domain}/members/unassign`, data)

export const upload = (domain, { file }) => {
  const data = new FormData()
  data.append('file', file)
  return post(`/${domain}/members/import`, data, { isFile: true })
}
