import React, { useState } from 'react'
import SwipeableRoutes from 'react-swipeable-routes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'
import { Layout, PrivateRoute } from 'components'
import { withTheme } from 'hocs'
import i18n from 'lib/i18n'
import Profile from './profile'
import Sessions from './sessions'

const Account = ({ classes }) => {
  const [tab, setTab] = useState(0)

  const onChange = index => {
    setTab(index)
  }

  return (
    <Layout>
      <Tabs
        value={tab}
        textColor="primary"
        indicatorColor="primary"
        className={classes.root}
        onChange={(e, tab) => onChange(tab)}
      >
        <Tab to={'/account/profile'} label={i18n`Profile`} component={Link} />
        <Tab to={'/account/sessions'} label={i18n`Sessions`} component={Link} />
      </Tabs>
      <SwipeableRoutes onChangeIndex={onChange}>
        <PrivateRoute exact path="/account/profile" component={Profile} />
        <PrivateRoute exact path="/account/sessions" component={Sessions} />
      </SwipeableRoutes>
    </Layout>
  )
}

export default withTheme('tabs')(Account)
