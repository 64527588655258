export const COMMUNITY_CREATED = 'community::created'
export const COMMUNITY_UPDATED = 'community::updated'
export const COMMUNITY_DELETED = 'community::deleted'
export const SEARCH_KEYS = ['title', 'domain', 'province', 'address', 'country']

export const UNIT_ASSIGNED = 'unit::assigned'
export const UNIT_UNASSIGNED = 'unit::unassigned'
export const MEMBER_ASSIGNED = 'member::assigned'
export const MEMBER_UNASSIGNED = 'member::unassigned'

export const MICRO = 'micro'
export const PLUS = 'plus'
export const PREMIUM = 'premium'
