import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import FormatListNumbered from '@material-ui/icons/FormatListNumbered'
import FormatQuote from '@material-ui/icons/FormatQuote'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import Markdown from 'lib/md'
import i18n from 'lib/i18n'

const insert = (state, selection, actionData) => {
  const newSelection = {}
  const newState = {}

  if (actionData.key && actionData.key === state.lastKey) {
    // If the user clicks twice in the same button, undo the action
    const diff = state.value.length - state.lastValue.length
    newSelection.start = selection.start - diff / 2
    newSelection.end = selection.end - diff / 2
    newState.value = state.lastValue
    newState.lastKey = null
  } else {
    const md = new Markdown()
    newState.lastValue = state.value
    newState.lastKey = actionData.key
    newState.value = md.render(actionData, selection.start, selection.end, state.value)
    newSelection.start = md.selectionStart
    newSelection.end = md.selectionEnd
  }

  return {
    state: newState,
    selection: newSelection
  }
}

const commands = {
  bold: {
    title: i18n`Add bold text`,
    icon: <FormatBoldIcon />,
    execute(state, selection) {
      return insert(state, selection, {
        key: 'bold',
        prefix: '**',
        suffix: '**'
      })
    }
  },
  italic: {
    title: i18n`Add italic text`,
    icon: <FormatItalicIcon />,
    execute(state, selection) {
      return insert(state, selection, {
        key: 'italic',
        prefix: '_',
        suffix: '_'
      })
    }
  },
  quote: {
    title: i18n`Insert a quote`,
    icon: <FormatQuote />,
    execute(state, selection) {
      return insert(state, selection, {
        prefix: '> ',
        multiline: true
      })
    }
  },
  link: {
    title: i18n`Add a link`,
    icon: <InsertLinkIcon />,
    execute(state, selection) {
      return insert(state, selection, {
        key: 'url',
        prefix: '[',
        suffix: '](url)'
      })
    }
  },
  unorderedList: {
    title: i18n`Add a bulleted list`,
    icon: <FormatListBulleted />,
    execute(state, selection) {
      return insert(state, selection, {
        prefix: '- ',
        multiline: true
      })
    }
  },
  orderedList: {
    title: i18n`Add a numbered list`,
    icon: <FormatListNumbered />,
    execute(state, selection) {
      return insert(state, selection, {
        prefix: '1. ',
        multiline: true
      })
    }
  }
}

const groups = [
  [commands.bold, commands.italic],
  [commands.link, commands.quote],
  [commands.unorderedList, commands.orderedList]
]

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'justify',
    marginLeft: -theme.spacing(2)
  },
  commandGroup: {
    marginRight: theme.spacing()
  }
})

const Toolbar = ({ classes, onCommand }) => {
  return (
    <div className={classes.root}>
      {groups.map((group, i) => (
        <div className={classes.commandGroup} key={i}>
          {group.map((command, j) => (
            <Tooltip
              key={command.title}
              title={command.title}
              placement="bottom-start"
              enterDelay={300}
            >
              <IconButton
                tabIndex={-1}
                aria-label="edit-button"
                onClick={() => onCommand(command.execute)}
              >
                {command.icon}
              </IconButton>
            </Tooltip>
          ))}
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(Toolbar)
