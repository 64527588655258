import React from 'react'
import i18n from 'lib/i18n'
import Guide from 'components/guide'

export default props => (
  <Guide
    steps={[
      {
        selector: '.units',
        content: (
          <div>
            <h2>{i18n`Unit List`}</h2>
            <span>{i18n`This is the unit list section where all units that belong to this community will be listed.`}</span>
          </div>
        )
      },
      {
        selector: '.units .table-toolbar',
        content: (
          <div>
            <h2>{i18n`Toolbar`}</h2>
            <span>{i18n`From here you can perform actions like search, create, import, export units.`}</span>
          </div>
        )
      },
      {
        selector: '.units .search',
        content: (
          <div>
            <h2>{i18n`Search`}</h2>
            <span>{i18n`Instant search for units.`}</span>
          </div>
        )
      },
      {
        selector: '.units .create-action',
        content: (
          <div>
            <h2>{i18n`Create Unit`}</h2>
            <span>{i18n`Create a new unit.`}</span>
          </div>
        )
      },
      {
        selector: '.units .upload-action',
        content: (
          <div>
            <h2>{i18n`Import Units`}</h2>
            <span>{i18n`Import units using a CSV file.`}</span>
            <br />
            <span>{i18n`Download the CSV import template from the help section under the main menu.`}</span>
          </div>
        )
      },
      {
        selector: '.units .download-action',
        content: (
          <div>
            <h2>{i18n`Download Units`}</h2>
            <span>{i18n`Export all units as a CSV file.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child',
        content: (
          <div>
            <h2>{i18n`Unit Record`}</h2>
            <span>{i18n`Row with basic information about the unit. You can click on the row to get more details of the unit.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child .cell-members',
        content: (
          <div>
            <h2>{i18n`Assigned Units`}</h2>
            <span>{i18n`Here you will see members assigned to the unit.`}</span>
            <br />
            <span>{i18n`You can unassign a member from a unit by clicking on its X.`}</span>
          </div>
        )
      },
      {
        selector: 'tbody tr:first-child td:last-child span',
        content: (
          <div>
            <h2>{i18n`Unit Activation`}</h2>
            <span>{i18n`You can activate or inactivate a unit by clicking this switch button.`}</span>
          </div>
        )
      },
      {
        selector: '.units .guide-action',
        content: (
          <div>
            <h2>{i18n`Unit Guide`}</h2>
            <span>{i18n`Open this guide again.`}</span>
          </div>
        )
      }
    ]}
    {...props}
  />
)
