import i18n from 'lib/i18n'

export const required = field => i18n`${field} is required`
export const invalid = field => i18n`${field} is invalid`
export const email = () => i18n`Invalid email address`
export const url = () => i18n`Invalid URL address`
export const mobile = () => i18n`Invalid mobile number`
export const emailOrMobile = () => i18n`Invalid email or mobile`
export const coordinate = () => i18n`Invalid coordinates`
export const slug = () => i18n`Invalid slug string`
