import { useRef, useEffect } from 'react'
import bus from 'lib/bus'

const useBus = (event, fn) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = fn
  }, [fn])

  useEffect(() => {
    const cb = event => ref.current(event)
    bus.on(event, cb)
    return () => bus.off(event, cb)
  }, [event])
}

export default useBus
