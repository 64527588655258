import React, { Fragment, useReducer, useEffect } from 'react'
import BusinessIcon from '@material-ui/icons/Business'
import { AssignDialog, DeleteDialog, Section } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import { pick } from 'lib/utils'
import toast from 'lib/toast'
import i18n from 'lib/i18n'
import Form from './form'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const MemberDetail = ({ match, history }) => {
  const [state, setState] = useReducer(reducer, {
    data: {},
    errors: {},
    isValid: false,
    assignDialog: false,
    deleteDialog: false
  })

  const { id, domain } = match.params
  const { isValid, deleteDialog, assignDialog } = state
  const member = stores.members.get(id)

  const isSameData =
    member.name === state.data.name &&
    member.phone === state.data.phone &&
    member.active === state.data.active

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const reset = () => {
    setState({ errors: {}, isValid: false, data: { code: 'none' } })
  }

  const fetch = async () => {
    try {
      await stores.members.fetch(domain, { id })
    } catch (err) {
      toast.error(i18n`Unable to fetch member information`)
      back()
    }
  }

  const update = async data => {
    try {
      await stores.members.update(domain, data)
      toast.success(i18n`Updated`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to update member`)
    }
    reset()
  }

  const destroy = async data => {
    try {
      await stores.members.destroy(domain, data)
      toast.success(i18n`Deleted`)
      back()
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to delete member`)
      }
    }
  }

  const assign = async units => {
    try {
      const data = units.map(unit => ({ userId: member.id, unitId: unit.id, role: unit.role }))
      await stores.members.assign(domain, data)
      toast.success(i18n`Assigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to assign member`)
    }
    reset()
  }

  const unassign = async units => {
    try {
      const data = units.map(unit => ({ unitId: unit.id, userId: member.id }))
      await stores.members.unassign(domain, data)
      toast.success(i18n`Unassigned`)
    } catch (error) {
      if (error.message === 'Offline') toast.warn(i18n`Request queued`)
      else toast.error(i18n`Unable to unassign member`)
    }
    reset()
  }

  const back = () => {
    history.push(`/members/${domain}/members`)
    return null
  }

  const onFormChange = (data, isValid, errors) => {
    data = pick(data, ['id', 'name', 'phone', 'active'])
    setState({ data, isValid, errors })
  }

  const onUpdate = () => {
    update(state.data)
  }

  const onDeleteAction = ({ ok }) => {
    if (ok) destroy({ id })
    closeDeleteDialog()
  }

  const onAssignAction = ({ ok, ...rest }) => {
    if (ok && rest.assign.length) assign(rest.assign)
    if (ok && rest.unassign.length) unassign(rest.unassign)
    closeAssignDialog()
  }

  const openDeleteDialog = () => {
    setState({ deleteDialog: true })
  }

  const closeDeleteDialog = () => {
    setState({ deleteDialog: false })
  }

  const openAssignDialog = () => {
    setState({ assignDialog: true })
  }

  const closeAssignDialog = () => {
    setState({ assignDialog: false })
  }

  if (!member) return back()

  const selectedUnits = member.units.map(unit => `${unit.id}:${unit.role}`)

  const units = stores.units.list.map(unit => {
    const { role } = member.units.find(u => u.id === unit.id) || { role: 'resident' }
    return { ...unit, role, value: `${unit.id}:${role}` }
  })

  const data = {
    ...member,
    ...state.data,
    units: member.units.map(unit => ` ${unit.title}`).sort()
  }

  return (
    <Fragment>
      <Section
        data={data}
        title={i18n`Member Detail`}
        backButtonLabel={i18n`Members`}
        onAssign={openAssignDialog}
        onDelete={openDeleteDialog}
        onCancel={back}
        onBack={back}
        onSubmit={onUpdate}
        disableSubmitButton={!isValid || isSameData}
        assignIcon={<BusinessIcon />}
      >
        <Form isUpdate data={data} onChange={onFormChange} />
      </Section>
      <AssignDialog
        id={id}
        title={i18n`Assign units`}
        open={assignDialog}
        data={units}
        selected={selectedUnits}
        onAction={onAssignAction}
      />
      <DeleteDialog open={deleteDialog} title={i18n`Delete member`} onAction={onDeleteAction}>
        <p>{i18n`Are you sure you want to delete this member?`}</p>
      </DeleteDialog>
    </Fragment>
  )
}

export default view(MemberDetail)
