import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { withTheme } from 'hocs'
import value from 'lib/value'
import i18n from 'lib/i18n'

const Comment = React.memo(({ create, title, subtitle, text: inText, classes, user, ...props }) => {
  const [text, setText] = useState('')

  const onChange = ({ text }) => {
    setText(text)
  }

  const onCreate = () => {
    props.onCreate({ text, time: Date.now() })
    setText('')
  }

  return (
    <div className={classes.root}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.header, content: classes.headerContent }}
          title={
            <div className={classes.titleContent}>
              <Avatar
                alt={user.name}
                src={user.avatar || '/images/profile.jpg'}
                className={classes.avatar}
              />
              <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                {!create ? title : user.name}
              </Typography>
            </div>
          }
          subheader={!create ? subtitle : i18n`Now`}
        />
        <CardContent classes={{ root: classes.content }}>
          {!create ? (
            <Typography>{inText}</Typography>
          ) : (
            <TextField
              fullWidth
              multiline
              rowsMax="4"
              InputProps={{
                disableUnderline: true
              }}
              placeholder={i18n`Write your comment here`}
              name="text"
              value={text}
              id="text-field"
              onChange={value(onChange, 'text')}
            />
          )}
        </CardContent>
      </Card>
      {create && (
        <div className={classes.actions}>
          {
            // <Button
            //   variant="contained"
            //   onClick={onCreate}
            //   className={classes.button}
            // >{i18n`CLOSE INCIDENT`}</Button>
          }
          <Button
            variant="contained"
            onClick={onCreate}
            className={classes.button}
          >{i18n`COMMENT`}</Button>
        </div>
      )}
    </div>
  )
})

Comment.defaultProps = {
  user: {},
  comment: {},
  create: false,
  onCreate: () => {}
}

export default withTheme('comment')(Comment)
