import React, { useState, useReducer, useRef, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from './toolbar'

const reducer = (state, data) => {
  return { ...state, ...data }
}

const Editor = props => {
  const [selection, setSelection] = useState()

  const [state, setState] = useReducer(reducer, {
    value: (props.value || '').replace(/\r\n/g, '\n')
  })

  const textArea = useRef()

  useEffect(() => {
    if (typeof selection !== 'object') return
    const { start, end } = selection
    textArea.current.setSelectionRange(start, end)
  }, [selection])

  const onChange = ({ currentTarget: { value } }) => {
    setState({ value, lastKey: null })
    props.onChange(value)
  }

  const onCommand = async command => {
    const { state: commandState, selection } = command(state, {
      start: textArea.current.selectionStart,
      end: textArea.current.selectionEnd
    })

    textArea.current.focus()
    textArea.current.setSelectionRange(0, textArea.current.value.length)

    // Firefox allows us to use `setState` normally without breaking history
    // In Chrome and others it's better to use this in order for undo/redo to behave correctly
    // Unfortunately this will call `props.onChange` once per line
    if (!/firefox/i.test(navigator.userAgent)) {
      document.execCommand('insertText', false, commandState.value)
    }

    setState(commandState)
    setSelection(selection)
  }

  return (
    <div>
      <TextField
        {...props}
        multiline
        margin="normal"
        onChange={onChange}
        className={props.classes.input}
        value={state.value}
        inputRef={textArea}
        fullWidth
      />
      <Toolbar onCommand={onCommand} />
    </div>
  )
}

Editor.defaultProps = {
  value: '',
  onChange: () => {}
}

const styles = theme => ({
  input: {
    marginTop: theme.spacing()
  }
})

export default withStyles(styles)(Editor)
