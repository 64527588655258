import { createStore, createList } from 'lib/store'
import { createMerge } from 'lib/utils'
import * as cons from './constants'
import * as api from './api'

const state = { list: [] }
const store = createStore(state, 'passes')
const merge = createMerge(store)
const list = createList(store)

const get = id => {
  return list.get(id)
}

const fetch = async (domain, force) => {
  const res = (await api.fetch(domain)) || []
  Array.isArray(res) ? list.replace(res) : list.add(res)
  return res
}

const create = async (domain, data) => {
  const res = await api.create(domain, data)
  return list.add(res)
}

const update = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.update(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const revoke = async (domain, data) => {
  const item = list.get(data.id)
  list.set(data.id, data)
  try {
    const res = await api.revoke(domain, data)
    return list.set(item.id, res)
  } catch (error) {
    list.set(item.id, item)
    throw error
  }
}

const download = async (domain, data) => {
  return api.download(domain, data)
}

const sortLogs = (logs = []) => {
  return logs.sort((a, b) => b.ts - a.ts)
}

const setLogs = (id, logs = []) => {
  list.set(id, { logs: sortLogs(logs) })
}

const fetchLogs = async (domain, data) => {
  const logs = await api.fetchLogs(domain, data)
  return setLogs(data.id, logs)
}

const event = ({ type, payload }) => {
  switch (type) {
    case cons.PASS_CREATED:
    case cons.PASS_REVOKED:
    case cons.PASS_CANCELED:
    case cons.PASS_CHECKED_IN:
    case cons.PASS_CHECKED_OUT:
      return list.add(payload)
    default:
      break
  }
}

export default merge({
  get,
  fetch,
  create,
  update,
  revoke,
  download,
  fetchLogs,
  setLogs,
  event
})
