import React, { Fragment, useEffect, useState } from 'react'
import clsx from 'clsx'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Select } from 'components'
import i18n from 'lib/i18n'
import Dialog from './dialog'

const noop = fn => fn
const empty = []

const AssignDialog = ({ data, className, ...props }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  const onRoleChange = value => {
    const [id] = value.split(':')
    setSelected([...selected.filter(v => v.indexOf(id) === -1), value])
  }

  const onChange = value => {
    const [id] = value.split(':')
    setSelected(isSelected(id) ? selected.filter(v => v.indexOf(id) === -1) : [...selected, value])
  }

  const onAction = ({ ok }) => {
    const assign = selected.filter(id => !props.selected.find(v => v === id))

    const unassign = props.selected.filter(s => {
      const [id] = (s || '').split(':')
      return !selected.find(v => v.indexOf(id) !== -1)
    })

    props.onAction({
      ok,
      assign: assign.map(a => {
        const [id, role] = a.split(':')
        return { id, role }
      }),
      unassign: unassign.map(a => {
        const [id, role] = a.split(':')
        return { id, role }
      })
    })

    setSelected([])
  }

  const getSelected = value => selected.find(s => s.indexOf(value) !== -1)
  const isSelected = value => Boolean(getSelected(value))
  const { okLabel = i18n`Assign`, title = i18n`Assign items`, ...rest } = props

  const controls = data.map(item => ({
    value: item.value || item.id,
    label: item.name || item.title
  }))

  const roles = [
    { value: 'owner', label: i18n`Owner` },
    { value: 'tenant', label: i18n`Tenant` },
    { value: 'resident', label: i18n`Resident` }
  ]

  return (
    <Dialog
      {...rest}
      maxWidth="xs"
      title={title}
      okLabel={okLabel}
      onAction={onAction}
      className={clsx('assign-dialog', className)}
    >
      <FormControl style={{ width: '100%' }} component="fieldset">
        <FormGroup>
          {controls.map(({ value, label }) => {
            const [id, role] = value.split(':')
            const [_, selectedRole] = (getSelected(id) || '').split(':')
            return (
              <div key={value} style={{ display: 'flex' }}>
                <FormControlLabel
                  style={{ width: '100%' }}
                  key={`ckeckbox-${label}${value}`}
                  label={label}
                  control={
                    <Checkbox
                      value={value}
                      checked={isSelected(id)}
                      onChange={() => onChange(value)}
                    />
                  }
                />

                <Select
                  variant="outlined"
                  required
                  noLabel
                  disabled={!isSelected(id)}
                  name="role"
                  id="role-field"
                  itemLabel="label"
                  itemValue="value"
                  label={i18n`Role`}
                  data={roles}
                  value={selectedRole || role || 'resident'}
                  onChange={({ role }) => onRoleChange(`${id}:${role}`)}
                />
              </div>
            )
          })}
        </FormGroup>
      </FormControl>
    </Dialog>
  )
}

AssignDialog.defaultProps = {
  selected: [],
  data: empty,
  onAction: noop,
  className: ''
}

// class AssignDialog extends Component {
//   static defaultProps = {
//     selected: [],
//     data: empty,
//     onAction: noop,
//     className: ''
//   }

//   state = {
//     selected: []
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.selected !== prevProps.selected) {
//       this.setSelected(this.props.selected)
//     }
//   }

//   setSelected(selected = []) {
//     this.setState({ selected })
//   }

//   onChange = value => {
//     const { selected } = this.state
//     this.setState({
//       selected: this.isSelected(value) ? selected.filter(v => v !== value) : [...selected, value]
//     })
//   }

//   onAction = ({ ok }) => {
//     const assign = this.state.selected.filter(
//       selected => !this.props.selected.find(id => id === selected)
//     )

//     const unassign = this.props.selected.filter(
//       id => !this.state.selected.find(selected => selected === id)
//     )

//     this.props.onAction({
//       ok,
//       assign: assign.map(id => ({ id })),
//       unassign: unassign.map(id => ({ id }))
//     })

//     this.setSelected()
//   }

//   isSelected = value => {
//     return this.state.selected.indexOf(value) !== -1
//   }

//   render() {
//     let {
//       data,
//       className,
//       onAction,
//       okLabel = i18n`Assign`,
//       title = i18n`Assign items`,
//       ...props
//     } = this.props

//     const controls = data.map(item => ({
//       value: item.id,
//       label: item.name || item.title
//     }))

//     return (
//       <Dialog
//         maxWidth="xs"
//         title={title}
//         okLabel={okLabel}
//         onAction={this.onAction}
//         className={clsx('assign-dialog', className)}
//         {...props}
//       >
//         <FormControl component="fieldset">
//           <FormGroup>
//             {controls.map(({ value, label }) => (
//               <FormControlLabel
//                 key={`ckeckbox-${label}${value}`}
//                 label={label}
//                 control={
//                   <Checkbox
//                     value={value}
//                     checked={this.isSelected(value)}
//                     onChange={() => this.onChange(value)}
//                   />
//                 }
//               />
//             ))}
//           </FormGroup>
//         </FormControl>
//       </Dialog>
//     )
//   }
// }

export default AssignDialog
