import React from 'react'
import Typography from '@material-ui/core/Typography'
import i18n from 'lib/i18n'

export default ({ name }) => (
  <div>
    <Typography variant="h6">{i18n`Authenticated`}</Typography>
    <br />
    <Typography variant="subtitle1">
      {i18n`Welcome`} <strong>{name}</strong>!
    </Typography>
  </div>
)
