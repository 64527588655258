import React, { Fragment, useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Tooltip from '@material-ui/core/Tooltip'
import toast from 'lib/toast'
import { Section } from 'components'
import { DeleteDialog as RevokeDialog } from 'components'
import { stores } from 'data'
import { view } from 'lib/store'
import i18n from 'lib/i18n'
import DetailForm from './forms/detail'

const PassDetail = ({ match, history, ...props }) => {
  const [revokeDialog, setRevokeDialog] = useState(false)
  const { id, domain } = match.params
  const pass = stores.passes.get(id)

  useEffect(() => {
    if (domain) fetch()
  }, [domain])

  const fetch = async () => {
    try {
      await stores.passes.fetch(domain, { id })
    } catch (err) {
      toast.error(i18n`Unable to fetch pass information`)
      back()
    }
  }

  const revoke = async data => {
    try {
      await stores.passes.revoke(domain, data)
      toast.success(i18n`Revoked`)
    } catch (error) {
      if (error.message === 'Offline') {
        toast.warn(i18n`Request queued`)
        back()
      } else {
        toast.error(i18n`Unable to revoke pass`)
      }
    }
  }

  const back = () => {
    history.push(`/passes/${domain}`)
    return null
  }

  const logs = () => {
    history.push(`/passes/${domain}/${id}/logs`)
  }

  const onRevokeAction = ({ ok }) => {
    if (ok) revoke({ id })
    closeRevokeDialog()
  }

  const openRevokeDialog = () => {
    setRevokeDialog(true)
  }

  const closeRevokeDialog = () => {
    setRevokeDialog(false)
  }

  const renderToolbarActions = () => {
    return (
      <Fragment>
        <Tooltip title={i18n`Event logs`}>
          <IconButton aria-label={i18n`Event logs`} onClick={logs}>
            <AccessTimeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={i18n`Revoke`}>
          <IconButton aria-label={i18n`Revoke`} onClick={openRevokeDialog}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Fragment>
    )
  }

  if (!pass) return back()

  return (
    <Fragment>
      <Section
        {...props}
        noAssign
        noActions
        noDelete={pass.status === 'REVOKED'}
        data={pass}
        title={i18n`Pass Detail`}
        backButtonLabel={i18n`Passes`}
        toolbarActions={renderToolbarActions}
        onBack={back}
      >
        <DetailForm isUpdate data={pass} />
      </Section>
      <RevokeDialog
        open={revokeDialog}
        okLabel={i18n`Revoke`}
        title={i18n`Revoke pass`}
        onAction={onRevokeAction}
      >
        <p>{i18n`Are you sure you want to revoke this pass?`}</p>
      </RevokeDialog>
    </Fragment>
  )
}

// class PassDetail extends Component {
//   state = {
//     revokeDialog: false
//   }

//   get id() {
//     return this.props.match.params.id
//   }

//   get domain() {
//     return this.props.match.params.domain
//   }

//   get pass() {
//     return stores.passes.get(this.id)
//   }

//   componentDidMount() {
//     this.fetch()
//   }

//   componentDidUpdate(prevProps) {
//     if (this.domain !== prevProps.match.params.domain) {
//       this.fetch()
//     }
//   }

//   fetch = async () => {
//     try {
//       await stores.passes.fetch(this.domain, { id: this.id })
//     } catch (err) {
//       toast.error(i18n`Unable to fetch pass information`)
//       this.back()
//     }
//   }

//   revoke = async data => {
//     try {
//       await stores.passes.revoke(this.domain, data)
//       toast.success(i18n`Revoked`)
//     } catch (error) {
//       if (error.message === 'Offline') {
//         toast.warn(i18n`Request queued`)
//         this.back()
//       } else {
//         toast.error(i18n`Unable to revoke pass`)
//       }
//     }
//   }

//   back = () => {
//     this.props.history.push(`/passes/${this.domain}`)
//     return null
//   }

//   logs = () => {
//     this.props.history.push(`/passes/${this.domain}/${this.id}/logs`)
//   }

//   onRevokeAction = ({ ok }) => {
//     if (ok) this.revoke({ id: this.id })
//     this.closeRevokeDialog()
//   }

//   openRevokeDialog = () => {
//     this.setState({ revokeDialog: true })
//   }

//   closeRevokeDialog = () => {
//     this.setState({ revokeDialog: false })
//   }

//   renderToolbarActions = () => {
//     return (
//       <Fragment>
//         <Tooltip title={i18n`Event logs`}>
//           <IconButton aria-label={i18n`Event logs`} onClick={this.logs}>
//             <AccessTimeIcon />
//           </IconButton>
//         </Tooltip>
//         <Tooltip title={i18n`Revoke`}>
//           <IconButton aria-label={i18n`Revoke`} onClick={this.openRevokeDialog}>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       </Fragment>
//     )
//   }

//   render() {
//     if (!this.pass) return this.back()
//     const { revokeDialog } = this.state

//     return (
//       <Fragment>
//         <Section
//           {...this.props}
//           noAssign
//           noActions
//           noDelete={this.pass.status === 'REVOKED'}
//           data={this.pass}
//           title={i18n`Pass Detail`}
//           backButtonLabel={i18n`Passes`}
//           toolbarActions={this.renderToolbarActions}
//           onBack={this.back}
//         >
//           <DetailForm isUpdate data={this.pass} onChange={this.onFormChange} />
//         </Section>
//         <RevokeDialog
//           open={revokeDialog}
//           okLabel={i18n`Revoke`}
//           title={i18n`Revoke pass`}
//           onAction={this.onRevokeAction}
//         >
//           <p>{i18n`Are you sure you want to revoke this pass?`}</p>
//         </RevokeDialog>
//       </Fragment>
//     )
//   }
// }

export default view(PassDetail)
