import React from 'react'
import AdminDetail from './detail'
import AdminCreate from './create'
import AdminList from './list'

export default (props) => {
  const { id } = props.match.params
  if (id && id === 'create') return <AdminCreate {...props} />
  else if (id && id !== 'create') return <AdminDetail {...props} />
  else return <AdminList {...props} />
}
