import * as cons from './constants'
import { timeAgo } from 'lib/utils'
import i18n from 'lib/i18n'

export const mapStatus = (status) =>
  ({
    [cons.STATUS_OPEN]: i18n`Open`,
    [cons.STATUS_ON_HOLD]: i18n`On hold`,
    [cons.STATUS_IN_PROGRESS]: i18n`In progress`,
    [cons.STATUS_RESOLVED]: i18n`Resolved`,
    [cons.STATUS_DUPLICATE]: i18n`Duplicate`,
    [cons.STATUS_CLOSED]: i18n`Closed`,
    [cons.STATUS_INVALID]: i18n`Invalid`
  }[status] || status)

export const mapStatusColor = (status) =>
  ({
    [cons.STATUS_OPEN]: '#2196F3',
    [cons.STATUS_ON_HOLD]: '#E65100',
    [cons.STATUS_IN_PROGRESS]: '#8E24AA',
    [cons.STATUS_RESOLVED]: '#43A047',
    [cons.STATUS_DUPLICATE]: '#546E7A',
    [cons.STATUS_CLOSED]: '#43A047',
    [cons.STATUS_INVALID]: '#E91E63'
  }[status] || status)

export const mapTypes = (type) =>
  ({
    [cons.FIRE]: i18n`Fire`,
    [cons.THEFT]: i18n`Theft`,
    [cons.PLAGUE]: i18n`Plague`,
    [cons.HAZARD]: i18n`Hazard`,
    [cons.ACCIDENT]: i18n`Accident`,
    [cons.INCIDENTE_WITH_SECURITY_PERSONNEL]: i18n`Incidente with security personnel`,
    [cons.ANIMAL_INCIDENT]: i18n`Animal incident`,
    [cons.ANIMAL_WASTE]: i18n`Animal waste`,
    [cons.CLEANSINESS]: i18n`Cleansiness`,
    [cons.MAINTENANCE_PROBLEMS]: i18n`Maintenance problems`,
    [cons.INFRASTRUCTURE_PROBLEMS]: i18n`Infrastructure problems`,
    [cons.GENERAL_IMPROVEMENT]: i18n`General improvement`,
    [cons.PROPERTY_DAMAGE]: i18n`Property damage`,
    [cons.HEALTH_EMERGENCY]: i18n`Health emergency`,
    [cons.SUSPICIOUS_ACTIVITY]: i18n`Suspicious activity`,
    [cons.GENERAL_SECURITY]: i18n`General security`,
    [cons.GENERAL_EMERGENCY]: i18n`General emergency`,
    [cons.RULES_VIOLATION]: i18n`Rules violation`,
    [cons.LOST_PETS]: i18n`Lost pets`,
    [cons.LOST_PERSON]: i18n`Lost person`,
    [cons.LOST_OBJECT]: i18n`Lost object`,
    [cons.VIOLENCE]: i18n`Violence`,
    [cons.DEBRIS]: i18n`Debris`,
    [cons.BAD_PARKING]: i18n`Bad parking`,
    [cons.STREET_LIGHTING_FAILURE]: i18n`Street lighting failure`,
    [cons.FALLEN_TREE_LIMB]: i18n`Fallen tree limb`,
    [cons.NOISE_POLLUTION]: i18n`Noise pollution`,
    [cons.POLLUTION]: i18n`Pollution`,
    [cons.UNSAFE_CONSTRUCTION_WORK]: i18n`Unsafe construction work`,
    [cons.OTHER]: i18n`Other`
  }[type] || '')

export const mapVisibility = (visibility) =>
  ({
    [cons.PUBLIC]: i18n`Public`,
    [cons.PRIVATE]: i18n`Private`
  }[visibility] || '')

export const mapLogs = ({ type, ts, incidentStatus, user, incident, comment }) =>
  ({
    [cons.INCIDENT_CREATED]: i18n`${user.name} created this ${timeAgo(ts)}`,
    [cons.INCIDENT_COMMENTED]: i18n`${user.name} commented ${timeAgo(ts)}`,
    [cons.INCIDENT_STATUS_CHANGED]: i18n`${user.name} ${mapStatus(incidentStatus)} this ${timeAgo(ts)}`
  }[type] || '')
