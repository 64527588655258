import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Toolbar from 'components/toolbar'
import Sidebar from 'components/sidebar'
import Content from 'components/content'
import { withTheme } from 'hocs'
import compose from 'lib/compose'
import sleep from 'lib/sleep'
import { stores } from 'data'
import { view } from 'lib/store'
import Guide from './guide'
import useBus from 'hooks/use-bus'

const Layout = ({ classes, children }) => {
  const [step, setStep] = useState()
  const [guide, setGuide] = useState(false)
  const [sidebar, setSidebar] = useState(false)

  const { guided } = stores.settings

  useEffect(() => {
    if (!guided) openGuide()
  }, [guided])

  useBus('guide::open', () => {
    openGuide()
  })

  const openSidebar = () => {
    setSidebar(true)
  }

  const closeSidebar = () => {
    setSidebar(false)
  }

  const openGuide = async () => {
    await sleep(500)
    openSidebar()
    await sleep(300)
    setGuide(true)
  }

  const closeGuide = async () => {
    setGuide(false)
    await sleep(200)
    closeSidebar()
    stores.settings.update({ guided: true })
  }

  const onGuideChange = step => {
    setStep(step)
  }

  return (
    <div className={classes.root}>
      <Toolbar onMenu={openSidebar} open={sidebar} />
      <Sidebar open={sidebar} onClose={closeSidebar} />
      <Content open={sidebar}>
        <Paper className={classes.paper} elevation={4}>
          {children}
        </Paper>
      </Content>
      <Guide
        step={step}
        open={guide}
        onClose={closeGuide}
        onChange={onGuideChange}
        className={classes.guide}
      />
    </div>
  )
}

export default compose(
  withTheme('layout'),
  view
)(Layout)
